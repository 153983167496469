// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#KeyFeaturesPanel {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  gap: 1em;
}

#CentralizedAccessKeyFeaturePanel {
  grid-area: KeyFeature1;
}

#TailoringKeyFeaturePanel {
  grid-area: KeyFeature2;
}

#SuggestionsKeyFeaturePanel {
  grid-area: KeyFeature3;
}`, "",{"version":3,"sources":["webpack://./src/styling/key-features-panel.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,eAAA;EACA,uBAAA;EACA,WAAA;EACA,QAAA;AADJ;;AAKA;EACI,sBAAA;AAFJ;;AAKA;EACI,sBAAA;AAFJ;;AAKA;EACI,sBAAA;AAFJ","sourcesContent":["\n\n#KeyFeaturesPanel{\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n    width: 100%;\n    gap: 1em;\n}\n\n\n#CentralizedAccessKeyFeaturePanel{\n    grid-area: KeyFeature1;\n}\n\n#TailoringKeyFeaturePanel{\n    grid-area: KeyFeature2;\n}\n\n#SuggestionsKeyFeaturePanel{\n    grid-area: KeyFeature3;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
