import { FC, memo, useEffect, useState } from 'react';
import Descriptor, {DescriptorModel} from '../shared/descriptor/descriptor';
import NeoDescriptor from './NeoDescriptor';
import { useNavigate } from 'react-router-dom';
import { clearSearch } from '../slices/searchSlice';
import { useAppDispatch } from '../store/hooks';
import { lexTypeToToken } from '../common/lexTypeToToken';
import type { LexLightObject } from '../interfaces/LexLightObject';

export interface NeoSearchResultRowModel {
  lexObject: LexLightObject;
//  id : string;
//  title: string;
//  descriptor: DescriptorModel;
//  lexType: string;
//  slug?: string;
}

export interface NeoSearchResultRowProps extends NeoSearchResultRowModel{
  rowIndex: number;
  pxHeight: number;
  separatorPxWidth: number;
  isSelected: boolean;
  hoverHandler: (rowId : string) => void;
}

const NeoSearchResultRow: FC<NeoSearchResultRowProps> = ({ lexObject, rowIndex, pxHeight, separatorPxWidth,  isSelected, hoverHandler }) => {
  const [isPressed, setIsPressed] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const classes : string[] = ["search-result-row"];

  if (isPressed) { 
    classes.push("pressed");
    console.log('pressed');
  }
  else if ( isSelected) {
    classes.push("highlighted");
    console.log('highlighted');
  }

  const handleClick = () => {
      dispatch(clearSearch());
      const token = lexTypeToToken(lexObject.lexType);
      navigate(`/${token}/${lexObject.instanceId}`);
  }

  return (
    <div 
      style={{height: pxHeight + "px", borderBottomWidth: separatorPxWidth+"px"}}
      className={classes.join(" ")}
      onMouseOver={() =>{ hoverHandler(lexObject.instanceId.toString())} } 
      onMouseDown={() => setIsPressed(true)}
      onMouseUp={() => setIsPressed(false)}
      onClick={handleClick}
      >
      <div className="result-row-content">
        <div className="title">{lexObject.displayTitle}</div>
        <NeoDescriptor lexObject={lexObject} />
      </div>
    </div>
  );
};

export default memo(NeoSearchResultRow);
