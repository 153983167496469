import '../styling/welcome-panel.scss';
import ElevatorPitchPanel from './ElevatorPitchPanel';
import KeyFeaturesPanel from './KeyFeaturesPanel';

function WelcomePage() {

  return (
    <div className="welcome-panel app-body">
        <ElevatorPitchPanel/>
        <KeyFeaturesPanel/>
    </div>
  );
}

export default WelcomePage;
