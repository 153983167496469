import type { ReferenceEntry } from '../interfaces/types';
import type { TocEntry } from '../interfaces/TocEntry';
import { referenceEntryToTocEntry } from './referenceEntryToTocEntry';
import { isWhiteSpaceString } from './isWhiteSpaceString';

export function getTreedTableOfContents(referenceEntries: ReferenceEntry[], maxLevel: number = 3): TocEntry[] {

  let tocEntries: TocEntry[] = [];

  let currentLevel = 0;

  let parents: any[] = [tocEntries];

  for (let i=0; i<referenceEntries.length; ++i) {
    const entry = referenceEntries[i];

    if (isWhiteSpaceString(entry.content)) continue;
    if (entry.level > maxLevel) continue;

    if (entry.level == 0) {
      tocEntries.push(referenceEntryToTocEntry(entry));
      parents = [tocEntries];
    }
    else if (entry.level == currentLevel) {
      parents[parents.length - 1].push(referenceEntryToTocEntry(entry));
    }
    else if (entry.level > currentLevel) {
      let lastParent = parents[parents.length - 1];
      let lastObject = lastParent[lastParent.length - 1];
      if (lastObject && 'children' in lastObject) {
        parents.push(lastObject.children);
        parents[parents.length - 1].push(referenceEntryToTocEntry(entry));
      }
    }
    else if (entry.level < currentLevel) {
      while (parents[parents.length - 1].level >= entry.level) {
        if (parents.length > 1) {
          parents.pop();
        }
        else {
          break;
        }
      }
      if (parents.length == 0) {
        parents = [tocEntries];
      }
      parents[parents.length - 1].push(referenceEntryToTocEntry(entry));
    }

    currentLevel = entry.level;
  }

  return tocEntries;
}
