import React, {useState} from 'react';
import ExpansionButton from '../shared/expansion-button/expansion-button';
import "../styling/bookmark-panel-section.scss";
import { ExpansionState } from '../shared/enumerations';

export interface BookmarkPanelSectionModel {
    warningText?: string;
}

export interface BookmarkPanelSectionProps extends BookmarkPanelSectionModel {
    title: string;
    children?: React.ReactNode;
    extraClasses? : string
}

const BookmarkPanelSectionPanel: React.FC<BookmarkPanelSectionProps> = ({ title, warningText, children, extraClasses }) => {

    const [expansionState, setExpansionState] = useState<ExpansionState>("COLLAPSED");
    
    let mainClass ="bookmark-panel-section " + (expansionState === "EXPANDED" ? "expanded" : "collapsed");
    if (extraClasses)
        mainClass = extraClasses + " " + mainClass;
    
    const headerClass = "bookmark-panel-section-header" + (warningText? " warning" : "");
    const bodyClass ="bookmark-panel-section-body " + (expansionState === "EXPANDED" ? "expanded" : "collapsed");

    const handleClick = () => {
        if (expansionState === "COLLAPSED")
            setExpansionState("EXPANDED");
        else
            setExpansionState("COLLAPSED");
    }

    return (
        <div className={mainClass}>
            <div className={headerClass} onClick={handleClick}>
                <div className="bookmark-panel-section-title">{title}</div>
                <ExpansionButton expansionState={expansionState} clickHandler={handleClick}/>
            </div>
            <div className={bodyClass}>
                {children}
            </div>
        </div>
    );
}

export default BookmarkPanelSectionPanel;
