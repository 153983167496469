import { FC, memo } from 'react';
import type { LexLightObject } from '../interfaces/LexLightObject';
import type { ReferenceResult } from "../interfaces/types";
import ExpansionButton from "../shared/expansion-button/expansion-button";
import NeoReferenceBlockHeader from "./NeoReferenceBlockHeader";

export interface ContentBlockReferenceAsPdfModel {
  lexObject: LexLightObject;
  referenceResult: ReferenceResult;
}

const ContentBlockReferenceAsPdf: FC<ContentBlockReferenceAsPdfModel> = ({ lexObject, referenceResult }) => {

  const pdfStyle = {
    height: "73vh",
    width: "100%",
    //width: "calc(100vw - 18px)",
  }

  const classes: string[] = ["content-block"];
 
  const urlEncodedUrl = encodeURI(referenceResult.reference.localUrl);
  const pdfString = `${urlEncodedUrl}#view=FitH&navpanes=0&messages=0&statusbar=0&toolbar=1`;

  return (
    <div id={referenceResult.reference.id.toString()} className={classes.join(" ")} > 
      <div className="title">{referenceResult.reference.title}</div>
      <NeoReferenceBlockHeader reference={referenceResult.reference} />
      <div className="block-body" >
      <iframe
        src={pdfString}
        style={pdfStyle}
        title={referenceResult.reference.title}
      ></iframe>
      </div>
    </div>
  );
}

export default memo(ContentBlockReferenceAsPdf);
