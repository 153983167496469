import '../styling/dashboard-menu-item.scss';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

export type DashboardMenuItemProps = {
  icon: React.ReactNode;
  title: string;
  content?: string;
  navigateTo: string;
}

const DashboardMenuItem: FC<DashboardMenuItemProps> = ({ icon, title, content, navigateTo }) => {

  const navigate = useNavigate();

  const handleClick = () => {
    navigate(navigateTo);
  }

  return (
    <div className="dashboard-menu-item" onClick={handleClick}>
      <div className="inner-box">
        <div className="icon">
          {icon}
        </div>
        <div className="content">
          <div className="title">
            {title}
          </div>
          <div className="text">
            {content}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardMenuItem;
