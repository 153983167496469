import '../styling/table-of-contents.scss';
import { FC, memo } from 'react';
import type { LexLightObject } from '../interfaces/LexLightObject';
import type { TocEntry } from '../interfaces/TocEntry';
import { useGetReferenceSegmentsQuery } from '../slices/apiSlice';
import { getTreedTableOfContents } from '../common/getTreedTableOfContents';
import TocTree from './TocTree';
import AccordionElement from './AccordionElement';

const TableOfContentsBlock: FC<LexLightObject> = ( lexObject: LexLightObject ) => {

  const {
    data: queryData,
    isLoading,
    isSuccess,
    isFetching,
    isError,
    //    error,
  } = useGetReferenceSegmentsQuery(lexObject.instanceId.toString());

  if (isLoading || isFetching) return(<></>);
  if (!queryData) return(<></>);
  if (isError) return(<div>An error occurred</div>);

  let tocEntries: TocEntry[] = [];

  if (queryData) {
    tocEntries = getTreedTableOfContents(queryData.entries);
  }

  if (tocEntries.length <= 1) return (<></>);

  const content = <div className='table-of-contents'>
                    <TocTree tocEntries={tocEntries} />
                  </div>;

  return (
    <div className="accordion-area">
      <AccordionElement
        title="Table of Contents"
        childComponent={content}
        openToStart={true}
      />
    </div>
  );

}

export default memo(TableOfContentsBlock);
