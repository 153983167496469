// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.related-entry-panel {
  display: grid;
  grid-template: "checkbox header"/max-content 1fr;
  max-width: 30rem;
}
.related-entry-panel .flat-checkbox {
  grid-area: checkbox;
}
.related-entry-panel .related-entry-panel-header {
  grid-area: header;
  opacity: 0.75;
}
.related-entry-panel .related-entry-descriptor {
  font-style: italic;
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/styling/related-entry-panel.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,gDACI;EAEJ,gBAAA;AAHJ;AAII;EACI,mBAAA;AAFR;AAKI;EACI,iBAAA;EACA,aAAA;AAHR;AAOI;EACI,kBAAA;EACA,gBAAA;AALR","sourcesContent":["\n\n.related-entry-panel{\n    display: grid;\n    grid-template:\n        \"checkbox header\" \n        /max-content 1fr;\n    max-width: 30rem;\n    .flat-checkbox{\n        grid-area: checkbox;\n    }\n\n    .related-entry-panel-header {\n        grid-area: header;\n        opacity: 0.75;\n \n    }\n\n    .related-entry-descriptor{\n        font-style: italic;\n        font-weight: 400;\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
