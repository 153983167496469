export default class EmailFormatValidator{

    // #region Methods
    public static EmailFormatIsValid(email: string) : boolean {
        const emailRegex: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          
        return emailRegex.test(email);
    }
    // #endregion
}
