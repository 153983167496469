import "../styling/potential-bookmark-block.scss";
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CircularButton from "../shared/circular-button/circular-button";
import { ReactComponent as AddBookmarkIcon } from "../shared/assets/images/icons/add-bookmark-icon.svg";
//import { ReactComponent as CheckedIcon } from "../shared/assets/images/icons/flat-checkbox-icon.svg"; TODO when can check if already bookmarked
import { ReactComponent as PreviousIcon } from "../shared/assets/images/icons/previous-icon.svg";
import { ReactComponent as NextIcon } from "../shared/assets/images/icons/next-icon.svg";

import type { LexLightObject } from "../interfaces/LexLightObject";
import PotentialBookmarkBodyTerm from './PotentialBookmarkBodyTerm';
import PotentialBookmarkBodyDocument from './PotentialBookmarkBodyDocument';

import { useAddBookmarkMutation } from '../slices/apiSlice';
import { useGetNextObjectQuery, useGetPrevObjectQuery } from '../slices/apiSlice';
import { lexTypeToToken } from '../common/lexTypeToToken';
import { isKnownLexType } from '../common/isKnownLexType';

const PotentialBookmarkBlock: FC<LexLightObject> = (lexObject: LexLightObject) => {

  const navigate = useNavigate();

  const [addBookmark, result] = useAddBookmarkMutation();

  const handleClick = () => {
    console.log("bookmarking ", lexObject);
    addBookmark(lexObject);
  };

  useEffect(() => {
    // TODO handle this
    console.log('got bookmark result: ', result);
  }, [result])

  if (!isKnownLexType(lexObject.lexType)) {
    return (<></>);
  }

  const {data: prevData, isSuccess: isPrevSuccess} = useGetPrevObjectQuery(lexObject);
  const {data: nextData, isSuccess: isNextSuccess} = useGetNextObjectQuery(lexObject);

  let prevObject: LexLightObject | null = null;
  let nextObject: LexLightObject | null = null;

  if (isPrevSuccess && prevData) {
    prevObject = prevData;
  }

  if (isNextSuccess && nextData) {
    nextObject = nextData;
  }

  const handlePrevClick = () => {
    if (prevObject) {
      navigate(`/${lexTypeToToken(prevObject.lexType)}/${prevObject.instanceId}/`);
    }
    prevObject = null;
  }

  const handleNextClick = () => {
    if (nextObject) {
      navigate(`/${lexTypeToToken(nextObject.lexType)}/${nextObject.instanceId}/`);
    }
    nextObject = null;
  }

  return (
    <div className="potential-bookmark-block">
      <CircularButton id="save-potential-bookmark-button" icon={<AddBookmarkIcon/>} importance="primary" tooltip="Save bookmark" clickHandler={handleClick} selectionState="UNSELECTED"/>
      <div id="title">{lexObject.displayTitle}</div>
      <div id="potential-bookmark-action-block">
        {prevObject && <CircularButton id="go-to-previous-entry-in-alphabetical-order" icon={<PreviousIcon/>} importance="tertiary" tooltip="Go to previous entry (in alphabetical order)" clickHandler={handlePrevClick}/>}
    { nextObject && <CircularButton id="go-to-next-entry-in-alphabetical-order" icon={<NextIcon/>} importance="tertiary" tooltip="Go to next entry (in alphabetical order)" clickHandler={handleNextClick}/>}
     </div>
    { lexObject.lexType === "document" && <PotentialBookmarkBodyDocument lexObject={lexObject} />}
    { lexObject.lexType === "term" && <PotentialBookmarkBodyTerm lexObject={lexObject} />}
    </div>
  );
}
  
export default PotentialBookmarkBlock;
