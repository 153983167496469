import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ReferenceResult, TermResult } from '../interfaces/types';
import { LoginCredentials } from '../interfaces/LoginCredentials';
import { LoginResponse } from '../interfaces/LoginResponse';
import type { LexLightObject } from '../interfaces/LexLightObject';
import type { Bookmark } from '../interfaces/Bookmark';
import type { RootState } from '../store/store';
import type { TermLink } from '../interfaces/TermLink';
import type { SignUpForm } from '../interfaces/SignUpForm';
import type { SignUpResponse } from '../interfaces/SignUpResponse';
import type { UserProfileForm } from '../interfaces/UserProfileForm';
import type { ReferenceRelationships } from '../interfaces/ReferenceRelationships';
import type { SearchResult } from '../interfaces/SearchResult';
import type { AdvancedSearchQuery } from '../interfaces/AdvancedSearchQuery';
import type { ReferenceCounts } from '../interfaces/ReferenceCounts';
import { lexTypeToToken } from '../common/lexTypeToToken';
//import { config } from '../constants';

// https://lexogon-demo-5wnvu.ondigitalocean.app/api/

export const apiSlice = createApi({
    reducerPath: 'lexogonApi',
    //    baseQuery: fetchBaseQuery({ baseUrl: `${config.url.API_URL}`}),
    baseQuery: fetchBaseQuery({
        //baseUrl: 'https://lexogon-demo-5wnvu.ondigitalocean.app/api/',
        baseUrl: 'https://api.lexogon.com/',
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).userSession.token;
            if (token) {
                headers.set('authorization', `Token ${token}`);
            }
            else {
                console.log('No token to set');
            }
            return headers;
        }
    }),
    tagTypes: ['Bookmarks', 'Terms', 'UserProfile'],
    endpoints: (builder) => ({
        login: builder.mutation<LoginResponse, LoginCredentials>({
            query: (loginCredentials: LoginCredentials) => ({
                url: 'user/login/',
                method: 'POST',
                mode: 'cors',
                body: loginCredentials,
            })
        }),
        signUp: builder.mutation<LoginResponse, SignUpForm>({
            query: (signUpForm: SignUpForm) => ({
                url: '/user/signup/',
                method: 'POST',
                mode: 'cors',
                body: signUpForm,
            })
        }),
        logout: builder.mutation<void, {token: string}>({
            query: () => ({
                url: 'user/logout/',
                method: 'POST',
                mode: 'cors',
            })
        }),
        getTermDetail: builder.query<TermResult, string>({
            query: (termSlug) => ({
                url: `term/${termSlug}`,
                method: 'GET',
                mode: 'cors',
            })
        }),
        getReferenceCounts: builder.query<ReferenceCounts, void>({
            query: () => ({
              url: 'document/count',
              method: 'GET',
              mode: 'cors',
            })
        }),
        getReferenceSegments: builder.query<ReferenceResult, number|string>({
            query: (documentId) => ({
                url: `document/${documentId}/entries`,
                method: 'GET',
                mode: 'cors',
            })
        }),
        getReferenceRelationships: builder.query<ReferenceRelationships, number|string>({
          query: (documentId) => ({
            url: `document/${documentId}/relationships`,
            method: 'GET',
            mode: 'cors',
          })
        }),
        getSearchResult: builder.query<SearchResult, string>({
          query: (searchText)=> ({
            url: `search/?q=${searchText}`,
            mode: 'cors',
          })
        }),
        getAdvancedSearchResult: builder.mutation<SearchResult, AdvancedSearchQuery>({
          query: (advancedSearchQuery) => ({
            url: `search/advanced/`,
            method: 'POST',
            mode: 'cors',
            body: advancedSearchQuery,
          })
        }),
        getDocumentFromPublication: builder.query<LexLightObject, string>({
          query: (instanceId) => ({
            url: `publication/${instanceId}/best-document/`,
            method: 'GET',
            mode: 'cors',
          })
        }),
        getLexLightObject: builder.query<LexLightObject, LexLightObject>({
          query: (lexObject) => ({
            url: `llo/${lexTypeToToken(lexObject.lexType)}/${lexObject.instanceId}`,
            mode: 'cors',
          })
        }),
        getNextObject: builder.query<LexLightObject, LexLightObject>({
          query: (lexObject) => ({
            url: `${lexTypeToToken(lexObject.lexType)}/${lexObject.instanceId}/next/`,
            mode: 'cors',
          })
        }),
        getPrevObject: builder.query<LexLightObject, LexLightObject>({
          query: (lexObject) => ({
            url: `${lexTypeToToken(lexObject.lexType)}/${lexObject.instanceId}/prev/`,
            mode: 'cors',
          })
        }),
        getTermsContainingTerm: builder.query<LexLightObject[], LexLightObject>({
          query: (lexObject) => ({
            url: `${lexTypeToToken(lexObject.lexType)}/${lexObject.instanceId}/terms/`,
            mode: 'cors',
          })
        }),
        getRefsContainingTerm: builder.query<LexLightObject[], LexLightObject>({
          query: (lexObject) => ({
            url: `${lexTypeToToken(lexObject.lexType)}/${lexObject.instanceId}/documents/`,
            mode: 'cors',
          })
        }),
        getTermLinks: builder.query<TermLink[], void>({
          query: () => ({
            url: 'term',
            mode: 'cors',
          }),
          providesTags: ['Terms']
        }),
        getProfile: builder.query<UserProfileForm, void>({
          query: () => ({
            url: 'user/profile/',
            method: 'GET',
          }),
          providesTags: ['UserProfile'],
        }),
        updateProfile: builder.mutation<UserProfileForm, UserProfileForm>({
          query: (profile: UserProfileForm) => ({
            url: 'user/profile/',
            method: 'POST',
            mode: 'cors',
            body: profile,
          }),
          invalidatesTags: ['UserProfile'],
        }),
        getBookmarks: builder.query<Bookmark[], void>({
          query: () => ({
            url: 'user/bookmarks/',
            method: 'GET',
          }),
          providesTags: ['Bookmarks'],
        }),
        addBookmark: builder.mutation<void, LexLightObject>({
          query: (bookmarkObject: LexLightObject) => ({
            url: '/user/bookmarks/',
            method: 'POST',
            mode: 'cors',
            body: bookmarkObject,
          }),
          invalidatesTags: ['Bookmarks'],
        }),
        updateBookmark: builder.mutation<void, Bookmark>({
          query: (bookmarkObject: Bookmark) => ({
            url: `/user/bookmarks/${bookmarkObject.bookmarkId}`,
            method: 'PUT',
            mode: 'cors',
            body: bookmarkObject,
            invalidatesTags: ['Bookmarks'],
          }),
          invalidatesTags: ['Bookmarks'],
        }),
        deleteBookmark: builder.mutation<void, Bookmark>({
          query: (bookmarkObject: Bookmark) => ({
            url: `/user/bookmarks/${bookmarkObject.bookmarkId}`,
            method: 'DELETE',
            mode: 'cors',
            body: bookmarkObject,
          }),
          invalidatesTags: ['Bookmarks'],
        }),
        getReferenceStatuses: builder.query<LexLightObject[], void>({
          query: () => ({
            url: '/documents/status/',
            method: 'GET',
            mode: 'cors',
          }),
        }),
        getJurisdictions: builder.query<LexLightObject[], void>({
          query: () => ({
            url: '/jurisdiction',
            method: 'GET',
            mode: 'cors',
          }),
        }),
        getRefsByJurisdiction: builder.query<LexLightObject[], string>({
          query: (countryCode: string) => ({
            url: `/jurisdiction/${countryCode}/documents`,
            method: 'GET',
            mode: 'cors',
          }),
        }),
        getRefsByStatus: builder.query<LexLightObject[], string>({
          query: (refStatus: string) => ({
            url: `/documents/status/${refStatus}`,
            method: 'GET',
            mode: 'cors',
          }),
        }),
    }),
})

export const { useLoginMutation, useSignUpMutation, useLogoutMutation, useGetTermDetailQuery, useGetReferenceCountsQuery, useGetReferenceSegmentsQuery, useGetReferenceRelationshipsQuery, useGetSearchResultQuery, useGetAdvancedSearchResultMutation, useGetDocumentFromPublicationQuery, useGetLexLightObjectQuery, useGetNextObjectQuery, useGetPrevObjectQuery, useGetTermsContainingTermQuery, useGetRefsContainingTermQuery, useGetTermLinksQuery, useGetProfileQuery, useUpdateProfileMutation, useGetBookmarksQuery, useAddBookmarkMutation, useUpdateBookmarkMutation, useDeleteBookmarkMutation, useGetReferenceStatusesQuery, useGetJurisdictionsQuery, useGetRefsByJurisdictionQuery, useGetRefsByStatusQuery } = apiSlice
