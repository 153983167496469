import "../styling/app-menu.scss";
import { FC } from 'react';
import AppMenuOption from "./AppMenuOption";
import { ReactComponent as LibraryExplorerIcon } from "../shared/assets/images/icons/library-explorer-icon.svg";
import { ReactComponent as SettingsIcon } from "../shared/assets/images/icons/Settings-icon.svg";
import { ReactComponent as UserDashboardIcon } from '../shared/assets/images/icons/user-dashboard-icon.svg';
import { ReactComponent as CoverageIcon } from '../shared/assets/images/icons/coverage-icon.svg';

export enum ExpansionState{
    Collapsed,
    Expanded
}

export enum Affordance{
    None,
    ShowPossibility,
    ShowIntent
}

export interface AppMenuProps{
    expansionState: ExpansionState;
    affordance: Affordance;
    
    optionClickHandler: (optionName: string) => void;
}

const AppMenu: FC<AppMenuProps> = ({expansionState, affordance, optionClickHandler}) => {
  
    let classNames = [];
    switch (expansionState){
        case ExpansionState.Collapsed:
            switch (affordance){
                case Affordance.None:
                    classNames.push("collapsed");
                    break;
                case Affordance.ShowPossibility:
                    classNames.push("peeking");
                    break;
                case Affordance.ShowIntent:
                    classNames.push("peeking-more");
                    break;
            }
        break;

        case ExpansionState.Expanded:
            switch (affordance){
                case Affordance.None:
                    classNames.push("expanded");
                    break;
                case Affordance.ShowPossibility:
                    classNames.push("expanded");
                    break;
                case Affordance.ShowIntent:
                    classNames.push("shrugging-more");
                    break;
            }
        break;

    }

    
    return (
        <div id="app-menu" className={classNames.join(" ")}>
          <AppMenuOption optionId = "library" icon={<LibraryExplorerIcon/>} text="Library" clickHandler={ optionClickHandler} destination={'/library'}/>
          {/*          <AppMenuOption optionId = "settings" icon={<SettingsIcon/>} text="Settings" clickHandler={optionClickHandler} destination={'/settings'} /> */}
          <AppMenuOption optionId = "dashboard" icon={<UserDashboardIcon/>} text="User Dashboard" clickHandler={ optionClickHandler} destination={'/user'}/>
          <AppMenuOption optionId = "coverage" icon={<CoverageIcon/>} text="Document coverage" clickHandler={optionClickHandler} destination={'/coverage'}/>
        </div>
    );
}
  
export default AppMenu;
