import '../styling/llo-list.scss';
import "../styling/appearance-panel.scss";
import { FC, useEffect, useState } from 'react';
import type { LexLightObject } from '../interfaces/LexLightObject';
import LLOLink from './LLOLink';
import CircularButton from "../shared/circular-button/circular-button";
import { ReactComponent as UpArrowIcon } from '../shared/assets/images/icons/up-arrow-icon.svg';
import { ReactComponent as DownArrowIcon } from '../shared/assets/images/icons/down-arrow-icon.svg';

export interface AdvancedLLOLinkListProps {
  lexObjects: LexLightObject[];
  title?: string;
}

const AdvancedLLOLinkList: FC<AdvancedLLOLinkListProps> = ({lexObjects, title}) => {
  const [list, setList] = useState<LexLightObject[]>([...lexObjects]);
  const [alphaOrder, setAlphaOrder] = useState<boolean>(true);

  useEffect(() => {
    let toSort: LexLightObject[];
    switch(alphaOrder) { 
      case true:
        toSort = [...lexObjects];
        setList(toSort.sort());
        break;
      case false:
        toSort = [...lexObjects];
        setList(toSort.sort().reverse());
        break;
      default:
        break;
    }
  }, [alphaOrder])

  const handleButtonClick = () => {
    setAlphaOrder(!alphaOrder);
  }

  return (
    <div className="advanced-llo-link-list">
      {title && <div className="list-title">
        {title}
      </div>}
      <div className="llo-list-toolbar">
        <div className="toolbar-group">
          <div className='button-explainer-text'><b>↕</b>A-z</div>
          <CircularButton
            type="TOGGLE"
            className="toggle-button"
            id={"alpha-sort-button"}
            tooltip={alphaOrder ? "Sort in reverse alphabetical order" : "Sort in alphabetical order"}
            icon={alphaOrder ? <DownArrowIcon /> : <UpArrowIcon />}
            importance="primary"
            clickHandler={handleButtonClick}
          />
        </div>
      </div>
      <ul className="llo-list">
        {list.map((object, index) => {
          return (
            <li key={index} className={object.lexType}>
              <LLOLink {...object} />
            </li>
          )
        })}
      </ul>
    </div>
  );
}

export default AdvancedLLOLinkList;
