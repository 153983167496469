import ContentPanel from "../components/ContentPanel";
import "../styling/library-explorer.scss";
import SelectionPanel from "../components/SelectionPanel";
import { useParams } from 'react-router';
import { LexLightObject} from '../interfaces/LexLightObject';
import { useGetLexLightObjectQuery } from '../slices/apiSlice'; 


const LibraryExplorer = () => {

  const params = useParams();
  const numKeys = Object.keys(params).length;

  let lexType = "";

  if (numKeys === 1) {
    console.log("single token navigation unimplemented");
  }
  else if (numKeys === 2) {
    const tokens = [String(params['firstToken']), String(params['secondToken'])];

    if (tokens[0] === "term") {
      lexType = 'term';
    }
    else if (tokens[0] === "document") {
      lexType = 'document';
    }
    else {
      console.log('Got unknown token for lexType.  Token: ', tokens[0]);
    }
  }
  else if (numKeys === 0) {
    console.log("should have gone to welcome through main router path");
  }
  else {
    console.log("too many params");
  }

  const partialLexLightObject : LexLightObject = {
    lexType: lexType,
    displayTitle: '',
    instanceId: String(params['secondToken']),
    jurisIDs: []
  };

  const { data, isLoading } = useGetLexLightObjectQuery(partialLexLightObject);

  if (isLoading) return (<></>);

  return (
    <div className="library-explorer app-body">
      {data && (
      <>
        <SelectionPanel {...data } />
        <ContentPanel {...data} />
      </>
      )}
    </div>
  );
}
  
export default LibraryExplorer;
