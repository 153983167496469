import { FC, memo } from 'react';

interface ReferenceStatusIndicatorModel {
  status: string;
}

const ReferenceStatusIndicator: FC<ReferenceStatusIndicatorModel> = ({ status }) => {

  const lStatus = status.toLowerCase();

  let text: string = '';
  let color: string = 'white';

  switch (lStatus) {
    case "active":
      text = 'Active';
      color = 'green';
      break;
    case "future":
      text = 'Future';
      color = '#5fb7cf';
      break;
    case "deprecated":
      text = 'Deprecated';
      color = 'red';
      break;
    case "work-in-progress":
    case "work_in_progress":
      text = 'Work-in-progress';
      color = 'orange';
      break;
    case "non-active":
    case "non_active":
      text = 'Non-active';
      color = 'silver';
      break;
    case "unknown":
      console.log('status was unknown');
      return (<></>);
  }

  const style = {
    color: 'white',
    backgroundColor: `${color}`,
    borderRadius: '0.3rem',
    paddingLeft: '0.2rem',
    paddingRight: '0.2rem',
    paddingTop: '0.1rem',
    paddingBottom: '0.1rem',
    fontSize: '0.8rem',
    fontWeight: 400,
  }

  return (
    <span style={style}>{text}</span>
  )
}

export default memo(ReferenceStatusIndicator);
