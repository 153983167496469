import { FC, memo } from 'react';
import '../styling/reference-entries.scss'
import type { ReferenceEntry } from '../interfaces/types';
import type { TermLink } from '../interfaces/TermLink';
import type { ReferenceContainsUrlRelationship } from '../interfaces/ReferenceRelationships';
import { getReferenceEntryClassName } from '../common/getReferenceEntryClassName';
import ReferenceEntryCitation from './ReferenceEntryCitation';
import ReferenceEntryParagraph from './ReferenceEntryParagraph';
import { isWhiteSpaceString } from '../common/isWhiteSpaceString';

export interface ReferenceEntryItemModel {
  entry: ReferenceEntry;
  index: number;
  termLinks: TermLink[];
  refLinks: ReferenceContainsUrlRelationship[];
  //  alreadyLinkedTerms: string[];
  isTermAlreadyLinked: (term: string) => boolean;
  addAlreadyLinkedTerm: (term: string) => void;
}

const ReferenceEntryItem: FC<ReferenceEntryItemModel> = ({ entry, index, termLinks, refLinks, isTermAlreadyLinked, addAlreadyLinkedTerm }) => {

  if (isWhiteSpaceString(entry.content)) return (<></>);

  const entryClassName = getReferenceEntryClassName(entry.contentType);

  if (entry.contentType === 'PARAGRAPH') {
    return <ReferenceEntryParagraph entry={entry} index={index} termLinks={termLinks} refLinks={refLinks} isTermAlreadyLinked={isTermAlreadyLinked} addAlreadyLinkedTerm={addAlreadyLinkedTerm} key={entry.documentEntryUid}/>
  }
  else if (entry.contentType === 'CITATION') {
    return <ReferenceEntryCitation entry={entry} index={index} refLinks={refLinks} key={entry.documentEntryUid} />
  }
  return (
    <div className={entryClassName} key={entry.documentEntryUid} id={entry.documentEntryUid} title={entry.sectionId}>{entry.content}</div>
  );
}

export default memo(ReferenceEntryItem);
