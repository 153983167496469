import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { LexLightObject } from '../interfaces/LexLightObject';

const initialState: LexLightObject = {
  lexType: 'welcome-page',
  displayTitle: '',
  instanceId: '',
  jurisIDs: []
} as LexLightObject;

export const activeObjectSlice = createSlice({
  name: 'activeObject',
  initialState,
  reducers: {
    setActiveObject: (state, action: PayloadAction<LexLightObject>) => {
      //state = action.payload;
      return action.payload;
    }
  }
})

export const { setActiveObject } = activeObjectSlice.actions
export default activeObjectSlice.reducer
