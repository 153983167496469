import "../styling/search-box.scss";
import { FC, useRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { search, clearSearch } from '../slices/searchSlice';
import { clearAdvancedSearch } from '../slices/advancedSearchSlice';
import { Availability } from '../shared/enums/availability';
import ModalBox from './ModalBox';
import AdvancedSearch from './AdvancedSearch';

export type SearchBoxAction = "HIGHLIGHT_NEXT_RESULT" | "HIGHLIGHT_PREVIOUS_RESULT" | "GO_TO_SELECTED_RESULT" | "CLEAR_RESULTS";

export type SearchBoxProps = {
    availability: Availability;
    queryChangeHandler: (newSearchQuery: string) => void;
    actionHandler: (action: SearchBoxAction) => void;
}

const SearchBox: FC<SearchBoxProps> = ({availability, queryChangeHandler, actionHandler}) =>{
  const [query, setQuery] = useState<string>('');
  const [advancedSearchVisible, setAdvancedSearchVisible] = useState<boolean>(false);
  const [showCancel, setShowCancel] = useState<boolean>(false);

  const searchBoxRef = useRef<any>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
    if (!searchBoxRef.current?.contains(event.target)) {
        clearTextbox()
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [searchBoxRef]);

  useEffect(() => {
    if ('' !== query) {
      console.log('no query');
      setShowCancel(true);
    }
    else {
      setShowCancel(false);
    }

  }, [query])

  const dispatch = useDispatch();

  const domTextbox = useRef<HTMLInputElement>(null);

  const isMobile = useMediaQuery({maxWidth: 800});
  let placeholderText;
  if (isMobile) {
    placeholderText = "Search...";
  }
  else {
    placeholderText = "Search for legislations and definitions...";
  }

  useEffect(() => {
      const timeoutId = setTimeout(() => {
          if (query.length >= 3) {
              dispatch(search(query));
          }
          else if (query.length === 0) {
              dispatch(clearSearch());
          }
      }, 333); // TODO rmove timout to config
      return () => {
          clearTimeout(timeoutId);
      }
  }, [query, dispatch]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      //        queryChangeHandler(event.target.value);
      setQuery(event.target.value);
  }

  function clearTextbox() {
    if(domTextbox.current !== null) {
      domTextbox.current.value = "";
      setQuery('');
    }
  }

   
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    switch(event.key) {
      case 'ArrowUp':
        event.preventDefault();
        actionHandler("HIGHLIGHT_PREVIOUS_RESULT");
        break;
      case 'ArrowDown':
        event.preventDefault();
        actionHandler("HIGHLIGHT_NEXT_RESULT");
        break;
      case 'Enter':
        event.preventDefault();
        clearTextbox();
        actionHandler("GO_TO_SELECTED_RESULT");
        break;
      case 'Escape':
        event.preventDefault();
        clearTextbox();
        actionHandler("CLEAR_RESULTS");
        break;
      default:
        break;
    }
  }

  var lClassNames = [];
  if (availability === Availability.Unavailable)
  lClassNames.push("unavailable");
  var lClassName = lClassNames.join(" ");

  var lSubControlsAreDisabled = availability === Availability.Unavailable;

  const onAdvancedSearchClick = () => {
    clearTextbox();
    clearAdvancedSearch();
    setAdvancedSearchVisible(true);
  }

  const advancedSearchIcon = 
    <div id="advanced-search-icon-wrapper" title="Advanced Search" onClick={onAdvancedSearchClick} >
      <svg fill="#000000" pointerEvents="all" viewBox="0 0 256 256" id="advanced-search-icon" >
        <path d="M83.99951,87.99353h-.00012l-44-.001a4,4,0,0,1,.00012-8h.00012l44,.001a4,4,0,0,1-.00012,8Zm136,84a4.00019,4.00019,0,0,1-4,4H191.65967a23.99507,23.99507,0,0,1-47.32031,0H39.99951a4,4,0,0,1,0-8H144.33936a23.99507,23.99507,0,0,1,47.32031,0h24.33984A4.0002,4.0002,0,0,1,219.99951,171.99353Zm-36,0a16,16,0,1,0-16,16A16.01833,16.01833,0,0,0,183.99951,171.99353Zm-104-88a23.99775,23.99775,0,0,1,47.66016-4l88.33984-.001a4,4,0,0,1,0,8l-88.33984.001a23.99775,23.99775,0,0,1-47.66016-4Zm8,0a16,16,0,1,0,16-16A16.01833,16.01833,0,0,0,87.99951,83.99353Z"
        />
      </svg>
    </div>;

  const cancelIcon =
    <div id="advanced-search-icon-wrapper" title="Clear search" onClick={clearTextbox}>
      <svg viewBox="0 0 512 512" fill="#888F98" id="advanced-search-icon">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="work-case" fill="#888F98" transform="translate(91.520000, 91.520000)">
          <polygon id="Close" points="328.96 30.2933333 298.666667 1.42108547e-14 164.48 134.4 30.2933333 1.42108547e-14 1.42108547e-14 30.2933333 134.4 164.48 1.42108547e-14 298.666667 30.2933333 328.96 164.48 194.56 298.666667 328.96 328.96 298.666667 194.56 164.48">
          </polygon>
        </g>
      </g>
      </svg>    
    </div>;

  return (
    <>
        <div id = "search-box" className={lClassName} ref={searchBoxRef} >
            <svg id="search-icon" viewBox="0 0 1.16782 1.20302">
                <path fill="currentColor"  d="M0.447838 0c0.122734,0 0.234224,0.0475133 0.315284,0.124261 0.081864,0.0775727 0.132534,0.184957 0.132534,0.303689 0,0.1033 -0.0383531,0.198017 -0.102247,0.271979l0.355989 0.386131c0.0260776,0.0283264 0.0242415,0.0724562 -0.00408495,0.0985339 -0.0283264,0.0260776 -0.0724562,0.0242415 -0.0985339,-0.00408495l-0.360177 -0.390649c-0.0691965,0.0417985 -0.151102,0.06604 -0.238763,0.06604 -0.122734,0 -0.234266,-0.0475133 -0.315284,-0.124261 -0.0818846,-0.0775727 -0.132554,-0.184957 -0.132554,-0.303689 0,-0.118732 0.0506699,-0.226116 0.132554,-0.303689 0.0810181,-0.0767475 0.19255,-0.124261 0.315284,-0.124261zm0.219226 0.225229c-0.0557451,-0.0527949 -0.133235,-0.0854744 -0.219226,-0.0854744 -0.0859902,0 -0.163501,0.0327002 -0.219226,0.0854744 -0.0548992,0.0520109 -0.0888579,0.123704 -0.0888579,0.202721 0,0.0790169 0.0339587,0.15071 0.0888579,0.202721 0.0557245,0.0527742 0.133235,0.0854744 0.219226,0.0854744 0.0859902,0 0.16348,-0.0326796 0.219226,-0.0854744 0.0548786,-0.0519902 0.0888373,-0.123683 0.0888373,-0.202721 0,-0.0790169 -0.0339587,-0.15073 -0.0888373,-0.202721z"/>
            </svg>
            <input type="Text" id="search-field"
              ref={domTextbox}
              placeholder={placeholderText}
              disabled = {lSubControlsAreDisabled}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
            />
            {showCancel ? cancelIcon : advancedSearchIcon}
        </div>
    { advancedSearchVisible && <ModalBox
          setVisible={setAdvancedSearchVisible}
          childComponent={
            <AdvancedSearch setModalVisibleCallback={setAdvancedSearchVisible}/>
            } />
      }
    </>
    );
  }
  
  export default SearchBox;
