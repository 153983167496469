import "../styling/saved-bookmarks-panel.scss";
import { FC, useState, useRef } from 'react';
import type { Bookmark } from '../interfaces/Bookmark';
import { Tree } from 'react-arborist';
import BookmarkTreeNode from './BookmarkTreeNode';
import { ReactComponent as FolderIcon } from "../shared/assets/images/icons/folder-icon.svg";
import { useAddBookmarkMutation, useUpdateBookmarkMutation } from '../slices/apiSlice';

export interface BookmarkTreeProps {
  initialBookmarks: Bookmark[]
}

//interface RenameProps<T> {
//  id: string;
//  name: string;
//  node: NodeApi<T>
//}
//
//interface DeleteProps {
//  ids: string[];
//}
//
//interface CreateProps {
//  parentId: string | null;
//  //  parentNode: NoteApi<T> | null;
//  index: number;
//  type: "internal" | "leaf";
//}

interface MoveProps {
  dragIds: string[];
  //  dragNodes: NodeApi<T>[];
  parentId: string | null;
  //parentNode: NodeApi<T> | null;
  index: number;
}

function getParentIdFromParent(parent: Bookmark) : string {
  if (parent.parentId === '') {
    return parent.bookmarkId;
  }
  return parent.parentId + '-' + parent.bookmarkId;
}

function getNumberNodes(bookmarks: Bookmark[]) : number {

  if (null == bookmarks || 0 === bookmarks.length) {
    return 0;
  }

  let count = 0;
  for (let i=0; i< bookmarks.length; ++i) {
    let children = null;
    if ('children' in bookmarks[i]) {
      children = bookmarks[i].children
    }
    if (children) {
      const numChildren = getNumberNodes(children);
      count = count + numChildren + 1;
    }
    else {
      count = count + 1;
    }
  }
  return count;  
}

const BookmarkTree: FC<BookmarkTreeProps> = ({ initialBookmarks }) => {
  // declare the tree ref so we can use it
  const treeRef = useRef<any>(null);

  const [bookmarks, setBookmarks] = useState<Bookmark[]>(initialBookmarks);
  const [addingNewFolder, setAddingNewFolder] = useState<boolean>(false);

  const [updateBookmark, updateBookmarkResult] = useUpdateBookmarkMutation();
  const [addBookmark, addBookmarkResult] = useAddBookmarkMutation();

  const newFolder = () => {
    setAddingNewFolder(true);
  }

  // onRename is also handled in the node directly 
  //  const onRename = ( {id, name, node}:RenameProps<ExtendedLLO> ) =>  {
  //  }

  // onDelete and onCreate are being handled automagically by sending the bookmark to the backend
  // and the cache being invalidated
  //  const onDelete = ( {ids}: DeleteProps) => {
  //    console.log('onDelete');
  //  }
  //
  //  const onCreate = ({parentId, index, type}: CreateProps):any => {
  //    console.log('onCreate, parentId: ', parentId, ", index: ", index, ", type: ", type);
  //    treeRef.current.create(parentId);
  //  }

  const onMove = ({ dragIds, parentId, index}:MoveProps) => {
    // the parentId is the target to which a dragId(s) has been dragged to
    const parentNode = treeRef.current.get(parentId);
    // target node must be a folder
    if (parentNode && parentNode.data && parentNode.data.lexType === 'bookmark-folder') {

      for (const id of dragIds) {
        const node = treeRef.current.get(id);
        if (node && node.data) {
          const bookmarkToModify: Bookmark = node.data;

          const parentId = getParentIdFromParent(parentNode.data);
          if (parentId) {

            updateBookmark({
              "bookmarkId": bookmarkToModify.bookmarkId,
              "lexType": bookmarkToModify.lexType,
              "displayTitle": bookmarkToModify.displayTitle,
              "instanceId": bookmarkToModify.instanceId,
              //"jurisIDs": lloToModify.jurisIDs,
              "parentId": parentId,
              "order": 0 // TODO fix this
              //            "parent": parentNode.data
            });
          }
          else {
            console.log('no parent id');
          }
        }
      }
    }
    else {
      for (const id of dragIds) {
        const node = treeRef.current.get(id);
        if (node && node.data) {
          const bookmarkToModify: Bookmark = node.data;
          updateBookmark({
            "bookmarkId": bookmarkToModify.bookmarkId,
            "lexType": bookmarkToModify.lexType,
            "displayTitle": bookmarkToModify.displayTitle,
            "instanceId": bookmarkToModify.instanceId,
            "parentId": '',
            "order": 0
          });
        }
      }
    }
  }

  const padding = 8;
  const rowHeight = 28;
  const numRows = getNumberNodes(bookmarks);
  //  const height = (numRows - 1) * (rowHeight + padding/2) + padding/2;
  const height = numRows*rowHeight + 2*padding;

  return (
    <>
    <Tree
      data={bookmarks}
      indent={16}
      padding={padding}
      rowHeight={rowHeight}
      width={460}
      height={height}
      ref={treeRef}
      //idAccessor='bookmarkId'
      idAccessor={(d) => d.bookmarkId.toString()}
      //onRename={onRename}
      //onDelete={onDelete}
      //onCreate={onCreate}
      onMove={onMove}
    >
    {BookmarkTreeNode}
    </Tree>
    <div id="saved-bookmarks-panel-footer">
      <div id="new-folder-button" className="footer-button" onClick={newFolder}>
        <div className="action-panel left-action-panel">
          <FolderIcon id="new-folder-icon" className="action-button"/>
        </div>
        {addingNewFolder ? (
          <input
            type="text"
            onFocus={(e) => e.currentTarget.select()}
            onBlur={() => setAddingNewFolder(false)}
            onKeyDown={(e)=>{
              if (e.key === "Escape") setAddingNewFolder(false);
              if (e.key === "Enter" || e.key === "Tab") {
                addBookmark({
                  "displayTitle": e.currentTarget.value,
                  "lexType": "bookmark-folder",
                  "instanceId": "",
                  "jurisIDs": []
                });
                setAddingNewFolder(false);
              }
            }}
            autoFocus
          />
        ) : (
            <div className="caption">
              New folder...
            </div>
        )}
      </div>
    </div>
    </>
  );
}

export default BookmarkTree;
