import "../styling/user-profile-panel.scss";
import "../styling/section-title.scss";
import { useEffect, useState } from 'react';
import SelectInput from './SelectInput';
import type { UserProfileForm } from '../interfaces/UserProfileForm';
import type { SelectOption } from '../interfaces/SelectOption';
import { useGetProfileQuery, useUpdateProfileMutation } from '../slices/apiSlice';
import LexogonLoader from './LexogonLoader';

const initialState = {
  email: '',
  industry: '',
  productSpeciality: '',
  deviceSpeciality: '',
  businessRole: '',
} as UserProfileForm;

const UserProfile = () => {
  const [htmlFormChanged, setFormChanged] = useState<boolean>(false);
  const [formValue, setFormValue] = useState(initialState);
  const [lastSavedFormValue, setLastSavedFormValue] = useState(initialState);

  const {
    data: profile,
    isLoading: isProfileLoading,
    isFetching: isProfileFetching,
    isSuccess: isProfileSuccess,
    isError: isProfileError,
    error: profileError
  } = useGetProfileQuery();

  const [ updateProfile, {
    data: updateData,
    isSuccess: isUpdateSuccess,
    isError: isUpdateError,
    error: updateError},] = useUpdateProfileMutation();


  const handleChange = (e: any) => {
    setFormValue({...formValue, [e.target.name]:e.target.value});
  }

  useEffect(() => {
    if (JSON.stringify(formValue) === JSON.stringify(lastSavedFormValue)) {
      setFormChanged(false);
    }
    else {
      setFormChanged(true);
    }

  }, [formValue, lastSavedFormValue]);

  useEffect(() => {
    if (isProfileSuccess && profile) {
      console.log('got profile: ', profile)
      setLastSavedFormValue(profile);
      setFormValue(profile);
      setFormChanged(false);
    }
  }, [profile, isProfileSuccess]);

  const attemptProfileUpdate = async () => {
    await updateProfile(formValue);
  };


  const industries: SelectOption[] = [
    {'value': '', 'label': ''},
    {'value': 'medical-devices', 'label': 'Medical Devices'},
    {'value': 'pharmaceuticals', 'label': 'Pharmaceuticals'}
  ];

  const productSpecialities: SelectOption[] = [
    {'value': "", 'label': ''},
    {'value': "drugs", 'label': "Drugs"},
    {'value': "medical-devices", 'label': "Medical Devices"},
    {'value': "biologics", "label":"Biologics"},
    {'value': "combination-products", "label": "Combination Products"},
    {'value': "radiation-emitting-products", "label": "Radiation-emitting Products"},
    {'value': "in-vitro-diagnostics", "label": "In vitro Diagnostics"},
    {'value': "other", "label": "Other"}
  ];

  const deviceSpecialities: SelectOption[] = [
    {"value": "", "label": ""},
    {"value": 'anesthesiology-and-pain-management', "label":"Anesthesiology and Pain Management"},
    {"value": 'cardiology', "label":"Cardiology"},
    {"value": 'clinical-chemistry', "label": "Clinical Chemistry"},
    {"value": 'dentistry', "label":"Dentistry"},
    {"value": 'endocrinology', "label": "Endocrinology"},
    {"value": 'gastroenterology-and-urology', "label":"Gastroenterology and Urology"},
    {"value": 'general-medicine', "label":"General Medicine"},
    {"value": 'hematology', "label": "Hematology"},
    {"value": 'immunology', "label": "Immunology"},
    {"value": 'medical-genetics', "label": "Medical Genetics"},
    {"value": 'mental-health', "label": "Mental Health"},
    {"value": 'microbiology', "label":"Microbiology"},
    {"value": 'neurology', "label": "Neurology"},
    {"value": 'obstetrics-and-gynecology', "label":"Obstetrics and Gynecology"},
    {"value": 'oncology', "label":"Oncology"},
    {"value": 'opthalmology-and-optometry', "label": "Opthalmology and Optometry"},
    {"value": 'orthopedics-and-podiatry', "label":"Orthopedics and Podiatry"},
    {"value": 'otolaryngology', "label": "Otolaryngology"},
    {"value": 'pathology', "label": "Pathology"},
    {"value": 'pediatrics', "label": "Pediatrics"},
    {"value": 'physical-medicine', "label": "Physical Medicine"},
    {"value": 'plastic-surgery', "label":  "Plastic Surgery"},
    {"value": 'pulmonology', "label": "Pulmonology"},
    {"value": 'radiology', "label":"Radiology"},
    {"value": 'rare-diseases', "label": "Rare Diseases"},
    {"value": 'toxicology', "label": "Toxicology"},
    {"value": 'other', "label": "Other"}
  ];

  const businessRoles: SelectOption[] = [
    {"value": "", "label":""},
    {"value": "quality-assurance", "label":"Quality Assurance"},
    {"value": "regulatory-affairs", "label":"Regulatory Affairs"},
    {"value": "consultant", "label":"Consultant"},
    {"value": "engineering", "label":"Engineering"},
    {"value": "executive", "label": "Executive"},
    {"value": "other", "label": "Other"}
  ];

  const handleSaveButtonClick = () => {
    attemptProfileUpdate()
  }

  if (isProfileLoading || isProfileFetching) return(<LexogonLoader/>);

  return (
    <div>
      <div className="section-title">User Profile</div>
      <div >
        <SelectInput
          id='industry'
          name='industry'
          label= 'Primary Industry:'
          selectOptions={industries}
          onChange={handleChange}
          value={formValue.industry}
        />
        <SelectInput
          id='business-role'
          name='businessRole'
          label='Business role:'
          selectOptions={businessRoles}
          onChange={handleChange}
          value={formValue.businessRole}
        />
        <SelectInput
          id='product-speciality'
          name='productSpeciality'
          label='Product speciality:'
          selectOptions={productSpecialities}
          onChange={handleChange}
          value={formValue.productSpeciality}
        />
        <SelectInput
          id='device-speciality'
          name='deviceSpeciality'
          label= 'Device speciality:'
          selectOptions={deviceSpecialities}
          onChange={handleChange}
          value={formValue.deviceSpeciality}
        />
        <p><button type="button" disabled={!htmlFormChanged} onClick={handleSaveButtonClick}>Save</button></p>
      </div>
    </div>
  )
}

export default UserProfile;
