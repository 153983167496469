import "../styling/block-header.scss";
import { FC } from 'react';
import Descriptor from "../shared/descriptor/descriptor";
import type { Reference } from "../interfaces/types";

export interface NeoReferenceBlockHeaderModel {
  reference: Reference;
}

export interface NeoReferenceBlockHeaderProps extends NeoReferenceBlockHeaderModel{
}

const NeoReferenceBlockHeader: FC<NeoReferenceBlockHeaderProps> = ({ reference }) => {
  return (
    <div id={reference.id.toString()} className="block-header" >
        <Descriptor title={reference.pedigree} jurisIDs={[reference.jurisdiction.countryCode]} status={reference.status} outsideLink={reference.sourceUrl} />
    </div>
  );
}
  
export default NeoReferenceBlockHeader;
