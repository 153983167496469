//import { useNavigate } from 'react-router-dom';
//import { useGetReferenceStatusesQuery } from '../slices/apiSlice';
import DashboardMenuItem from './DashboardMenuItem';
//import LexogonLoader from './LexogonLoader';

import { ReactComponent as UnknownIcon } from '../shared/assets/images/icons/question-mark-icon.svg';
import { ReactComponent as ActiveIcon } from '../shared/assets/images/icons/heartbeat-icon.svg';
import { ReactComponent as FutureIcon } from '../shared/assets/images/icons/future-icon.svg';
import { ReactComponent as DeprecatedIcon } from '../shared/assets/images/icons/cancelled-icon.svg';
import { ReactComponent as WorkInProgressIcon } from '../shared/assets/images/icons/wip-icon.svg';
import { ReactComponent as NonActiveIcon } from '../shared/assets/images/icons/moon-icon.svg';

const ReferenceStatusPanel = () => {

  // TODO right now the statuses are handcoded.  Could be retrieved from back end through query, but need
  // to map the icon to the right status.
  //  const navigate = useNavigate();

  //  function handleClick(s: string) {
  //    navigate(`/reference/status/${s}`);
  //  }

  //  const {
  //    data: statuses,
  //    isSuccess,
  //    isLoading,
  //  } = useGetReferenceStatusesQuery();
  //
  //  let content;
  //
  //  if (isLoading) {
  //    content = <LexogonLoader />;
  //  }
  //
  //  if (isSuccess && statuses) {
  //     content = statuses.map((j, index) => (
  //      <div className="link-list-item" key={index} onClick={() => handleClick(j.instanceId.toString())} >
  //        <span className="link-list-text">{ j.displayTitle }</span>
  //      </div>
  //    ))
  //  }

  return (
    <div className="centered-panel">
      <div className="title-wrapper">
        <div className="title">
          Reference by Status
        </div>
      </div>
      <div className="content">
        <DashboardMenuItem
          icon = {<ActiveIcon />}
          title = {"Active"}
          navigateTo = {'ACTIVE'}
        />
        <DashboardMenuItem
          icon = {<FutureIcon />}
          title = {"Future"}
          navigateTo = {'FUTURE'}
        />
        <DashboardMenuItem
          icon = {<DeprecatedIcon />}
          title = {"Deprecated"}
          navigateTo = {'DEPRECATED'}
        />
        <DashboardMenuItem
          icon = {<WorkInProgressIcon />}
          title = {"Work-in-progress"}
          navigateTo = {'WORK_IN_PROGRESS'}
        />
        <DashboardMenuItem
          icon = {<NonActiveIcon />}
          title = {"Non-active"}
          navigateTo = {'NON_ACTIVE'}
        />
        <DashboardMenuItem
          icon = {<UnknownIcon />}
          title = {"Unknown"}
          navigateTo = {'UNKNOWN'}
        />
      </div>
    </div>
  );

}

export default ReferenceStatusPanel;
