import { configureStore } from '@reduxjs/toolkit'
import { apiSlice } from '../slices/apiSlice'
import { setupListeners } from '@reduxjs/toolkit/query'

import searchReducer from '../slices/searchSlice';
import advancedSearchReducer from '../slices/advancedSearchSlice'
import userSessionReducer from '../slices/userSessionSlice';
import activeObjectReducer from '../slices/activeObjectSlice';
import tocReducer from '../slices/tocSlice';

export const store = configureStore({
    reducer: {
      search: searchReducer,
      advancedSearch: advancedSearchReducer,
      userSession: userSessionReducer,
      activeObject: activeObjectReducer,
      toc: tocReducer,
      [apiSlice.reducerPath]: apiSlice.reducer
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(apiSlice.middleware)
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

setupListeners(store.dispatch)
