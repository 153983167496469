import type { LexLightObject } from '../interfaces/LexLightObject';

export function urlFromLLO(lexObject: LexLightObject) : string {

  const lexType = lexObject.lexType;

  if (lexType === 'term') {
    return `/term/${lexObject.instanceId}`;
  }
  else if (lexType === 'document') {
    return `/document/${lexObject.instanceId}`;
  }

  // TODO
  return '';
}
