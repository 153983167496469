export function prettyPrintRefStatus(refStatus: string): string {

  switch (refStatus.toUpperCase()) {
    case 'UNKNOWN':
      return "unknown";
    case 'ACTIVE':
      return 'active';
    case 'FUTURE':
      return 'future';
    case 'DEPRECATED':
      return 'deprecated';
    case 'WORK_IN_PROGRESS':
      return 'work-in-progress';
    case 'NON_ACTIVE':
      return 'non-active';
    default:
      return refStatus;
  }
}
