import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { AdvancedSearchQuery } from '../interfaces/AdvancedSearchQuery';

const initialState = {
  searchString: '',
  types: ['TERM', 'REFERENCE'],
  jurisdictions: [
    "00",
    "EU",
    "US",
  ],
  pedigrees: [
    "GUIDANCE",
    "LEGISLATION",
    "WORKING_GROUP_PUBLICATION",
  ],
  sources: [
    "EURLEX",
    "FDA",
    "IMDRF",
    "ECFR",
    "MDCG",
    "US_GOV_INFO",
  ],
  statuses: [
    "ACTIVE",
    "FUTURE",
    "DEPRECATED",
    "WORK_IN_PROGRESS",
    "NON_ACTIVE"
  ]
} as AdvancedSearchQuery;

export const advancedSearchSlice = createSlice({
  name: "advancedSearch",
  initialState,
  reducers: {
    advancedSearch: (state, action: PayloadAction<AdvancedSearchQuery>) => {
      console.log("advancedSearch got payload: ", action.payload);
      // have to explicitly write out copy to avoid getting immer'd
      state.searchString = action.payload.searchString;
      state.types = action.payload.types;
      state.jurisdictions = action.payload.jurisdictions;
      state.pedigrees = action.payload.pedigrees;
      state.sources = action.payload.sources;
      state.statuses = action.payload.statuses;
      console.log('new state is ', state);
    },
    clearAdvancedSearch: (state, action: PayloadAction<void>) => {
      //state = initialState;
      state.searchString = '';
    },
  }

});

export const { advancedSearch, clearAdvancedSearch } = advancedSearchSlice.actions;
export default advancedSearchSlice.reducer
