import "../styling/sign-in-panel.scss";
import "../styling/full-screen-dialog.scss";
import "../styling/sign-or-not.scss";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Textbox from "./Textbox";
import EmailFormatValidator from "../common/EmailFormatValidator";
import { storeAuthentication } from '../slices/userSessionSlice';
import { useLoginMutation } from '../slices/apiSlice';
import { useAppDispatch } from '../store/hooks';

//export type SignInPanelProps = {
//    errorMessage : string | null;
//    signInRequestHandler : (username: string, pass: string) => void;    
//    credentialsChangeHandler : () => void;
//}

//const SignInPanel: FC<SignInPanelProps> = ({errorMessage, signInRequestHandler, credentialsChangeHandler: credentialsChanged}) =>{
const SignInPanel = () => {

  const [emailFormatIsValid, setEmailFormatIsValid] = useState<boolean>(false);
  const [passHasBeenFilledIn, setPassHasBeenFilledIn] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const errorMessage = '';

  const [ loginUser, {data: loginData, isSuccess: isLoginSuccess, isError: isLoginError, error: loginError},] = useLoginMutation();

  const handleEmailTextboxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    //credentialsChanged();
    setEmail(event.target.value);
    setEmailFormatIsValid(EmailFormatValidator.EmailFormatIsValid(event.target.value)); 
  }  

  const handlePassTextboxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    //credentialsChanged();
    setPassword(event.target.value);
    setPassHasBeenFilledIn(event.target.value.length > 3); 
  }  

  const handleKeyUp = (event: React.KeyboardEvent<HTMLDivElement>): void => {
      if (event.key === 'Enter' )
          attemptSignIn();            
  };

  const handleSignUpButtonClick = () => {
      attemptSignIn();            
  }

  const attemptSignIn = async () => {
    if (emailFormatIsValid && passHasBeenFilledIn) {
      const username = email;
      await loginUser({username, password});
    }
    else {
      console.log("email or password problem");
    }
  };

  useEffect(() => {
      if (isLoginSuccess) {
          console.log('loginData: ', loginData);
          if (loginData ) {
              dispatch(storeAuthentication(loginData));
              navigate('/user');
          }
      }
  });

  return (
      <div id = "sign-in-panel" className="full-screen-dialog" onKeyUp={handleKeyUp}>
          <div id = "centered-column">
              <p id="sign-in-instructions-panel">Sign in</p>
              <Textbox id="sign-in-email-textbox" textboxType ="text" placeholder="email" changeHandler={handleEmailTextboxChange} autofocus={true} />
              <Textbox id="sign-in-pass-textbox" textboxType ="password" placeholder="password" changeHandler={handlePassTextboxChange}/>
              {
                  errorMessage !== null &&(
                  <div id="error-panel" >
                      {errorMessage}
                  </div>
                  )
              }
              <button type="button" id="sign-in-button" disabled={!emailFormatIsValid || !passHasBeenFilledIn} onClick={handleSignUpButtonClick}>Sign in</button>
            <div id="sign-or-not">Not registered?  <a href="/sign-up">Sign up</a></div>
          </div>
      </div>
  );
}

export default SignInPanel;
