export function eurlexReferenceToCelexRelationship(relationship: string) {

  switch (relationship) {
        case 'EURLEX_TREATY':
          return 'is a treaty on the Functioning of the European Union';
        case 'EURLEX_LEGAL_BASIS':
          return 'has as legal basis';
        case 'EURLEX_PROPOSAL':
          return 'is a proposal to';
        case 'EURLEX_AMENDMENT_TO':
          return 'is an amendment to';
        case 'EURLEX_AMENDED_BY':
          return 'is amended by';
        case 'EURLEX_INSTRUMENT_CITED':
          return 'is cited by';
        case 'EURLEX_SUBSEQUENT_RELATED_INSTRUMENT':
          return 'is subsequent to';
        case 'EURLEX_IS_BASED_ON':
          return 'is based on';
        default:
          return 'unknown';
  }
}
