import type { SelectOption} from '../interfaces/SelectOption';
import { FC } from 'react';
import '../styling/select-label.scss'
import '../styling/select-input.scss'
import '../styling/select-wrapper.scss'

export interface SelectInputProps {
  id: string;
  name: string;
  label: string;
  selectOptions: SelectOption[];
  onChange: (e: any) => void;
  value: string;
}

const SelectInput: FC<SelectInputProps> = ({id, name, label, selectOptions, onChange, value }) => {

  return (
    <div className="select-wrapper">
      <div className="select-label">
        <label htmlFor={id}>{label}</label>
      </div>
      <div className="select-input">
        <select id={id} name={name} onChange={onChange} value={value}>
          { selectOptions.map((opt, index) => (
            <option key={index} value={opt.value}>{opt.label}</option>
          ))}
        </select>
      </div>
    </div>
  )
}

export default SelectInput;
