// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flag-strip {
  display: inline-grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-gap: 0.5em;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/shared/flag-strip/flag-strip.scss"],"names":[],"mappings":"AAAA;EACI,oBAAA;EACA,sBAAA;EACA,8BAAA;EACA,eAAA;EACA,mBAAA;AACJ","sourcesContent":[".flag-strip{\n    display: inline-grid;\n    grid-auto-flow: column;\n    grid-auto-columns: max-content;\n    grid-gap: 0.5em;\n    align-items: center;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
