import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SearchText {
    text: string;
    options: [];
    pastSearches: string[];
    currentResults: [];
}

const initialState = {
    text: "",
    options: [],
    pastSearches: [],
    currentResults: [],
} as SearchText

export const searchSlice = createSlice({
    name: "search",
    initialState,
    reducers: {
        search: (state, action: PayloadAction<string>) => {
            state.pastSearches.push(state.text);
            state.text = action.payload;
            console.log("got search ", action.payload);
            // TODO: options when those are figured out
        },
        clearSearch: (state, action: PayloadAction<void>) => {
            state.text = "";
        },
    },
});

export const { search, clearSearch } = searchSlice.actions;
export default searchSlice.reducer;
