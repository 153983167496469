import { memo, FC } from 'react';
import '../styling/reference-entries.scss';
import type { ReferenceEntry } from '../interfaces/types';
import type { ReferenceContainsUrlRelationship } from '../interfaces/ReferenceRelationships';
import type { TermLink } from '../interfaces/TermLink';
import { potentialTermSet } from '../common/potentialTermSet';
import reactStringReplace from 'react-string-replace';

export interface ReferenceEntryParagraphModel {
  entry: ReferenceEntry;
  index: number;
  termLinks: TermLink[];
  refLinks: ReferenceContainsUrlRelationship[];
  //alreadyLinkedTerms: string[];
  isTermAlreadyLinked: (term: string) => boolean;
  addAlreadyLinkedTerm: (term: string) => void;
}

const ReferenceEntryParagraph: FC<ReferenceEntryParagraphModel> = ({ entry, index, termLinks, refLinks, isTermAlreadyLinked, addAlreadyLinkedTerm }) => {

  const potentialTerms = potentialTermSet(entry.content, 5);
  const linkableTerms: TermLink[] = []
  const potentialTermArray = Array.from(potentialTerms);

  const linkedThisParagraph: string[] = [];
  potentialTermArray.sort((a, b) => b.length - a.length);

  for (const potentialTerm of potentialTermArray) {
    if (!isTermAlreadyLinked(potentialTerm)) {
      for (const termLink of termLinks) {
        if (termLink.term === potentialTerm) {
          linkableTerms.push(termLink);
          //alreadyLinkedTerms.push(termLink.term);
        }
      }
    }
  }
  //addAlreadyLinkedTerm(index.toString());
  //alreadyLinkedTerms.push(index.toString());
  linkableTerms.sort((a, b) => b.term.length - a.term.length);

  const linkableRefs = refLinks.filter(e => e.reference_entry === entry.documentEntryUid);

  const initContent = entry.content;
  var content;
  var spanCount = 0;

  if (linkableRefs.length) {
    var re1 = new RegExp('\\b('+linkableRefs[0].subString+')\\b', 'i');
    content = reactStringReplace(initContent, re1, (match, i) => (
      <span key={`pr-${index}-${entry.documentEntryUid}-${spanCount}`} title={`Go to external link "${linkableRefs[0].subString}"`}><a href={linkableRefs[0].externalLink} target="_blank" rel="noreferrer">{match}</a></span>
    ));
    ++spanCount;
  
    for (let a=1; a<linkableRefs.length; ++a) {
      var re1 = new RegExp('\\b('+linkableRefs[a].subString+')\\b', 'i');
      content = reactStringReplace(content, re1, (match, i) => (
        <span key={`pr-${index}-${entry.documentEntryUid}-${spanCount}`} title={`Go to external link "${linkableRefs[a].subString}"`}><a href={linkableRefs[a].externalLink} target="_blank" rel="noreferrer">{match}</a></span>
      ));
      ++spanCount;
    }
  }
  else {
    content = initContent;
  }

  if (0 != linkableTerms.length) {
    var re1 = new RegExp('\\b('+linkableTerms[0].term+')\\b', 'i');
    let count =0;
    content = reactStringReplace(content, re1, (match, i) => {
      if (count == 0) {
        ++count;
        return (<span key={`pt1-${entry.documentEntryUid}-${spanCount}-${i}`} title={`Go to term "${linkableTerms[0].term}"`}><a href={`/term/${linkableTerms[0].slug}`}>{match}</a></span>
      )}
      else {
        return (<>{match}</>)
      }
    });
    ++spanCount;

    for (let a=1; a<linkableTerms.length; ++a) {
      var tempTerm = linkableTerms[a].term;
      var re2 = new RegExp('\\b('+linkableTerms[a].term+')\\b', 'i');
      var count2 = 0;
        content = reactStringReplace(content, re2, (match, i) => {
          if (count2 ==0) {
            ++count2;
            return <span key={`pt2-${match}-${entry.documentEntryUid}-${spanCount}-${i}`} title={`Go to term "${linkableTerms[a].term}"`}><a href={`/term/${linkableTerms[a].slug}`}>{match}</a></span>
          }
          else {
            return <>{match}</>
          }
        });
        ++spanCount;
    }
  }
  else {
    content = entry.content;
  }

  return (
    <p className='entry-paragraph' key={entry.documentEntryUid}  id={entry.documentEntryUid}  title={entry.sectionId}>{content}</p>
  );
}

export default memo(ReferenceEntryParagraph);
