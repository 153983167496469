import SignInPanel from './SignInPanel';

interface LoginRedirectProps {
  isLoggedIn: boolean;
  loggedInTarget: React.ReactElement;
}

const LoginRedirect: React.FC<LoginRedirectProps> = ({isLoggedIn, loggedInTarget}): React.ReactElement => {
  if (isLoggedIn) {
    return loggedInTarget;
  }
  else {
    return <SignInPanel />;
  }
}

export default LoginRedirect;
