import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

interface AppMenuOptionProps {
  optionId : string;
  icon: JSX.Element;
  text: string
  clickHandler: (buttonId: string) => void;
  destination?: string;
}

const AppMenuOption: FC<AppMenuOptionProps> = ({optionId, icon, text, clickHandler, destination }) => {
  const navigate = useNavigate()

  const handlerWrapper = (buttonId: string) => {
    clickHandler(buttonId)
    if (destination) navigate(destination);
  }

  return (
    <div className="app-menu-option" onClick={ () => handlerWrapper(optionId) }>
      <div className="icon-area">{icon}</div>
      <div className="text-area">{text}</div>
    </div>
  );
}

export default AppMenuOption;
