export function getReferenceEntryClassName(contentType: string): string {
  switch (contentType) {
    case "DOCUMENT_TITLE":
      return 'document-title'
    case "CHAPTER_TITLE":
      return 'chapter-title';
    case "SECTION_TITLE":
      return 'section-title';
    case "SUBSECTION_TITLE":
      return 'subsection-title';
    case "SUBSECTION_LABEL":
      return 'subsection-label';
    case "ARTICLE_TITLE":
      return 'article-title';
    case "ARTICLE_LABEL":
      return 'article-label';
    case "TABLE_OF_CONTENTS_TITLE":
      return 'toc-title';
    case "TABLE_OF_CONTENTS_ELEMENT":
      return 'toc-element';
    case "PARAGRAPH":
      return 'entry-paragraph';
    case "CITATION":
      return 'citation';
    default:
      return 'unknown-entry-type';
  }
}
