import '../styling/slide-out-panel.scss';
import '../styling/celex-info.scss';
import type { LexLightObject } from '../interfaces/LexLightObject';
import { useGetReferenceRelationshipsQuery } from '../slices/apiSlice';
import LLOLinkList from './LLOLinkList';
import CelexInfo from './CelexInfo';
import { eurlexReferenceToCelexRelationship } from '../common/eurlexReferenceToCelexRelationship';
import type { ReferenceContainsUrlRelationship } from '../interfaces/ReferenceRelationships';
import OutsideLinkList from './OutsideLinkList';

const ReferenceRelationshipsPanel: React.FC<LexLightObject> = (lexObject: LexLightObject) => {

  const {
    data: relationships,
    isLoading:
    isQueryLoading,
    isSuccess: isQuerySuccess,
    isFetching: isQueryFetching,
    isError: isQueryError,
    error: queryError
  } = useGetReferenceRelationshipsQuery(lexObject.instanceId.toString());

  if (isQueryLoading || isQueryFetching) return (<></>);
  if (!relationships) return (<div>Could not get relationships</div>);
  if (isQueryError) return (<div>Query error</div>);

  let refersTo: LexLightObject[] = [];
  let relatedTo: LexLightObject[] = [];
  let unknownRelation: LexLightObject[] = [];

  let linksTo: ReferenceContainsUrlRelationship[] = [];
  if (relationships && relationships.containsUrls) {
    linksTo = relationships.containsUrls;
  }

  if (relationships && relationships.relationships) {
    const thisReferenceId = lexObject.instanceId;
    for (let i=0; i<relationships.relationships.length; ++i) {
      let relationship = relationships.relationships[i];
      if (relationship.twoReferenceType === 'REFERS_TO') {
        if (relationship.referenceOne.instanceId == thisReferenceId) {
          refersTo.push(relationship.referenceTwo);
        }
        else {
          refersTo.push(relationship.referenceOne);
        }
      }
      else if (relationship.twoReferenceType === 'IS_RELATED_TO') {
        if (relationship.referenceOne.instanceId == thisReferenceId) {
          relatedTo.push(relationship.referenceTwo);
        }
        else {
          relatedTo.push(relationship.referenceOne);
        }
      }
      else {
         if (relationship.referenceOne.instanceId == thisReferenceId) {
          unknownRelation.push(relationship.referenceTwo);
        }
        else {
          unknownRelation.push(relationship.referenceOne);
        }
      }
    }
  }

  let terms: LexLightObject[] = [];
  if (relationships && relationships.definitions) {
    terms = relationships.definitions.map(({ term }) => term);
  }

  return (
    <div className="content">
      <div className="section-title">
        Links to:
      </div>
      <>
        {linksTo.length ? <OutsideLinkList outsideLinks={linksTo} /> :  <p className="p-none"><i>None</i></p>}
      </>
      <div className="section-title">
        Refers to References: 
      </div>
      <>
        {refersTo.length ? <LLOLinkList lexObjects={refersTo} /> : <p className="p-none"><i>None</i></p>}
      </>
      <div className="section-title">
        Is related to:
      </div>
      <>
        {relatedTo.length ? <LLOLinkList lexObjects={relatedTo} /> : <p className="p-none"><i>None</i></p>}
      </>
      <div className="section-title">
        Definitions that cite or refer to this reference: 
      </div>
      <>
        {terms.length ? <LLOLinkList lexObjects={terms} /> : <p className="p-none"><i>None</i></p>}
      </>
      <>
      { relationships.celexes && relationships.celexes.length &&
        <>
        <div className="section-title">
          CELEX Info:
        </div>
          <div className="celex-info">
            <p><b>CELEX Number: </b>{relationships.celexes[0].celexNumber}</p>
            <p><b>Relation: </b>{eurlexReferenceToCelexRelationship(relationships.celexes[0].relation)}</p>
          </div>
        </>
      }
      </>
    </div>
  );
}

export default ReferenceRelationshipsPanel;
