import "../styling/content-block.scss";
import { Point } from "../shared/point/point";
import { FC, useState, useRef } from 'react';
import { WordPrevalence } from './WordPrevalence';
import { ExpansionState } from "../shared/expansion-button/expansion-button";
import ExpansionButton from "../shared/expansion-button/expansion-button";
import { ImportanceFilter } from "./ImportanceFilter";
export type ContentBlockType = "DOCUMENT_BLOCK" | "DEFINITION_BLOCK";
export type BlockInclusionReason = "SELECTED_BY_USER" | "SUGGESTED_BY_APP";
import type { Definition } from "../interfaces/types";
import type { LexLightObject } from "../interfaces/LexLightObject";
import NeoDefinitionBlockHeader from "./NeoDefinitionBlockHeader";
import BlockBodyTermNeo from "./BlockBodyTermNeo";
import { useGetTermLinksQuery } from '../slices/apiSlice';

export interface ContentBlockTermModel {
  term: LexLightObject;
  definitions: Definition[];
  inclusionReason? : BlockInclusionReason;
}

export interface ContentBlockTermProps extends ContentBlockTermModel{
  
}

function cursorIsWithinComponentBoundaries(cursorPosition : Point, element : HTMLDivElement) : boolean {
  const { left, top, right, bottom } = element.getBoundingClientRect();
  return (cursorPosition.x >= left && cursorPosition.x <= right && cursorPosition.y >= top && cursorPosition.y <= bottom);
}

const ContentBlockTerm: FC<ContentBlockTermProps> = ({
  term,
  definitions,
  inclusionReason = "SELECTED_BY_USER",
  }) => {

  const [expansionState, setExpansionState] = useState<ExpansionState>("EXPANDED");
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [titleIsHovered, setTitleIsHovered] = useState<boolean>(false);
  const contentBlockElement = useRef<HTMLDivElement | null>(null);

  const {
    data: termLinks,
    isLoading,
    isFetching,
    isSuccess,
    isError,
    error
  } = useGetTermLinksQuery();

  const handleMouseEnter = () => {
    // TODO this causes re-renders down to children so can be slow when term links must be re-linked
    setIsHovered(true);  
   };

  const handleMouseLeave = () => {
    // TODO this causes re-renders down to children so can be slow when term links must be re-linked
    setIsHovered(false);
  };

  const handleMouseEnteredTitle = () => {
    setTitleIsHovered(true);
  }
  const handleMouseLeftTitle = () => {
    setTitleIsHovered(false);
  }

  const handleTitleClicked = () => {
    toggleExpansionState();
  }
  const handleExpansionButtonClicked = () => {
    toggleExpansionState();
  }

  const toggleExpansionState = () => {
    setExpansionState(expansionState === "EXPANDED" ? "COLLAPSED" : "EXPANDED");
  }
 
  let classes: string[] = ["content-block"];
  
  if (expansionState === "COLLAPSED")
    classes.push("collapsed");
  if (expansionState === "EXPANDED")
    classes.push("expanded");  

  if (isHovered)
    classes.push("hovered");  
  if (titleIsHovered)
    classes.push("hovered-title");

  const expansionTooltip = (expansionState === "EXPANDED")? `Collapse definition`: "Expand definition";  

  const fullTitle = term.displayTitle + ((inclusionReason === "SUGGESTED_BY_APP")? " (related entry)" : "");

  return (
    <div ref={contentBlockElement} id={term.instanceId.toString()} className={classes.join(" ")} onMouseEnter={ handleMouseEnter} onMouseLeave={handleMouseLeave}> 
      <ExpansionButton extraClasses={["block-expansion-button"]} expansionState={expansionState} clickHandler={handleExpansionButtonClicked} onMouseEnter={handleMouseEnteredTitle} onMouseLeave={handleMouseLeftTitle} />
      <div className="title" title={expansionTooltip} onMouseEnter={handleMouseEnteredTitle} onMouseLeave={handleMouseLeftTitle} onClick={handleTitleClicked}>{fullTitle}</div>
      <NeoDefinitionBlockHeader
        term={term}
        definitions={definitions}
      />

    {expansionState === "EXPANDED" &&
    <BlockBodyTermNeo
        term={term}
        definition={definitions[0]}
        termLinks={termLinks}
      />
    }
    </div>
  );
}

export default ContentBlockTerm;
