import "../styling/sign-up-panel.scss";
import "../styling/full-screen-dialog.scss";
import "../styling/sign-or-not.scss";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Textbox from "./Textbox";
import { useSignUpMutation } from "../slices/apiSlice";
import { storeAuthentication } from '../slices/userSessionSlice';
import { useAppDispatch } from '../store/hooks';

function isValidEmail(email: string): boolean {
    const emailRegex: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    return emailRegex.test(email);
  }

const SignUpPanel = () => {

  const [emailIsValid, setEmailIsValid] = useState<boolean>(false);
  const [passIsValid, setPassIsValid] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [ signUpUser, 
    { data: signUpData,
      isSuccess: isSignUpSuccess,
      //      isError: isSignUpError,
      //      error: signUpError
    },] = useSignUpMutation();

    const attemptSignUp = async () => {
      if (emailIsValid && passIsValid) {
        await signUpUser({email, password});
      }
      else {
        console.log("email or password problem");
      }
    };

  useEffect(() => {
      if (isSignUpSuccess) {
          console.log('signUpData: ', signUpData);
          if (signUpData ) {
              dispatch(storeAuthentication(signUpData));
              navigate('/user');
          }
      }
  });

    const handleEmailTextboxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setEmail(event.target.value);
        setEmailIsValid(isValidEmail(event.target.value)); 
    }  

    const handlePassTextboxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setPassword(event.target.value);
        setPassIsValid(event.target.value.length > 6); 
    }  

    const handleKeyUp = (event: React.KeyboardEvent<HTMLDivElement>): void => {
        if (event.key === 'Enter' )
            attemptSignUp();
            
    };

    const handleSignUpButtonClick = () => {
        attemptSignUp();
    }

    return (
        <div id = "sign-up-panel" className="full-screen-dialog" onKeyUp={handleKeyUp}>
            <div id = "centered-column">
                <p id="registration-advantages-panel">Sign up, and gain access to our <b>entire</b> library of medical regulations and terms.</p>
                <Textbox id="sign-up-email-textbox" textboxType ="text" placeholder="email" changeHandler={handleEmailTextboxChange} autofocus={true}/>
                <Textbox id="sign-up-pass-textbox" textboxType ="password" placeholder="password" changeHandler={handlePassTextboxChange}/>
                <button type="button" id="sign-up-button" disabled={!emailIsValid || !passIsValid} onClick={handleSignUpButtonClick}>Sign up</button>
              <div id="sign-or-not">Already registered?  <a href="/sign-in">Sign in</a></div>

            </div>
        </div>
      );
}

export default SignUpPanel;
