import '../styling/advanced-search.scss';
import '../styling/checkbox.scss';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import type { AdvancedSearchQuery } from '../interfaces/AdvancedSearchQuery';
import { advancedSearch } from '../slices/advancedSearchSlice';
import JurisFlag from '../shared/flag-strip/juris-flag/juris-flag';
import { useAppSelector } from '../store/hooks';
import type { RootState } from '../store/store';

const booleanOptions =
  [
  "TERM",
  "REFERENCE",
  "00",
  "EU",
  "US",
  "GUIDANCE",
  "LEGISLATION",
  "WORKING_GROUP_PUBLICATION",
  "EURLEX",
  "FDA",
  "IMDRF",
  "ECFR",
  "MDCG",
  "US_GOV_INFO",
  "ACTIVE",
  "FUTURE",
  "DEPRECATED",
  "WORK_IN_PROGRESS",
  "NON_ACTIVE"
  ]

export type AdvancedSearchProps = {
  setModalVisibleCallback: React.Dispatch<React.SetStateAction<boolean>>;
}

const AdvancedSearch: FC<AdvancedSearchProps> = ({setModalVisibleCallback}) => {
  const [searchString, setSearchString] = useState<string>('');
  const [formValue, setFormValue] = useState<boolean[]>(new Array(booleanOptions.length).fill(true));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const previousOptions = useAppSelector((state: RootState) => state.advancedSearch);


  useEffect(() => {
    const trueIndices: number[] = [];
    for (let t=0; t<previousOptions.types.length; ++t) {
      trueIndices.push(booleanOptions.indexOf(previousOptions.types[t]));
    }

    for (let j=0; j<previousOptions.jurisdictions.length; ++j) {
      trueIndices.push(booleanOptions.indexOf(previousOptions.jurisdictions[j]));
    }

    for (let p=0; p<previousOptions.pedigrees.length; ++p) {
      trueIndices.push(booleanOptions.indexOf(previousOptions.pedigrees[p]));
    }

    for (let s=0; s<previousOptions.sources.length; ++s) {
      trueIndices.push(booleanOptions.indexOf(previousOptions.sources[s]));
    }

    for (let s=0; s<previousOptions.statuses.length; ++s) {
      trueIndices.push(booleanOptions.indexOf(previousOptions.statuses[s]));
    }

    const storedFormValue = new Array(booleanOptions.length).fill(false);
    for (let i=0; i< trueIndices.length; ++i) {
      storedFormValue[trueIndices[i]] = true;
    }

    setFormValue(storedFormValue);

  }, [previousOptions]);

  const handleSearchStringChange = (e: any) => {
    setSearchString(e.target.value);
  }

  const handleChange = (e: any) => {
      const position = booleanOptions.indexOf(e.target.name);
      const updatedState = formValue.map((item, index) => 
        index === position ? !item : item
      );
      setFormValue(updatedState);
  }

  const [jurisAll, setJurisAll] = useState<boolean>(true);
  const jurisSelectAll = () => {
    const indices: number[] = [];
    indices.push(booleanOptions.indexOf('00'));
    indices.push(booleanOptions.indexOf('EU'));
    indices.push(booleanOptions.indexOf('US'));
    const updatedState = formValue;
    for (let i=0; i<indices.length; ++i) {
      updatedState[indices[i]] = !jurisAll;
    }
    setFormValue(updatedState);
    setJurisAll(!jurisAll);
  }

  const [pedigreeAll, setPedigreeAll] = useState<boolean>(true);
  const pedigreeSelectAll = () => {
    const indices: number[] = [];
    indices.push(booleanOptions.indexOf('GUIDANCE'));
    indices.push(booleanOptions.indexOf('LEGISLATION'));
    indices.push(booleanOptions.indexOf('WORKING_GROUP_PUBLICATION'));
    const updatedState = formValue;
    for (let i=0; i<indices.length; ++i) {
      updatedState[indices[i]] = !pedigreeAll;
    }
    setFormValue(updatedState);
    setPedigreeAll(!pedigreeAll);
  }

  const [sourceAll, setSourceAll] = useState<boolean>(true);
  const sourceSelectAll = () => {
    const indices: number[] = [];
    indices.push(booleanOptions.indexOf('EURLEX'));
    indices.push(booleanOptions.indexOf('FDA'));
    indices.push(booleanOptions.indexOf('IMDRF'));
    indices.push(booleanOptions.indexOf('ECFR'));
    indices.push(booleanOptions.indexOf('MDCG'));
    indices.push(booleanOptions.indexOf('US_GOV_INFO'));
    const updatedState = formValue;
    for (let i=0; i<indices.length; ++i) {
      updatedState[indices[i]] = !sourceAll;
    }
    setFormValue(updatedState);
    setSourceAll(!sourceAll);
  }

  const [statusAll, setStatusAll] = useState<boolean>(true);
  const statusSelectAll = () => {
    const indices: number[] = [];
    indices.push(booleanOptions.indexOf('ACTIVE'));
    indices.push(booleanOptions.indexOf('FUTURE'));
    indices.push(booleanOptions.indexOf('DEPRECATED'));
    indices.push(booleanOptions.indexOf('WORK_IN_PROGRESS'));
    indices.push(booleanOptions.indexOf('NON_ACTIVE'));
    const updatedState = formValue;
    for (let i=0; i<indices.length; ++i) {
      updatedState[indices[i]] = !statusAll;
    }
    setFormValue(updatedState);
    setStatusAll(!statusAll);
  }

  const booleanValue = (key: string) => {
    const index = booleanOptions.indexOf(key);
    if (index<0)
      return false;

    return formValue[index];
  }

  const handleSearchButtonClick = () => {
    const query : AdvancedSearchQuery = {
      searchString: '',
      types: [],
      jurisdictions:[],
      pedigrees: [],
      sources: [],
      statuses:[]
    };

    query.searchString = searchString;

    if (booleanValue("TERM")) query.types.push("TERM");
    if (booleanValue("REFERENCE")) query.types.push("REFERENCE");

    if (booleanValue("00")) query.jurisdictions.push("00");
    if (booleanValue("EU")) query.jurisdictions.push("EU");
    if (booleanValue("US")) query.jurisdictions.push("US");

    if (booleanValue("GUIDANCE")) query.pedigrees.push("GUIDANCE");
    if (booleanValue("LEGISLATION")) query.pedigrees.push("LEGISLATION");
    if (booleanValue("WORKING_GROUP_PUBLICATION")) query.pedigrees.push("WORKING_GROUP_PUBLICATION");

    if (booleanValue("EURLEX")) query.sources.push("EURLEX");
    if (booleanValue("FDA")) query.sources.push("FDA");
    if (booleanValue("IMDRF")) query.sources.push("IMDRF");
    if (booleanValue("ECFR")) query.sources.push("ECFR");
    if (booleanValue("MDCG")) query.sources.push("MDCG");
    if (booleanValue("US_GOV_INFO")) query.sources.push("US_GOV_INFO");

    if (booleanValue("ACTIVE")) query.statuses.push("ACTIVE");
    if (booleanValue("FUTURE")) query.statuses.push("FUTURE");
    if (booleanValue("DEPRECATED")) query.statuses.push("DEPRECATED");
    if (booleanValue("WORK_IN_PROGRESS")) query.statuses.push("WORK_IN_PROGRESS");
    if (booleanValue("NON_ACTIVE")) query.statuses.push("NON_ACTIVE");

    setModalVisibleCallback(false);
    navigate('/advanced-search');

    console.log("dispatching search query: ", query);
    dispatch(advancedSearch(query));
  }

  return (
    <div id="advanced-search">
      <h3>Advanced Lexogon Search</h3>
      <div id = "search-box" >
        <svg id="search-icon" viewBox="0 0 1.16782 1.20302">
          <path fill="currentColor"  d="M0.447838 0c0.122734,0 0.234224,0.0475133 0.315284,0.124261 0.081864,0.0775727 0.132534,0.184957 0.132534,0.303689 0,0.1033 -0.0383531,0.198017 -0.102247,0.271979l0.355989 0.386131c0.0260776,0.0283264 0.0242415,0.0724562 -0.00408495,0.0985339 -0.0283264,0.0260776 -0.0724562,0.0242415 -0.0985339,-0.00408495l-0.360177 -0.390649c-0.0691965,0.0417985 -0.151102,0.06604 -0.238763,0.06604 -0.122734,0 -0.234266,-0.0475133 -0.315284,-0.124261 -0.0818846,-0.0775727 -0.132554,-0.184957 -0.132554,-0.303689 0,-0.118732 0.0506699,-0.226116 0.132554,-0.303689 0.0810181,-0.0767475 0.19255,-0.124261 0.315284,-0.124261zm0.219226 0.225229c-0.0557451,-0.0527949 -0.133235,-0.0854744 -0.219226,-0.0854744 -0.0859902,0 -0.163501,0.0327002 -0.219226,0.0854744 -0.0548992,0.0520109 -0.0888579,0.123704 -0.0888579,0.202721 0,0.0790169 0.0339587,0.15071 0.0888579,0.202721 0.0557245,0.0527742 0.133235,0.0854744 0.219226,0.0854744 0.0859902,0 0.16348,-0.0326796 0.219226,-0.0854744 0.0548786,-0.0519902 0.0888373,-0.123683 0.0888373,-0.202721 0,-0.0790169 -0.0339587,-0.15073 -0.0888373,-0.202721z"/>
        </svg>
        <input type="Text" id="search-field" placeholder="Advanced search..." onChange={handleSearchStringChange} />
      </div>
      <div className="section-title">Types:</div>
      <div className="options-section">
        <label className="checkbox-container">Term
          <input type="checkbox" id="TERM" name="TERM" checked={formValue[booleanOptions.indexOf("TERM")]} onChange={handleChange}/>
          <span className="checkmark"></span>
        </label>
        <label className="checkbox-container">Reference
          <input type="checkbox" id="REFERENCE" name="REFERENCE" checked={formValue[booleanOptions.indexOf("REFERENCE")]} onChange={handleChange}/>
          <span className="checkmark"></span>
        </label>
      </div>
      <div className="section-title">Jurisdictions:</div>
      <div className="options-section">
        <label className="checkbox-container">Working Group or Standards Body <JurisFlag jurisId={'00'} />
          <input type="checkbox" id="00" name="00" checked={formValue[booleanOptions.indexOf("00")]} onChange={handleChange} />
          <span className="checkmark"></span>
        </label>
        <label className="checkbox-container">EU <JurisFlag jurisId={"EU"} />
          <input type="checkbox" id="EU" name="EU" checked={formValue[booleanOptions.indexOf("EU")]} onChange={handleChange} />
          <span className="checkmark"></span>
        </label>
        <label className="checkbox-container">USA <JurisFlag jurisId={'US'} />
          <input type="checkbox" id="US" name="US" checked={formValue[booleanOptions.indexOf("US")]} onChange={handleChange} />
          <span className="checkmark"></span>
        </label>
        <div className='control-all' onClick={jurisSelectAll}><div>{jurisAll ? "Unselect": "  Select"}  All</div></div>
      </div>
      <div className="section-title">Pedigree:</div>
      <div className="options-section">
         <label className="checkbox-container">Guidance
          <input type="checkbox" id="GUIDANCE" name="GUIDANCE" checked={formValue[booleanOptions.indexOf("GUIDANCE")]} onChange={handleChange} />
          <span className="checkmark"></span>
        </label>
          <label className="checkbox-container">Legislation
          <input type="checkbox" id="LEGISLATION" name="LEGISLATION" checked={formValue[booleanOptions.indexOf("LEGISLATION")]} onChange={handleChange} />
          <span className="checkmark"></span>
        </label>
        <label className="checkbox-container">Working Group Publication
          <input type="checkbox" id="WORKING_GROUP_PUBLICATION" name="WORKING_GROUP_PUBLICATION" checked={formValue[booleanOptions.indexOf("WORKING_GROUP_PUBLICATION")]} onChange={handleChange} />
          <span className="checkmark"></span>
        </label>
        <div className='control-all' onClick={pedigreeSelectAll}><div>{pedigreeAll ? "Unselect": "  Select"} All</div></div>
      </div>
      <div className="section-title">Source:</div>
      <div className="options-section">
        <label className="checkbox-container">EURLEX
          <input type="checkbox" id="EURLEX" name="EURLEX" checked={formValue[booleanOptions.indexOf("EURLEX")]} onChange={handleChange} />
          <span className="checkmark"></span>
        </label>
         <label className="checkbox-container">FDA
          <input type="checkbox" id="FDA" name="FDA" checked={formValue[booleanOptions.indexOf("FDA")]} onChange={handleChange} />
          <span className="checkmark"></span>
        </label>
         <label className="checkbox-container">IMDRF
          <input type="checkbox" id="IMDRF" name="IMDRF" checked={formValue[booleanOptions.indexOf("IMDRF")]} onChange={handleChange} />
          <span className="checkmark"></span>
        </label>
         <label className="checkbox-container">ECFR
          <input type="checkbox" id="ECFR" name="ECFR" checked={formValue[booleanOptions.indexOf("ECFR")]} onChange={handleChange} />
          <span className="checkmark"></span>
        </label>
         <label className="checkbox-container">MDCG
          <input type="checkbox" id="MDCG" name="MDCG" checked={formValue[booleanOptions.indexOf("MDCG")]} onChange={handleChange} />
          <span className="checkmark"></span>
        </label>
        <label className="checkbox-container">US Gov Info
          <input type="checkbox" id="US_GOV_INFO" name="US_GOV_INFO" checked={formValue[booleanOptions.indexOf("US_GOV_INFO")]} onChange={handleChange} />
          <span className="checkmark"></span>
        </label>
        <div className='control-all' onClick={sourceSelectAll}><div>{sourceAll ? "Unselect": "  Select"} All</div></div>
      </div>
      <div className="section-title">Status:</div>
      <div className="options-section">
        <label className="checkbox-container">Active
          <input type="checkbox" id="ACTIVE" name="ACTIVE" checked={formValue[booleanOptions.indexOf("ACTIVE")]} onChange={handleChange} />
          <span className="checkmark"></span>
        </label>
        <label className="checkbox-container">Future
          <input type="checkbox" id="FUTURE" name="FUTURE" checked={formValue[booleanOptions.indexOf("FUTURE")]} onChange={handleChange} />
          <span className="checkmark"></span>
        </label>
        <label className="checkbox-container">Deprecated
          <input type="checkbox" id="DEPRECATED" name="DEPRECATED" checked={formValue[booleanOptions.indexOf("DEPRECATED")]} onChange={handleChange} />
          <span className="checkmark"></span>
        </label>
        <label className="checkbox-container">Work-in-progress
          <input type="checkbox" id="WORK_IN_PROGRESS" name="WORK_IN_PROGRESS" checked={formValue[booleanOptions.indexOf("WORK_IN_PROGRESS")]} onChange={handleChange} />
          <span className="checkmark"></span>
        </label>
        <label className="checkbox-container">Non-active 
          <input type="checkbox" id="NON_ACTIVE" name="NON_ACTIVE" checked={formValue[booleanOptions.indexOf("NON_ACTIVE")]} onChange={handleChange} />
          <span className="checkmark"></span>
        </label>
        <div className='control-all' onClick={statusSelectAll}><div>{statusAll ? "Unselect": "  Select"} All</div></div>
      </div>
      <button type="button" onClick={handleSearchButtonClick}>Advanced Lexogon Search</button>
    </div>
  );
}

export default AdvancedSearch;
