import { Component } from 'react';
import './circular-button.scss';
import { CircularButtonSelectionState } from "./selection-state";

export type CircularButtonType = "NORMAL" | "TOGGLE";

interface CircularButtonProps {
    type?: CircularButtonType;
    id: string;
    className?: string;
    icon: JSX.Element;
    importance: "primary" | "secondary" | "tertiary";
    selectionState: CircularButtonSelectionState;
    tooltip: string;
    clickHandler?: (buttonId: string) => void;
    toggleRequestedHandler?: () => void;
}

class CircularButton extends Component<CircularButtonProps, {}> {

    static defaultProps = {
        className: "",
        selectionState: "UNSELECTED"
    };

    handleClick = () => {
        if (this.props.type === "TOGGLE") 
            this.props.toggleRequestedHandler?.();

        this.props.clickHandler?.(this.props.id);
    }

    render() {
        const { id, className, tooltip, icon, importance, type, selectionState } = this.props;

        const classList = [className, `${importance}-circular-button`, 'circular-button'];

        if (type === "TOGGLE") {
            classList.push("toggle-button");
            classList.push(selectionState.toLowerCase());
        }

        return (
            <button id={id} className={classList.join(' ')} title={tooltip} onClick={this.handleClick}>
                {icon}
            </button>
        );
    }
}

export default CircularButton;