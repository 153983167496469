import '../styling/loader.scss';
import { Oval } from 'react-loader-spinner';

const LexogonLoader = () => {

  const style = {};

  return (
    <div className='loader-container'>
      <div className='loader-row'>
        <div className='loader'>
          <Oval
            visible={true}
            color="#0033ff"
            secondaryColor="D9DCF3"
            height="80"
            width="80"
            ariaLabel="loading"
            wrapperStyle={style}
            wrapperClass=""
          />
        </div>
      </div>
    </div>
  )
}

export default LexogonLoader;
