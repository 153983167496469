import "../styling/section-title.scss";
import "../styling/checkbox.scss";
import { useEffect, useState } from 'react';

const booleanOptions =
  [
  "GENERAL",
  ]

const UserSubscriptions = () => {
  const [htmlFormChanged, setFormChanged] = useState<boolean>(false);
  const [formValue, setFormValue] = useState<boolean[]>(new Array(booleanOptions.length).fill(true));
  const [lastSavedFormValue, setLastSavedFormValue] = useState(formValue);

  const handleSaveButtonClick = () => {
//    attemptProfileUpdate()
  }

  const handleChange = (e: any) => {
      const position = booleanOptions.indexOf(e.target.name);
      const updatedState = formValue.map((item, index) => 
        index === position ? !item : item
      );
      setFormValue(updatedState);
  }

  useEffect(() => {
    if (JSON.stringify(formValue) === JSON.stringify(lastSavedFormValue)) {
      setFormChanged(false);
    }
    else {
      setFormChanged(true);
    }

  }, [formValue, lastSavedFormValue]);


  return(
    <div>
      <div className="section-title">Subscriptions</div>
      <label className="checkbox-container">General Lexogon News and Updates
        <input type="checkbox" id="GENERAL" name="GENERAL" checked={formValue[booleanOptions.indexOf("GENERAL")]} onChange={handleChange} />
        <span className="checkmark"></span>
      </label>
        <p><button type="button" disabled={!htmlFormChanged} onClick={handleSaveButtonClick}>Save</button></p>

    </div>
  );
}

export default UserSubscriptions;
