import "./juris-flag.scss";
import React, { FC } from 'react';
//import GlobeFlag from '../../assets/images/globe-flag.svg'

import { ReactComponent as GlobeFlag } from "../../assets/images/globe-flag.svg";


interface JurisFlagModel {
    jurisId: string;
  }

interface JurisFlagProps extends JurisFlagModel {
  
}

const JurisFlag: FC<JurisFlagProps> = ({ jurisId }) => {
  let svg: JSX.Element;

  switch (jurisId.toLowerCase()) {
    case 'eu':
        svg = (
            <svg viewBox="0 0 3.85 2.58" className="juris-flag">
                <rect className="eu-flag-background dark-colored-shape" id="BlueBackground" fill="#003399" width="3.85" height="2.58"/>
                <polygon className="eu-flag-star light-colored-shape" fill="yellow" points="1.31437,0.432571 1.36959,0.603469 1.54921,0.603091 1.40362,0.708409 1.45951,0.879157 1.31437,0.773315 1.16923,0.879157 1.22512,0.708409 1.07953,0.603091 1.25915,0.603469 "/>
                <polygon className="eu-flag-star light-colored-shape" fill="yellow" points="2.5356,0.432571 2.59082,0.603469 2.77044,0.603091 2.62485,0.708409 2.68074,0.879157 2.5356,0.773315 2.39046,0.879157 2.44635,0.708409 2.30076,0.603091 2.48038,0.603469 "/>
                <polygon className="eu-flag-star light-colored-shape" fill="yellow" points="2.5356,1.65372 2.59082,1.82462 2.77044,1.82424 2.62485,1.92956 2.68074,2.10031 2.5356,1.99447 2.39046,2.10031 2.44635,1.92956 2.30076,1.82424 2.48038,1.82462 "/>
                <polygon className="eu-flag-star light-colored-shape" fill="yellow" points="1.925,1.90665 1.98021,2.07755 2.15983,2.07717 2.01424,2.18249 2.07013,2.35324 1.925,2.2474 1.77985,2.35324 1.83574,2.18249 1.69015,2.07717 1.86978,2.07755 "/>
                <polygon className="eu-flag-star light-colored-shape" fill="yellow" points="2.78855,1.04315 2.84376,1.21404 3.02339,1.21367 2.8778,1.31898 2.93369,1.48973 2.78855,1.38389 2.64341,1.48973 2.6993,1.31898 2.5537,1.21367 2.73333,1.21404 "/>
                <polygon className="eu-flag-star light-colored-shape" fill="yellow" points="1.92499,0.179642 1.98021,0.350539 2.15983,0.350161 2.01424,0.45548 2.07013,0.626228 1.92499,0.520386 1.77985,0.626228 1.83574,0.45548 1.69015,0.350161 1.86977,0.350539 "/>
                <polygon className="eu-flag-star light-colored-shape" fill="yellow" points="1.06143,1.04315 1.11665,1.21404 1.29627,1.21367 1.15068,1.31898 1.20657,1.48973 1.06143,1.38389 0.916287,1.48973 0.972177,1.31898 0.826587,1.21367 1.00621,1.21404 "/>
                <polygon className="eu-flag-star light-colored-shape" fill="yellow" points="1.31437,1.65372 1.36959,1.82462 1.54921,1.82424 1.40362,1.92956 1.45951,2.10031 1.31437,1.99447 1.16923,2.10031 1.22512,1.92956 1.07953,1.82424 1.25915,1.82462 "/>
            </svg>
        );
      break;
    case 'us':
        svg = (
            <svg viewBox="0 0 6.05 4.05" className="juris-flag">
                <polygon className="us-flag-red-stripe mid-colored-shape" fill="red" points="-0,3.47145 6.05,3.47145 6.05,4.05 -0,4.05 "/>
                <polygon className="us-flag-red-stripe mid-colored-shape" fill="red" points="-0,2.31434 6.05,2.31434 6.05,2.89289 -0,2.89289 "/>
                <polygon className="us-flag-red-stripe mid-colored-shape" fill="red" points="-0,1.15724 6.05,1.15724 6.05,1.73579 -0,1.73579 "/>
                <polygon className="us-flag-red-stripe mid-colored-shape" fill="red" points="-0,0.000133858 6.05,0.000133858 6.05,0.578685 -0,0.578685 "/>
                <polygon className="us-flag-white-stripe light-colored-shape" fill="whitesmoke" points="-0,2.89289 6.05,2.89289 6.05,3.47145 -0,3.47145 "/>
                <polygon className="us-flag-white-stripe light-colored-shape" fill="whitesmoke" points="-0,1.73579 6.05,1.73579 6.05,2.31434 -0,2.31434 "/>
                <polygon className="us-flag-white-stripe light-colored-shape" fill="whitesmoke" points="-0,0.578685 6.05,0.578685 6.05,1.15724 -0,1.15724 "/>
                <rect id="us-flag-blue-area" className="dark-colored-shape" fill="#2E52B2" width="3.27875" height="2.31434"/>
                <polygon className="us-flag-star light-colored-shape" fill="white" points="1.9488,1.54263 2.00199,1.70537 2.17329,1.7055 2.03484,1.80646 2.08751,1.9692 1.9488,1.86877 1.81008,1.9692 1.86274,1.80633 1.7243,1.7055 1.89559,1.70524 "/>
                <polygon className="us-flag-star light-colored-shape" fill="white" points="0.554728,0.898051 0.607925,1.06079 0.779224,1.06092 0.640776,1.16189 0.693445,1.32463 0.554728,1.22419 0.416012,1.32463 0.468681,1.16176 0.330232,1.06092 0.501531,1.06066 "/>
                <polygon className="us-flag-star light-colored-shape" fill="white" points="1.25176,1.54263 1.30496,1.70537 1.47626,1.7055 1.33781,1.80646 1.39048,1.9692 1.25176,1.86877 1.11305,1.9692 1.16571,1.80633 1.02726,1.7055 1.19856,1.70524 "/>
                <polygon className="us-flag-star light-colored-shape" fill="white" points="0.554728,1.54263 0.607925,1.70537 0.779224,1.7055 0.640776,1.80646 0.693445,1.9692 0.554728,1.86877 0.416012,1.9692 0.468681,1.80633 0.330232,1.7055 0.501531,1.70524 "/>
                <polygon className="us-flag-star light-colored-shape" fill="white" points="2.64596,1.54263 2.69916,1.70537 2.87046,1.7055 2.73201,1.80646 2.78467,1.9692 2.64596,1.86877 2.50724,1.9692 2.55991,1.80633 2.42146,1.7055 2.59276,1.70524 "/>
                <polygon className="us-flag-star light-colored-shape" fill="white" points="2.64596,0.898051 2.69916,1.06079 2.87046,1.06092 2.73201,1.16189 2.78467,1.32463 2.64596,1.22419 2.50724,1.32463 2.55991,1.16176 2.42146,1.06092 2.59276,1.06066 "/>
                <polygon className="us-flag-star light-colored-shape" fill="white" points="1.25176,0.898051 1.30496,1.06079 1.47626,1.06092 1.33781,1.16189 1.39048,1.32463 1.25176,1.22419 1.11305,1.32463 1.16571,1.16176 1.02726,1.06092 1.19856,1.06066 "/>
                <polygon className="us-flag-star light-colored-shape" fill="white" points="1.25176,0.253343 1.30496,0.416079 1.47626,0.416213 1.33781,0.517177 1.39048,0.679917 1.25176,0.579484 1.11305,0.679917 1.16571,0.517043 1.02726,0.416213 1.19856,0.415945 "/>
                <polygon className="us-flag-star light-colored-shape" fill="white" points="1.9488,0.253343 2.00199,0.416079 2.17329,0.416213 2.03484,0.517177 2.08751,0.679917 1.9488,0.579484 1.81008,0.679917 1.86274,0.517043 1.7243,0.416213 1.89559,0.415945 "/>
                <polygon className="us-flag-star light-colored-shape" fill="white" points="2.64596,0.253343 2.69916,0.416079 2.87046,0.416213 2.73201,0.517177 2.78467,0.679917 2.64596,0.579484 2.50724,0.679917 2.55991,0.517043 2.42146,0.416213 2.59276,0.415945 "/>
                <polygon className="us-flag-star light-colored-shape" fill="white" points="1.9488,0.898051 2.00199,1.06079 2.17329,1.06092 2.03484,1.16189 2.08751,1.32463 1.9488,1.22419 1.81008,1.32463 1.86274,1.16176 1.7243,1.06092 1.89559,1.06066 "/>
                <polygon className="us-flag-star light-colored-shape" fill="white" points="0.554728,0.253343 0.607925,0.416079 0.779224,0.416213 0.640776,0.517177 0.693445,0.679917 0.554728,0.579484 0.416012,0.679917 0.468681,0.517043 0.330232,0.416213 0.501531,0.415945 "/>
            </svg>
        );
      break;
    case 'at':
      svg = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 896 600" className="juris-flag">
          <rect fill="#c8102e" width="896" height="600"/>
          <rect fill="#fff" y="200" width="896" height="200"/>
        </svg>)
      break;
    case 'au':
      svg = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10080 5040" className="juris-flag">
        <defs>
        <clipPath id="c1"><path d="M0,0H6V3H0z"/></clipPath>
        <clipPath id="c2"><path d="M0,0V1.5H6V3H6zM6,0H3V3H0V3z"/></clipPath>
        <path id="Star7" d="M0,-360 69.421398,-144.155019 281.459334,-224.456329 155.988466,-35.603349 350.974048,80.107536 125.093037,99.758368 156.198146,324.348792 0,160 -156.198146,324.348792 -125.093037,99.758368 -350.974048,80.107536 -155.988466,-35.603349 -281.459334,-224.456329 -69.421398,-144.155019z"/>
        <path id="Star5" d="M0,-210 54.859957,-75.508253 199.721868,-64.893569 88.765275,28.841586 123.434903,169.893569 0,93.333333 -123.434903,169.893569 -88.765275,28.841586 -199.721868,-64.893569 -54.859957,-75.508253z"/>
        </defs>
        <g transform="scale(840)">
        <rect width="12" height="6" fill="#00008b"/>
        <path d="M0,0 6,3M6,0 0,3" stroke="#fff" strokeWidth="0.6" clipPath="url(#c1)"/>
        <path d="M0,0 6,3M6,0 0,3" stroke="#f00" strokeWidth="0.4" clipPath="url(#c2)"/>
        <path d="M3,0V3M0,1.5H6" stroke="#fff"/>
        <path d="M3,0V3M0,1.5H6" stroke="#f00" strokeWidth="0.6"/>
        </g>
        <g fill="#fff">
        <use id="Comwlth"  transform="translate(2520, 3780) scale(2.1)"/>
        <use id="αCrucis"  x="7560" y="4200"/>
        <use id="βCrucis"  x="6300" y="2205"/>
        <use id="γCrucis"  x="7560" y="840"/>
        <use id="δCrucis"  x="8680" y="1869"/>
        <use id="εCrucis"  x="8064" y="2730"/>
        </g>
        </svg>
      )
      break;
    case 'be':
      svg = (
        <svg viewBox="0 0 900 602" className="juris-flag"><path fill="#ef3340" d="M0 0h900v602H0z"/><path fill="#fdda25" d="M0 0h600v602H0z"/><path d="M0 0h300v602H0z"/></svg>
      );
      break;
    case 'bg':
      svg = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5 3" className="juris-flag">
          <rect width="5" height="3" fill="#fff"/>
          <rect width="5" height="2" y="1" fill="#00966E"/>
          <rect width="5" height="1" y="2" fill="#D62612"/>
        </svg>
      );
      break;
    case '00':
    svg = (
      <svg viewBox="0 0 48 32" className="juris-flag" fill="blue" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <rect fill="blue" width="48" height="34"/>
          <g transform="translate(12,4)">
            <circle cx="12" cy="12" r="10"></circle><line x1="2" y1="12" x2="22" y2="12"></line><path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path>
          </g>
      </svg> 
    );
      break;
    default:
        svg = (
            <svg viewBox="0 0 3.85 2.58" className="juris-flag">
              <rect id="unknown-flag-blue-background" className="dark-colored-shape" fill="#9900CC" width="3.85" height="2.58"/>
                  <path id="unknown-flag-question-mark" className="light-colored-shape" fill="white" d="M2.11155 1.62357l-0.444122 0 0 -0.0442913c0,-0.0754094 0.00838189,-0.136854 0.0255394,-0.183941 0.0171535,-0.0474803 0.0426929,-0.0901732 0.0766142,-0.12928 0.0339173,-0.0391024 0.11013,-0.107728 0.228646,-0.205886 0.0630472,-0.0514685 0.0945709,-0.0985551 0.0945709,-0.141248 0,-0.0430945 -0.0127717,-0.0762126 -0.0379094,-0.10015 -0.0255394,-0.0235433 -0.0638425,-0.0355118 -0.115319,-0.0355118 -0.0554685,0 -0.100957,0.0183543 -0.137268,0.0550591 -0.036315,0.036311 -0.0594567,0.100555 -0.0694331,0.191524l-0.453701 -0.0562559c0.015563,-0.166787 0.0762165,-0.300854 0.181563,-0.402602 0.10574,-0.101744 0.267748,-0.152417 0.48602,-0.152417 0.169988,0 0.306854,0.0355118 0.411406,0.106134 0.141657,0.0961614 0.212681,0.223846 0.212681,0.383445 0,0.0662362 -0.0183543,0.130476 -0.055063,0.191925 -0.036315,0.0614449 -0.111331,0.136858 -0.22426,0.225441 -0.0786102,0.0626417 -0.128488,0.11252 -0.149236,0.150421 -0.0203504,0.0375079 -0.0307283,0.0869882 -0.0307283,0.147634zm-0.459681 0.11811l0.476039 0 0 0.419752 -0.476039 0 0 -0.419752z"/>
            </svg>
        );
        break;
  }

  return svg;
};

export default JurisFlag;
