import { FC } from 'react';
import DefinitionSource, {DefinitionSourceModel} from "./DefinitionSource";
import DefinitionSourceR from "./DefinitionSourceR";
import type { Definition } from "../interfaces/types";

export interface DefinitionSourceListProps{
  //  sources: DefinitionSourceModel[];
  definitions: Definition[];
}

const DefinitionSourcesList: FC<DefinitionSourceListProps> = ({ definitions }) => {
  const lastIndex = definitions.length - 1;

  return (
    <span className="definition-source-list" >
      <span>, from </span>
        { definitions.map((def, index) => ( 
        <span key={index}>
          <DefinitionSourceR definition={def} />
          {index === lastIndex - 1 ? ', and ' : index < lastIndex ? ', ' : ''}
        </span>
        ))}
    </span>
  );
}
  
export default DefinitionSourcesList;

//const DefinitionSourcesList: FC<DefinitionSourceListProps> = ({ sources, reference }) => {
//    const lastIndex = sources.length - 1;
//
//    return (
//    
//    <span className="definition-source-list" >
//        <span>, from </span>
//        {sources.map((source, index) => (
//                <span key={index}>
//                    <DefinitionSourceR reference={reference} />
//                    {index === lastIndex - 1 ? ', and ' : index < lastIndex ? ', ' : ''}
//                </span>
//            ))}
//    </span>
//  );
//}
//  
//export default DefinitionSourcesList;
