import '../styling/advanced-search-results-page.scss';
import { useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { useAppSelector } from '../store/hooks';
import type { RootState } from '../store/store';
import SearchResultsPanel from './SearchResultsPanel';
import NeoSearchResultsPanel from './NeoSearchResultsPanel';
import { SearchResultsPanelModel} from '../interfaces/types';
import { useGetAdvancedSearchResultMutation } from '../slices/apiSlice';
import { clearAdvancedSearch } from '../slices/advancedSearchSlice';
import { useDispatch } from 'react-redux';
import AdvancedSearchOptionsIndicator from './AdvancedSearchOptionsIndicator';
import LexogonLoader from './LexogonLoader';

const AdvancedSearchResultsPage = () => {

  const [searchResultsSelectedIndex, setSearchResultsSelectedIndex] = useState<number>(-1);
  const [resultsPanelModel, setResultsPanelModel] = useState<SearchResultsPanelModel>({searchResults: undefined});

  const advancedSearchQuery = useAppSelector((state: RootState) => state.advancedSearch, shallowEqual);
  const dispatch = useDispatch();

  // get search results
  const [ sendSearchQuery, {
    data: searchResults,
    isLoading,
    isSuccess,
    //    isError,
    //    error
  }] = useGetAdvancedSearchResultMutation();

  useEffect(() => {
    if (advancedSearchQuery.searchString != "") {
      sendSearchQuery(advancedSearchQuery);
    }
  }, [advancedSearchQuery]);

  useEffect(() => {
    dispatch(clearAdvancedSearch());
  }, [searchResults])

  const handleSearchResultsRowHover = (rowId : string) : void => {
    if (resultsPanelModel?.searchResults === undefined)
      return;

    const rowIndex = resultsPanelModel.searchResults.findIndex( searchResult => searchResult.id === rowId);
    setSearchResultsSelectedIndex(rowIndex || 0);
  }

  if (isLoading) return (<LexogonLoader />);

//  if (searchResults) {
//    resultsPanelModel.searchResults = searchResults.results;
//    //setResultsPanelModel({...resultsPanelModel, ['searchResults']: searchResults.results});
//  }
//  else {
//    console.log("did not get searchResults");
//  }

  return (
    <div>
      {searchResults?.query &&
      <>
      <div className='search-results-page-title'>
        Advanced search results for &quot;{searchResults.query}&quot; with options:
      </div>
      <div>
        {searchResults.options && <AdvancedSearchOptionsIndicator {...searchResults.options} />}
      </div>
      </>
      }
      {searchResults && <NeoSearchResultsPanel searchResults={searchResults.results} selectedIndex={searchResultsSelectedIndex} rowHoverHandler={handleSearchResultsRowHover} />}
    </div>
  );
}

export default AdvancedSearchResultsPage;
