import { memo, useEffect } from 'react';
import { useGetDocumentFromPublicationQuery } from '../slices/apiSlice';
import { useNavigate } from 'react-router-dom';
import { lexTypeToToken } from '../common/lexTypeToToken';
import { useParams } from 'react-router';

const PublicationToDocumentResolver = () => {

  const params = useParams();
  const instanceId = params.instanceId;
  const navigate = useNavigate();

  if (!instanceId) return <></>;
  const {
    data: documentObject,
    isLoading,
    isSuccess,
    isFetching,
    isError,
    //    error: queryError
  } = useGetDocumentFromPublicationQuery(instanceId);

  useEffect(() => {
    if (documentObject) {
      const token = lexTypeToToken(documentObject.lexType);
      navigate(`/${token}/${documentObject.instanceId}`, { replace: true});
    }
  }, [documentObject]) 
 
  return (
    <>
    </>
  )
}

export default memo(PublicationToDocumentResolver);
