import '../styling/toc-tree-node.scss';
import { FC, useEffect, useState } from 'react';
import TocElement from './TocElement';
import ExpansionButton from '../shared/expansion-button/expansion-button';
import { ExpansionState, SelectionState } from './enumerations';
import { ReactComponent as ExpansionIcon } from '../shared/assets/images/icons/expansion-icon.svg';
import { ReactComponent as ExpandIcon } from '../shared/assets/images/icons/down-chevron.svg';
import { ReactComponent as CloseIcon } from '../shared/assets/images/icons/up-chevron.svg';

export interface TocTreeNodeProps {
  node: any;
  style: any;
  dragHandle?: any;
  tree: any;
}

const TocTreeNode: FC<TocTreeNodeProps> = ({ node, style, dragHandle, tree }) => {

  let initialExpansion:ExpansionState;
  if (node.data.level > 1) {
    initialExpansion = "COLLAPSED";
  }
  else {
    initialExpansion = "EXPANDED";
  }

  const [expansionState, setExpansionState] = useState<ExpansionState>(initialExpansion); 

  const isCollapsible = (node.data.children.length > 0);

  const toggleExpansionState = () => {
    if (!isCollapsible) return;

    let newExpansionState: ExpansionState;
    if (expansionState === "COLLAPSED") {
      newExpansionState = "EXPANDED";
    }
    else {
      newExpansionState = "COLLAPSED";
    }
    setExpansionState(newExpansionState);
  }

  const expansionButtonClickHandler = () => {
    toggleExpansionState();
  }

  useEffect(() => {
    if (expansionState === "COLLAPSED") {
      node.close();
    }
    else {
      node.open();
    }
  }, [expansionState])

  return (
    <div className="toc-tree-node">
      <TocElement key={node.data.id} {...node.data} />
      <div className="toc-tree-node-button">
        {isCollapsible && <ExpansionButton expansionState={expansionState} clickHandler={expansionButtonClickHandler}/>}
      </div>
    </div>
  );
}

export default TocTreeNode;
