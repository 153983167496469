import "../styling/flat-checkbox.scss";
import { FunctionComponent } from 'react';
import { ReactComponent as FolderIcon } from "../shared/assets/images/icons/folder-icon.svg";
import { ReactComponent as BookmarkIcon } from "../shared/assets/images/icons/bookmark-selector-icon.svg";
import { ReactComponent as CheckboxIcon } from "../shared/assets/images/icons/flat-checkbox-icon.svg";
import { ReactComponent as RadioIcon } from "../shared/assets/images/icons/flat-radio-icon.svg";

export type Shape = "FOLDER" | "BOOKMARK" | "SQUARE" | "CIRCLE";
export type CheckedState = "UNCHECKED" | "CHECKED" | "SUBDUED" | "" | "HIGHLIGHTED";

export interface FlatCheckboxProps {
  extraClasses? : string[];
  shape : Shape; 
  checkedState : CheckedState;
  tooltip? : string;
  warning? :boolean;
}

const FlatCheckbox: FunctionComponent<FlatCheckboxProps> = ({extraClasses, shape, checkedState: state, tooltip, warning}) => {
  let classes : string[] = ["flat-checkbox"];
  classes = classes.concat(extraClasses || []);
  switch (state){
    case "UNCHECKED":
      classes = classes.concat("unchecked");
      break;
    case "CHECKED":
      classes = classes.concat("checked");
      break;
    case "SUBDUED":
      classes = classes.concat("subdued");
      break;
    case "HIGHLIGHTED":
      classes = classes.concat("highlighted");
      break;  
  }

  switch (shape){
    case "FOLDER":
      classes = classes.concat("folder-checkbox");
      break;
    case "BOOKMARK":
      classes = classes.concat("bookmark-checkbox");
      break;
    case "SQUARE":
      classes = classes.concat("square-checkbox");
      break;
    case "CIRCLE":
      classes = classes.concat("circle-checkbox");
      break;  
  }

  if (warning) {
    classes = classes.concat("warning");
  }

  const className = classes.join(" ");

  switch (shape) {
    case "FOLDER":
      return <FolderIcon className={className} title={tooltip} />;
    case "BOOKMARK":
      return <BookmarkIcon className={className} title={tooltip} />;
    case "SQUARE":
      return <CheckboxIcon className={className} title={tooltip} />;
    case "CIRCLE":
      return <RadioIcon className={className} title={tooltip} />;
    default:
      return null;
  }
};

export default FlatCheckbox;
