import "../styling/library-explorer.scss";
import '../styling/centered-panel.scss';
import DashboardMenuItem from './DashboardMenuItem';

import { ReactComponent as SettingsIcon } from '../shared/assets/images/icons/Settings-icon.svg';
import { ReactComponent as ProfileIcon } from '../shared/assets/images/icons/profile-icon.svg';
import { ReactComponent as SubscriptionIcon } from '../shared/assets/images/icons/email-icon.svg';
import { ReactComponent as AccountSettingsIcon } from '../shared/assets/images/icons/account-settings.svg';

const UserDashboard = () => {

  return (
    <div className="centered-panel">
      <div className="title-wrapper">
        <div className="title">
          User Dashboard 
        </div>
      </div>
      <div className="content">
      <DashboardMenuItem
        icon ={<ProfileIcon />}
        title = {"Profile"}
        content = {"Manage your user profile"}
        navigateTo = {'profile'}
        />
      <DashboardMenuItem
        icon ={<SubscriptionIcon />}
        title = {"Subscriptions"}
        content = {"Add/remove topical subscriptions"}
        navigateTo = {'subscriptions'}
        />
      <DashboardMenuItem
        icon ={<SettingsIcon />}
        title = {"Lexogon Settings"}
        content = {"Adjust Lexogon settings, e.g. search defaults"}
        navigateTo = {'settings'}
      />
      <DashboardMenuItem
        icon ={<AccountSettingsIcon />}
        title = {"Account"}
        content = {"Manage account settings and billing"}
        navigateTo = {'account'}
      />
      </div>
    </div>
  );
}

export default UserDashboard;
