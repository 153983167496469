import Descriptor from "../shared/descriptor/descriptor";
import { FC } from 'react';
//import {BookmarkProps, BookmarkModel} from "./Bookmark";
import RelatedEntryPanel, {RelatedEntryPanelModel} from "./RelatedEntryPanel";
import BookmarkPanelSectionPanel from "./BookmarkPanelSection";
import type { LexLightObject } from '../interfaces/LexLightObject';

export interface PotentialBookmarkBodyTermModel {//extends BookmarkModel{
    lexObject: LexLightObject;
  //    sources : RelatedEntryPanelModel[];
    relatedTerms? : RelatedEntryPanelModel[];
}

const PotentialBookmarkBodyTerm: FC<PotentialBookmarkBodyTermModel> = ({ lexObject, relatedTerms }) => {
    return (
    <div className="term-bookmark bookmark">
        <Descriptor title="Term" jurisIDs={lexObject.jurisIDs}/>
        {/*        <BookmarkPanelSectionPanel title="SOURCES" >
            {sources.map((source, index) => (
                <RelatedEntryPanel key={index} {...source} parentSelectionState="UNSELECTED" />))}
              </BookmarkPanelSectionPanel>*/}
        {relatedTerms && ( 
            <BookmarkPanelSectionPanel title="RELATED ENTRIES" >
            {relatedTerms.map((relatedTerm, index) => (
                <RelatedEntryPanel key={index} {...relatedTerm} parentSelectionState="UNSELECTED" />))} 
            </BookmarkPanelSectionPanel> 
        )}
    </div>
  );
}
  
export default PotentialBookmarkBodyTerm;
