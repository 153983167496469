import '../styling/slide-out-panel.scss';
import { FC, useEffect, useRef, useState } from 'react';
import type { LexLightObject } from '../interfaces/LexLightObject';
import { ReactComponent as OpenIcon } from '../shared/assets/images/icons/previous-icon.svg';
import { ReactComponent as CloseIcon } from '../shared/assets/images/icons/next-icon.svg';
import ReferenceRelationshipsPanel from './ReferenceRelationshipsPanel';
import TermRelationshipsPanel from './TermRelationshipsPanel';

const RelationshipsSidePanel: FC<LexLightObject> = (lexObject: LexLightObject) => {

  const [showRelationships, setShowRelationships] = useState<boolean>(false);

  const panelRef = useRef<any>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (showRelationships && !panelRef.current?.contains(event.target)) {
        setShowRelationships(false);
        panelRef.current?.classList.toggle('is-open');
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showRelationships, panelRef]);


  const handleClick = () => {
    if (showRelationships) {
      setShowRelationships(false);
    }
    else {
      setShowRelationships(true);
    }
    panelRef.current?.classList.toggle('is-open');
  }

  let content;
  if (lexObject.lexType==="term") {
    content = <TermRelationshipsPanel {...lexObject} />;
  }
  else if (lexObject.lexType==="document") {
    content = <ReferenceRelationshipsPanel {...lexObject} />;
  }
  else {
    return <></>
  }

  return (
    <div ref={panelRef} id="slide-out-panel">
      { content }
      <div className="close-button" onClick={handleClick}>
        {showRelationships ? <CloseIcon/>: <OpenIcon/>} 
      </div>
    </div>
  );
}

export default RelationshipsSidePanel;
