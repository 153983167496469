import "../styling/block-header.scss";
import "../styling/definition-block-header.scss";
import { FC } from 'react';
import { BlockHeaderModel } from "./BlockHeader";
import InformationButton from "../shared/information-button/information-button";
import DefinitionSourcesList from "./DefinitionSourceList";
import { DefinitionSourceModel } from "./DefinitionSource";
import type { Definition } from "../interfaces/types";
import type { LexLightObject } from "../interfaces/LexLightObject";

export interface NeoDefinitionBlockHeaderModel {
  term: LexLightObject;
  definitions: Definition[];
  definitionSources? : DefinitionSourceModel[]; // TODO delete
  conflictingSources? : DefinitionSourceModel[]; // TODO delete
  overridingSources? : DefinitionSourceModel[]; // TODO delete
}

export interface NeoDefinitionBlockHeaderProps extends NeoDefinitionBlockHeaderModel{
}

const NeoDefinitionBlockHeader: FC<NeoDefinitionBlockHeaderProps> = ({ term, definitions, definitionSources, conflictingSources, overridingSources }) => {

  //  if (definitionSources === undefined)
  //    throw new Error("The definitionSources property is mandatory for a DefinitionsBlockHeader");
    
  function calculateDifferentJurisdiction(sources : DefinitionSourceModel[]) {
    let differentJurisdictions : string[] = [];
    sources.forEach(element => {
      if (differentJurisdictions.indexOf(element.jurisID) === -1)
        differentJurisdictions.push(element.jurisID);
    });
    return differentJurisdictions;
  }

  function generateExplanation(reason : "OVERRIDE" | "CONFLICT", sources : DefinitionSourceModel[]){
    const openingStatement = (reason === "OVERRIDE")? "This definition is not binding" : "This definition might not be binding";
    const otherDocumentCaption = (sources.length === 1)? "is another document" : "are other documents";
    const differentJurisdictions = calculateDifferentJurisdiction(sources);
    const jurisdictionCaption = (differentJurisdictions.length === 1)? "jurisdiction" : "jurisdictions";
    const documentLevel = (reason === "OVERRIDE")? "a higher" : "the same";
    const theDocumentCaption = (sources.length === 1)? "The document is" : "These documents are:";
    
    //    let sourcesLegend : string = sources[0].title;
    //    for (let i = 1; i < sources.length; i++) {
    //        sourcesLegend += `, ${sources[i].title}`;
    //    }

    //let sourcesLegend: string = definition.reference.title;

    let explanation = ""; // TODO figure out what should go here 
    //        `${openingStatement}. There ${otherDocumentCaption} `+
    //        `for the same ${jurisdictionCaption} (${differentJurisdictions.join(", ")}) `+
    //        `with ${documentLevel} legal hierarchy (${sources[0].documentLevel}) that contains a different definition for the term "${title}". ` +
    //        `${theDocumentCaption} ${sourcesLegend}`;

    return explanation;
  }

  let fullDescriptorTitle = "";
  let descriptorTitleClasses : string[] = ["descriptor-title"];
  let descriptorExplanation;
  if (overridingSources) 
  {
    fullDescriptorTitle = "Non-binding definition";
    descriptorTitleClasses = descriptorTitleClasses.concat("overriden");
    descriptorExplanation = generateExplanation("OVERRIDE", overridingSources);
  }
  else if (conflictingSources) 
  {
    fullDescriptorTitle = "Conflicting definition";
    descriptorTitleClasses = descriptorTitleClasses.concat("conflicting");
    descriptorExplanation = generateExplanation("CONFLICT", conflictingSources);
  }
  else
    fullDescriptorTitle = "Definition";

  return (
    <div id={definitions[0].id.toString()} className="block-header definition-block-header" >
      <div className="definition-descriptor">
        <span className={descriptorTitleClasses.join(" ")}>{fullDescriptorTitle}</span>
        { descriptorExplanation && <InformationButton legend={descriptorExplanation}/>}
        <DefinitionSourcesList definitions={definitions} />
      </div>
    </div>
  );
}
  
export default NeoDefinitionBlockHeader;
