import '../styling/app-header.scss';
import { Availability } from '../shared/enums/availability';
import SearchBox, { SearchBoxAction } from './SearchBox';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { logout } from '../slices/userSessionSlice';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import type { RootState } from '../store/store';
import { apiSlice } from '../slices/apiSlice';
import { Link } from 'react-router-dom';
import { clearSearch } from '../slices/searchSlice';

import { ReactComponent as AppMenuIcon } from "../shared/assets/images/icons/app-menu-icon.svg";
import { ReactComponent as LexogonLogo } from "../shared/assets/images/lexogon-logo.svg";

export enum MenuIconCursorEvent{
    Entered,
    Leave,
    MouseDown,
    MouseUp
}

type AppHeaderProps = {
  menuAvailability : Availability;
  searchBoxAvailability : Availability;
  signupAvailability :Availability;
  signInAvailability : Availability;
  upgradeAvailability : Availability;
  signoutAvailability : Availability;
  searchQueryChangeHandler: (newSearchQuery: string) => void;
  searchboxActionHandler: (action: SearchBoxAction) => void;
  signUpButtonClickHandler: () => void;
  signInButtonClickHandler: () => void;
  signOutButtonClickHandler: () => void;
  viewPremiumBenefitsButtonClickHandler: () => void;
  appMenuIconClickHandler: () => void;
  menuIconMouseHandler: (event: MenuIconCursorEvent) => void;
//  logoClickHandler : () => void;

}

const AppHeader: FC<AppHeaderProps> = ({menuAvailability, searchBoxAvailability, signupAvailability, signInAvailability, upgradeAvailability, signoutAvailability, searchQueryChangeHandler, searchboxActionHandler, signUpButtonClickHandler, signInButtonClickHandler, signOutButtonClickHandler, appMenuIconClickHandler, menuIconMouseHandler, viewPremiumBenefitsButtonClickHandler}) => {
  //const PageHeader: FC<PageHeaderProps> = ({searchQueryChangeHandler, searchboxActionHandler}) => {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [loggedIn, setLoggedIn] = useState<boolean>(false);

  const username = useAppSelector((state: RootState) => state.userSession.user.username);

  useEffect(() => {
    if (username) {
      setLoggedIn(true);
    }
    else {
      setLoggedIn(false);
    }
  }, [username]);

  const handleLogoClicked = () : void => {
    clearSearch(); //TODO
    if (!loggedIn)
      window.location.href = '/';
    else
      window.location.href = '/dashboard';
  }

  const handleSignIn = () => {
    if (!loggedIn) {
      navigate('/sign-in');
    }
    else {
      dispatch(logout());
      setLoggedIn(false);
      dispatch(apiSlice.util.resetApiState());
      navigate('/');
    }
  }

  const handleMenuIconClicked = (event: React.MouseEvent<SVGSVGElement> ) => {
        event.stopPropagation(); // So the click does not get to the parent div of the app and hides the menu.
        appMenuIconClickHandler();
  }

  var lMenuClassName = menuAvailability === Availability.Unavailable? "unavailable" : "";

return (
    <div id="app-header">
        <div id="app-header-left">
          <AppMenuIcon id="hamburger-menu-button" className={lMenuClassName} onClick={ handleMenuIconClicked } onMouseEnter={ () => menuIconMouseHandler(MenuIconCursorEvent.Entered)} onMouseDown={() => menuIconMouseHandler(MenuIconCursorEvent.MouseDown)} onMouseUp={() => menuIconMouseHandler(MenuIconCursorEvent.MouseUp)} onMouseLeave={() => menuIconMouseHandler(MenuIconCursorEvent.Leave)}/>
          <LexogonLogo id="lexogon-logo" onClick={handleLogoClicked}/>
        </div>
        <SearchBox
            availability={searchBoxAvailability}
            queryChangeHandler={searchQueryChangeHandler}
            actionHandler={searchboxActionHandler}
            ></SearchBox>
        <div id="app-header-right">
            <button className="app-header-element app-header-button" onClick={handleSignIn}>{!loggedIn ? "Sign in" : "Log out"}</button>
        </div>
    </div>
);
}
  
export default AppHeader;
