import { FC, memo, useState, useEffect, useRef } from 'react';
import "../styling/search-results-panel.scss";
import SearchResultRow, { SearchResultRowModel } from './SearchResultRow';
import { DescriptorModel } from '../shared/descriptor/descriptor';
import type { RootState } from '../store/store';
import type { LexLightObject } from '../interfaces/LexLightObject';
import { useAppSelector } from '../store/hooks';
import { useGetSearchResultQuery } from '../slices/apiSlice';
import { remToPixels } from '../common/remToPixels';
import NeoSearchResultRow from './NeoSearchResultRow';

const rowRemHeight = 4; 
const rowPxHeight = remToPixels(rowRemHeight); 
const separatorPxWidth = 1; 
const animationStepDuration = 300; // ms
const animationStepDelay = Math.round( animationStepDuration * 0.6);

export interface NeoSearchResultsPanelModel {
  searchResults?: LexLightObject[];
}

export interface NeoSearchResultsPanelProps extends NeoSearchResultsPanelModel{
  selectedIndex: number;
  rowHoverHandler: (rowId : string) => void;
}

const NeoSearchResultsPanel: FC<NeoSearchResultsPanelProps> = ({ searchResults, selectedIndex, rowHoverHandler }) => {
    const [panelHeight, setPanelHeight] = useState("0px");
    const [contentOpacity, setContentOpacity] = useState(0);
    const [displayResults, setDisplayResults] = useState<LexLightObject[] | undefined>();
    const isEmpty = useRef<boolean>(true);

    // TODO should check/sanitize results for lexType
    function resultToId(result:any): string {
        switch(result['lexType']) {
            case 'term':
                return String(result.slug)
            case 'definition':
                return String(result.id)
            case 'document':
                return String(result.id)
            default:
                console.log('resultToKey encountered unknown lexType: ', result['lexType']);
                return "";
        }
    }

    function resultToTitle(result:any): string {
        switch(result['lexType']) {
            case 'term':
                return String(result.term)
            case 'definition':
                return String(result.definition)
            case 'document':
                return String(result.title)
            default:
                console.log('resultToTitle encountered unknown lexType: ', result['lexType']);
                return "";
        }
    }

    function resultToDescriptor(result:any): DescriptorModel {
        switch(result['lexType']) {
            case 'term':
                return { title: result.lexType, jurisIDs: []};    
            case 'definition':
                return { title: result.lexType, jurisIDs: []}; // TODO have to have backend send codes and not foreign keys
            case 'document':
                return { title: result.lexType, jurisIDs: [result.jurisdiction.slug]}; // TODO: should use juris countryCode but to_lower or fix juris component
            default:
                console.log('resultToDescriptor encountered unknown lexType: ', result['lexType']);
                return {title: "", jurisIDs: []};
        }
    }

    const searchTerm = useAppSelector((state: RootState) => state.search.text);

    let skip: boolean;
    skip = (searchTerm === "")
    let result = useGetSearchResultQuery(searchTerm, { skip });

    let theResults: any[];
    let content;

    if (result.isSuccess) {
        console.log('data: ', result.data);
        searchResults = result.data.results;
    }

    useEffect(() => {
      // Starts fade out
      let resizeDelay = 0;
      if (!isEmpty.current )
      {
        setContentOpacity(0);
        resizeDelay = animationStepDelay;
      }
      isEmpty.current = searchResults === undefined;

      // Waits until the fadeout is done, then resizes the panel
      setTimeout(() => {
        setPanelHeight(calculatePanelPxHeight(searchResults));}, 
        resizeDelay); // delay for fade out
      
      // Waits for the resizing to be done, then  adds the content (invisible) and starts fading in  
      const fadeInDelay = resizeDelay + animationStepDelay;
      setTimeout(() => {
        setDisplayResults(searchResults);
        if (searchResults)
          setContentOpacity(1);}, 
          fadeInDelay); // 
      
    }, [searchResults]);

    function calculatePanelPxHeight(results?: any[]) : string {
      if (!results) 
        return "0px";

      const rowCount : number = results.length;

      if (rowCount === 0)
        return rowPxHeight + "px";

      const totalRowPxHeight : number = (rowCount * rowPxHeight); 
      const totalSeparatorPxWidth : number = (rowCount * separatorPxWidth);
      return (totalRowPxHeight + totalSeparatorPxWidth) + "px";
    }

    const panelStyle = {height: panelHeight, transition: 'height ' + animationStepDuration + 'ms ease-in-out'};
    const contentStyle = {opacity: contentOpacity, transition: 'opacity ' + animationStepDuration + 'ms ease-out'};
    return (
        <div className="search-results-panel" style={panelStyle}>
          <div className="top-shadow shadow"/>
          {<div className="content" style={ contentStyle}>
            {displayResults && displayResults.length === 0 && <div className="no-results-found-panel" style={ {height: rowPxHeight + "px"} }>No results found.</div>}
            {displayResults && displayResults.map((searchResult, index) => (
              <NeoSearchResultRow
                lexObject={searchResult}
                rowIndex={index}
                pxHeight={rowPxHeight}
                separatorPxWidth={separatorPxWidth}
                //id={resultToId(searchResult)}
                //title={resultToTitle(searchResult)}
                //descriptor={resultToDescriptor(searchResult)}
                //lexType={searchResult.lexType}
                isSelected={index === selectedIndex}
                hoverHandler={(rowIndex) => rowHoverHandler(rowIndex)}
                key={index}/>
            ))}
          </div>}
          <div className="bottom-shadow shadow"/>
        </div>
      );
};

export default memo(NeoSearchResultsPanel);
