// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.centered-panel {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: start;
}
.centered-panel .title-wrapper {
  flex: 1 1 100%;
  flex-direction: row;
}
.centered-panel .title-wrapper .title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1rem;
  font-size: 1.6rem;
  font-weight: 600;
  color: #4254BE;
  padding: 1.8rem;
}
@media screen and (max-width: 576px) {
  .centered-panel .title-wrapper .title {
    padding: 1.4rem;
  }
}
.centered-panel .content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  row-gap: 1rem;
  justify-content: space-evenly;
  align-items: center;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 576px) {
  .centered-panel .content {
    max-width: 100%;
  }
}
.centered-panel .left-justified-content {
  display: block;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}`, "",{"version":3,"sources":["webpack://./src/styling/centered-panel.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,eAAA;EACA,mBAAA;EACA,oBAAA;AACJ;AACE;EACE,cAAA;EACA,mBAAA;AACJ;AAAI;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;EACA,eAAA;AAEN;AADM;EATF;IAUI,eAAA;EAIN;AACF;AACE;EACE,aAAA;EACA,eAAA;EACA,mBAAA;EACA,aAAA;EACA,6BAAA;EAEA,mBAAA;EACA,cAAA;EAIA,iBAAA;EACA,kBAAA;AAHJ;AADI;EATF;IAUI,eAAA;EAIJ;AACF;AACE;EACE,cAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AACJ","sourcesContent":[".centered-panel {\n    display: flex;\n    flex-wrap: wrap;\n    flex-direction: row;\n    align-content: start;\n\n  .title-wrapper {\n    flex: 1 1  100%;\n    flex-direction: row;\n    .title {\n      display: flex;\n      justify-content: center;\n      align-items: center;\n      height: 1rem;\n      font-size: 1.6rem;\n      font-weight: 600;\n      color: #4254BE;\n      padding: 1.8rem;\n      @media screen and (max-width: 576px) {\n        padding: 1.4rem;\n      }\n\n    }\n  }\n\n  .content {\n    display: flex;\n    flex-wrap: wrap;\n    flex-direction: row;\n    row-gap: 1rem; \n    justify-content: space-evenly;\n//    align-content: center;\n    align-items: center;\n    max-width: 80%;\n    @media screen and (max-width: 576px) {\n      max-width: 100%;\n    }\n    margin-left: auto;\n    margin-right: auto;\n  }\n\n  .left-justified-content {\n    display: block;\n    max-width: 500px;\n    margin-left: auto;\n    margin-right: auto;\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
