import { FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import type { TocEntry } from '../interfaces/TocEntry';
import { Tree } from 'react-arborist';
import TocTreeNode from './TocTreeNode';
import { useMediaQuery } from 'react-responsive';
import { useDebouncedCallback } from 'use-debounce';

export interface TocTreeProps {
  tocEntries: TocEntry[];
}

function getNumberNodes(tocEntries: TocEntry[]) : number {

  if (null == tocEntries || 0 === tocEntries.length) {
    return 0;
  }

  let count = 0;
  for (let i=0; i< tocEntries.length; ++i) {
    let children = null;
    if ('children' in tocEntries[i]) {
      children = tocEntries[i].children
    }
    if (children) {
      const numChildren = getNumberNodes(children);
      count = count + numChildren + 1;
    }
    else {
      count = count + 1;
    }
  }
  return count;  
}

const TocTree: FC<TocTreeProps> = ({ tocEntries }) => {

  let initWidth = 460;

  if (useMediaQuery({maxWidth: 576})) {
    initWidth = 200;
  }
  else if (useMediaQuery({maxWidth: 800})) {
    initWidth = 280;
  }
  else if (useMediaQuery({maxWidth: 1000})) {
    initWidth = 340;
  }
  else if (useMediaQuery({maxWidth: 1200})) {
    initWidth = 400;
  }
  else {
    initWidth = 460;
  }

  const [width, setWidth] = useState<number>(initWidth);
  const [temp, setTemp] = useState<number>(initWidth);

  const treeRef = useRef<any>(null);

  const padding = 8;
  const rowHeight = 28;

  let height;
  const numRows = useMemo(() => getNumberNodes(tocEntries), [height]);
  height = numRows*rowHeight + 2*padding;

  const debouncedHandleResize = useDebouncedCallback(
      () => {
        setWidth(temp);
      }, 1000);

  useEffect(() => {
      const windowWidth = window.innerWidth;

    let newWidth = 460;
    if (windowWidth <= 576) {
      newWidth = 200;
      setTemp(200);
    }
    else if (windowWidth <= 800) {
      newWidth = 280;
      setTemp(280);
    }
    else if (windowWidth <= 1000) {
      newWidth = 340;
      setTemp(340);
    }
    else if (windowWidth <= 1200) {
      newWidth = 340;
      setTemp(400);
    }
    else {
      newWidth = 460;
      setTemp(460);
    }

    var arg = { newWidth: newWidth };
    window.addEventListener('resize', debouncedHandleResize.bind(arg));

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, []);

  return (
    <>
      <Tree
      data={tocEntries}
      indent={16}
      padding={padding}
      rowHeight={rowHeight}
      width={width}
      height={height}
      ref={treeRef}
      //idAccessor='bookmarkId'
      idAccessor={(d) => d.entryId}
      //onRename={onRename}
      //onDelete={onDelete}
      //onCreate={onCreate}
      //onMove={onMove}
    >
        {TocTreeNode}
      </Tree>
    </>
  )
}

export default memo(TocTree);
