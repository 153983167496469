import '../styling/reference-list-panel.scss';
import '../styling/slide-out-panel.scss'; // for p-none, TODO remove
import { useParams } from 'react-router';
import { useGetRefsByJurisdictionQuery, useGetRefsByStatusQuery } from '../slices/apiSlice';
import LexogonLoader from './LexogonLoader';
import LLOLinkList from './LLOLinkList';
import { jurisIdToText } from '../common/jurisIdToText';
import { jurisdictionPreposition } from '../common/jurisdictionPreposition';
import { prettyPrintRefStatus } from '../common/prettyPrintRefStatus';

const ReferenceListPanel = () => {

  const params = useParams();
  const numKeys = Object.keys(params).length;

  function titleByStatus(token: string) {
    return `Documents with status "${prettyPrintRefStatus(token)}"`;
  }

  function titleByJurisdiction(token: string) {
    return `Documents from ${jurisdictionPreposition(token)} ${jurisIdToText(token)}`; 
  }

  let category: string = '';
  let categoryToken: string = '';

  if (numKeys === 2) {
    category = String(params['category']);
    categoryToken = String(params['categoryToken']);
  }
  else {
    console.log('did not get expected number of tokens.  Params were: ', params);
  }

  let query;
  let titleFunction;
  if (category.toLowerCase() === 'jurisdiction') {
    query = useGetRefsByJurisdictionQuery;
    titleFunction = titleByJurisdiction;
  }
  else if (category.toLowerCase() === 'status') {
    query = useGetRefsByStatusQuery;
    titleFunction = titleByStatus;
  }
  else {
    return (<>Yikes...</>);
  }

  const {
    data: references,
    isSuccess: isQuerySuccess,
    isLoading: isQueryLoading,
  } = query(categoryToken);

  let content;

  if (isQueryLoading) {
    content = <LexogonLoader/>
  }

  if (isQuerySuccess && references) {
    if (0 != references.length) {
      content = <LLOLinkList lexObjects={references} />;
    }
    else {
      content = <p className="p-none"><i>None found</i></p>;
    }
  }

  const title: string = titleFunction(categoryToken);

  return (
    <div className="reference-list-panel">
      <div className="centered-column">
        <div className="header">
          {title}
        </div>
        {content}
      </div>
    </div>
  );
}

export default ReferenceListPanel; 
