// takes text and creates a unique set of 'phrases' up to the depth provided
export function potentialTermSet(text: string, depth: number) : Set<string> {
  const words = text.trim().toLowerCase().split(' ');

  let termSet = new Set<string>();

  for (let w = 0; w <words.length; ++w) {
    termSet.add(words[w]);
  }

  for (let window = 1; window <= depth; ++window) {

    let endWord: number = words.length - window;
    if (endWord < 0) {
        return termSet;
    }

    for (let w = 0; w <= endWord; ++w) {
      let phrase: string = '';
      for (let i = 0; i< window; ++i) {
        if (i != 0) phrase = phrase + " ";
        phrase = phrase + words[w + i];
      }
      termSet.add(phrase);
    }
  }
  return termSet;
}
