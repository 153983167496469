export enum AccountLevel{
    Guest,
    Regular,
    Premium
}

export class AccountLevelHelper{
    public static getLevelDescription(anAccountLevel: AccountLevel) : string{
        switch (anAccountLevel){
            case AccountLevel.Premium: return "Premium account";
            case AccountLevel.Regular: return "Regular account";
            default: return "Guest account";
        }
    }
}