export function lexTypeToToken(lexType: string) : string {
  switch (lexType) {
    case "term":
      return "term";
    case "publication":
      return "publication";
    case "document":
      return "document";
    case "bookmark-folder":
      return "folders";
    default:
      console.log("got unknown lexType: ", lexType);
      return "";
  }
}
