// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.expansion-button {
  fill: currentColor;
  cursor: pointer;
}
.expansion-button.expanded .arrow-up {
  opacity: 1;
}
.expansion-button.expanded .arrow-down {
  opacity: 0;
}
.expansion-button.collapsed .arrow-up {
  opacity: 0;
}
.expansion-button.collapsed .arrow-down {
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/shared/expansion-button/expansion-button.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,eAAA;AACJ;AACQ;EACI,UAAA;AACZ;AACU;EACE,UAAA;AACZ;AAGQ;EACI,UAAA;AADZ;AAGU;EACE,UAAA;AADZ","sourcesContent":[".expansion-button{\n    fill: currentColor;\n    cursor: pointer;\n    &.expanded{\n        .arrow-up{\n            opacity: 1;\n          }\n          .arrow-down{\n            opacity: 0;\n          }\n    }\n    &.collapsed{\n        .arrow-up{\n            opacity: 0;\n          }\n          .arrow-down{\n            opacity: 1;\n          }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
