// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.single-version-band {
  display: grid;
  grid-template: "main";
  row-gap: 2em;
}`, "",{"version":3,"sources":["webpack://./src/styling/single-version-band.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,qBAAA;EACA,YAAA;AADJ","sourcesContent":["@import \"./band\";\n\n.single-version-band{\n    display: grid;\n    grid-template: \"main\";\n    row-gap: 2em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
