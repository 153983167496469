import Descriptor from "../shared/descriptor/descriptor";
import { FC } from 'react';
//import VersionSelectionPanel from "./VersionSelectionPanel";
//import {DocumentBasedBookmarkProps, DocumentBasedBookmarkModel} from "./DocumentBasedBookmark";
import RelatedEntryPanel, { RelatedEntryPanelModel } from "./RelatedEntryPanel";
import BookmarkPanelSectionPanel from "./BookmarkPanelSection";
import type { LexLightObject } from '../interfaces/LexLightObject';

export interface PotentialBookmarkBodyDocumentModel { //extends DocumentBasedBookmarkModel {
  lexObject: LexLightObject;
  relatedDocs? : RelatedEntryPanelModel[]
}

const PotentialBookmarkBodyDocument: FC<PotentialBookmarkBodyDocumentModel> = ({ lexObject, relatedDocs }) => {

    const title: string = lexObject.pedigree ? lexObject.pedigree : "Not specified";

    return (
    <div className="bookmark">
        <Descriptor title={title} jurisIDs={lexObject.jurisIDs}/>
        {relatedDocs && ( 
            <BookmarkPanelSectionPanel title="RELATED ENTRIES" >
            {relatedDocs.map((relatedDoc, index) => (
                <RelatedEntryPanel 
                    key={index}
                    {...relatedDoc}
                    selectionState={relatedDoc.selectionState} 
                    parentSelectionState="UNSELECTED" />))} 
            </BookmarkPanelSectionPanel> 
        )}
    </div>
  );
} 
  
export default PotentialBookmarkBodyDocument;
