import { FC } from 'react';
import FlagStrip from '../shared/flag-strip/flag-strip';
import "../styling/descriptor.scss";
import { ReactComponent as LinkOutIcon } from '../shared/assets/images/icons/link-out-icon.svg';
import ReferenceStatusIndicator from './ReferenceStatusIndicator';
import type { LexLightObject } from '../interfaces/LexLightObject';

export interface NeoDescriptorModel {
  lexObject: LexLightObject; 
}

export interface NeoDescriptorProps extends NeoDescriptorModel{
  
}


const NeoDescriptor: FC<NeoDescriptorProps> = ({ lexObject }) => {

  let classes : string[] = ["descriptor"];
//  if (extraClasses) 
//    classes = classes.concat(extraClasses);

  return (
    <div className={classes.join(" ")}>
      <div className="descriptor-title">{lexObject.lexType}</div>
      <FlagStrip jurisIDs={lexObject.jurisIDs}/>
      { lexObject.status && <ReferenceStatusIndicator status={lexObject.status} />}
      {/*outsideLink && <a href={outsideLink} target="_blank" rel="noreferrer" title={`Open source document ${outsideLink} in new tab/window`}><LinkOutIcon className="link-out"/></a>*/}
    </div>
  );
};

export default NeoDescriptor;
