import '../styling/welcome-panel.scss';
import '../styling/elevator-pitch-panel.scss';
import '../styling/centered-text.scss';
import { useEffect, useState } from 'react';
import { useGetReferenceCountsQuery } from '../slices/apiSlice';
import LexogonLoader from './LexogonLoader';
import AnimatedNumbers from 'react-animated-numbers';

const DocumentCoverage = () => {
  const [totalDocs, setTotalDocs] = useState<number>(1000);

  const {
    data: counts,
    isLoading,
  } = useGetReferenceCountsQuery();


  useEffect(() => {
    if (counts) {
      console.log('counts total: ', counts.total);
      setTotalDocs(counts.total);
    }
  }, [counts]);


  if (isLoading) return <LexogonLoader />;

  return (
    <div className="welcome-panel app-body">
      <div className="elevator-pitch-panel">
        Lexogon currently covers
        <b><AnimatedNumbers includeComma animateToNumber={totalDocs} className="centered-text"/></b>
    legal, regulatory, and working group documents.
      </div>
    </div>
  );

}

export default DocumentCoverage;
