// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.centered-text {
  margin: auto;
  padding: 0.5rem;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/styling/centered-text.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,eAAA;EACA,mBAAA;EACA,uBAAA;AACF","sourcesContent":[".centered-text {\n  margin: auto;\n  padding: 0.5rem;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
