import type { Definition } from '../interfaces/types';


function toComparisonString(str: string) : string {
  // remove all spaces and punctuation with nothing
  return str.replace(/[\s.,/#!$%^&*;:{}=\-_`~()]/g,"").toLowerCase();
}


export function groupSameDefinitions(sourceDefinitions: Definition[]) : [Definition][] {

  let groupedDefinitions : [Definition][] = [];

  for (let i=0; i<sourceDefinitions.length; ++i) {
    let isUnique : boolean = true;
    for (let j=0; j<groupedDefinitions.length; ++j) {
      if (toComparisonString(sourceDefinitions[i].definition) === toComparisonString(groupedDefinitions[j][0].definition)) {
        groupedDefinitions[j].push(sourceDefinitions[i]);
        isUnique = false;
      }
    }
    if (isUnique) {
      groupedDefinitions.push([sourceDefinitions[i]]);
    }
  }

  return groupedDefinitions;
}
