import '../styling/panel-header.scss';
import '../styling/panel-section.scss';
import '../styling/library-panel.scss';
import DashboardMenuItem from './DashboardMenuItem';

import { ReactComponent as JurisdictionIcon } from '../shared/assets/images/icons/globe-icon.svg';
import { ReactComponent as ActiveIcon } from '../shared/assets/images/icons/heartbeat-icon.svg';

const LibraryPanel = () => {

  return (
    <div className="centered-panel">
      <div className="title-wrapper">
        <div className="title">
          Browse documents by... 
        </div>
      </div>
      <div className="content">
        <DashboardMenuItem
          icon = {<JurisdictionIcon />}
          title = {"Jurisdiction"}
          navigateTo = {'/document/jurisdiction'}
        />
        <DashboardMenuItem
          icon = {<ActiveIcon />}
          title = {"Status"}
          navigateTo = {'/document/status'}
        />
      </div>
    </div>
  )
}

export default LibraryPanel;
