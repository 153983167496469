import '../styling/slide-out-panel.scss';
import '../styling/click-for-more.scss';
import { FC, useEffect, useState } from 'react';
import type { LexLightObject } from '../interfaces/LexLightObject';
import LLOLinkList from './LLOLinkList';
import { useGetTermsContainingTermQuery, useGetRefsContainingTermQuery } from '../slices/apiSlice';
import LexogonLoader from './LexogonLoader';
import ModalBox from './ModalBox';
import AdvancedLLOLinkList from './AdvancedLLOLinkList';

const TermRelationshipsPanel: FC<LexLightObject> = (lexObject: LexLightObject) => {

  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalList, setModalList] = useState<LexLightObject[]>([]);
  const [modalTitle, setModalTitle] = useState<string>('');

  const relationLimit = 7;
  let seeMoreTerms: boolean = false;
  let seeMoreRefs: boolean = false;

  const onSeeMoreTermsClicked = () => {
    if (termsContainingTerm) {
      setModalList(termsContainingTerm);
      setModalTitle(`Terms containing the term "${lexObject.displayTitle}"`);
      setShowModal(true);
    }
  }

  const onSeeMoreRefsClicked = () => {
    if (refsContainingTerm) {
      setModalList(refsContainingTerm);
      setModalTitle(`References containing the term "${lexObject.displayTitle}"`);
      setShowModal(true);
    }
  }

  useEffect(() => {
    if(!showModal) {
      setModalList([]);
      setModalTitle('');
    }
  }, [showModal]);

  const {
    data: termsContainingTerm,
    isLoading: isTermsLoading,
    isSuccess: isTermsSuccess,
    isFetching: isTermsFetching,
    isError: isTermsError,
    //    error: termsError
  } = useGetTermsContainingTermQuery(lexObject);

  const {
    data: refsContainingTerm,
    isLoading: isRefsLoading,
    isSuccess: isRefsSuccess,
    isFetching: isRefsFetching,
    isError: isRefsError,
    //    error: refsError
  } = useGetRefsContainingTermQuery(lexObject);


  let termsContainingTermContent = <p>...</p>;
  let refsContainingTermContent = <p>...</p>;

  if (isTermsLoading || isTermsFetching) termsContainingTermContent = <LexogonLoader />;
  if (isTermsError) return (<div>Query error</div>);
  if (isTermsSuccess && termsContainingTerm) {
    if (termsContainingTerm.length == 0) {
      termsContainingTermContent = <p className="p-none"><i>None</i></p>;
    }
    else {
      if (termsContainingTerm.length <= relationLimit) {
        termsContainingTermContent = <LLOLinkList lexObjects={termsContainingTerm} />;
      }
      else {
        termsContainingTermContent = <LLOLinkList lexObjects={termsContainingTerm.slice(0, relationLimit)} />;
        seeMoreTerms = true;
      }
    }
  }

  if (isRefsLoading || isRefsFetching) refsContainingTermContent = <LexogonLoader />;
  if (isRefsError) return (<div>Query error</div>);
  if (isRefsSuccess && refsContainingTerm) {
    if (refsContainingTerm.length == 0) {
      refsContainingTermContent = <p className="p-none"><i>None</i></p>;
    }
    else {
      if (refsContainingTerm.length <= relationLimit) {
        refsContainingTermContent = <LLOLinkList lexObjects={refsContainingTerm} />;
      }
      else {
        refsContainingTermContent = <LLOLinkList lexObjects={refsContainingTerm.slice(0, relationLimit)} />;
        seeMoreRefs = true;
      }
    }
  }

  const content =
    <div className="content">
      <div className="section-title">
        This Term Appears in Terms:
      </div>
      <div className="section-content">
        {termsContainingTermContent}
        {seeMoreTerms && termsContainingTerm?.length && <div className="click-for-more" onClick={onSeeMoreTermsClicked}>See {termsContainingTerm.length - relationLimit} more...</div>}
      </div>
      <div className="section-title">
        This Term Appears in References: 
      </div>
      <div className="section-content">
      {refsContainingTermContent}
      {seeMoreRefs && refsContainingTerm?.length && <div className="click-for-more" onClick={onSeeMoreRefsClicked}>See {refsContainingTerm.length - relationLimit} more...</div>}
      </div>
      <div className="section-title">
        Appears in 510(k) summaries: 
      </div>
      <p>...</p>
    </div>;

  return (
    <>
      {content}
      {showModal &&
        <ModalBox setVisible={setShowModal}
                  childComponent={
                    <AdvancedLLOLinkList lexObjects={modalList} title={modalTitle} />} />}
    </>
  )
}

export default TermRelationshipsPanel;
