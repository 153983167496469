import type { ReferenceEntry } from '../interfaces/types';
import type { TocEntry } from '../interfaces/TocEntry';

export function referenceEntryToTocEntry(referenceEntry: ReferenceEntry) : TocEntry {

  let entry: TocEntry = {
    'referenceId': referenceEntry.referenceId,
    'entryId': referenceEntry.documentEntryUid,
    'level': referenceEntry.level,
    'displayTitle': referenceEntry.content,
    'contentType': referenceEntry.contentType,
    'index': referenceEntry.index,
    'children': [],
  };


  return entry;
}
