import '../styling/panel-header.scss';
import '../styling/panel-section.scss';
import '../styling/test-panel.scss';
import FlagStrip from '../shared/flag-strip/flag-strip';
import { useGetJurisdictionsQuery } from '../slices/apiSlice';
import { useNavigate } from 'react-router-dom';
import LexogonLoader from './LexogonLoader';
import type { LexLightObject } from '../interfaces/LexLightObject';

const JurisdictionPanel = () => {

  const navigate = useNavigate();

  function handleClick(s: string) {
    navigate(`/document/jurisdiction/${s}`);
  }


  const tempJurisdictions: LexLightObject[] = [
    {
      'lexType': 'jurisdiction',
      'instanceId': '00',
      'displayTitle': 'International working group or standards body',
      'jurisIDs': ['00']
    },
    {
      'lexType': 'jurisdiction',
      'instanceId': 'EU',
      'displayTitle': 'European Union',
      'jurisIDs': ['EU']
    },
    {
      'lexType': 'jurisdiction',
      'instanceId': 'US',
      'displayTitle': 'United States',
      'jurisIDs': ['US']
    }
  ];

//  const {
//    data: jurisdictions,
//    isSuccess: isQuerySuccess,
//    isLoading: isQueryLoading,
//  } = useGetJurisdictionsQuery();

//  let content;

//  if (isQueryLoading) {
//    content = <LexogonLoader/>;
//  }
//  if (isQuerySuccess && jurisdictions) {
//    content = jurisdictions.map((j, index) => (
//      <div className="link-list-item" key={index} onClick={() => handleClick(j.instanceId.toString())} >
//        <FlagStrip jurisIDs={[`${j.instanceId}` ]} />
//        <span className="link-list-text">{ j.displayTitle }</span>
//      </div>
//    ))
//  }

  let content = tempJurisdictions.map((j, index) => (
      <div className="link-list-item" key={index} onClick={() => handleClick(j.instanceId.toString())} >
        <FlagStrip jurisIDs={[`${j.instanceId}` ]} />
        <span className="link-list-text">{ j.displayTitle }</span>
      </div>
    ));

  return (
    <div className="test-panel">
      <div className="centered-column">
        <h3>Documents By Jurisdiction</h3>
        {content}
      </div>
    </div>
  );
}

export default JurisdictionPanel;
