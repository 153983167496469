import '../styling/toc-element.scss';
import { FC } from 'react';
import type { TocEntry } from '../interfaces/TocEntry';

const TocElement: FC<TocEntry> = ( tocEntry: TocEntry ) => {

  const marginBase = 0.5;

  const thisMargin = 4.2+ tocEntry.level * marginBase;

  const style = {
    paddingLeft: `${thisMargin}rem`,
  }

  const handleClick = () => {
    document.getElementById(tocEntry.entryId)!.scrollIntoView();
  }

  const link = `#${tocEntry.entryId}`;

  let content;
  if (tocEntry.children && tocEntry.children.length > 0) {
    content = tocEntry.displayTitle;
  }
  else {
    content = tocEntry.displayTitle;
  }

  return (
    <div className='toc-element' onClick={handleClick} title={`Jump to ${tocEntry.displayTitle}`}>
      <div style={style}>
        <div className="toc-element-text">
        {content}
        </div>
      </div>
    </div>
  );
}

export default TocElement;
