import '../styling/llo-list.scss';
import type { LexLightObject } from '../interfaces/LexLightObject';
import LLOLink from './LLOLink';

export interface LLOLinkListProps {
  lexObjects: LexLightObject[];
}

const LLOLinkList: React.FC<LLOLinkListProps> = ({lexObjects}) => {

  return (
    <ul className="llo-list">
      {lexObjects.map((object, index) => {
        return (
          <li key={index} className={object.lexType}>
            <LLOLink {...object} />
          </li>
        )
      })}
    </ul>
  );
}

export default LLOLinkList;
