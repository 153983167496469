import "../styling/definition-source.scss";
import { FC } from 'react';
import EntryTitle from "../shared/entry-title/entry-title";
import type { Definition } from "../interfaces/types";

export interface DefinitionSourceRModel {
  definition: Definition;
}

const DefinitionSourceR: FC<DefinitionSourceRModel> = ({ definition }) => {

  const isOkay : boolean = definition.document ? true : false;

  return (
    <>
    {isOkay &&
      <span className="definition-source" >
       <span className="definition-source-document-level">{definition.document.pedigree.toLocaleLowerCase()}{": "}</span>
       <EntryTitle title={definition.document.title} jurisIDs={[definition.jurisdiction.countryCode]} reference={definition.document} />
   </span>
    }
  </>
  );
}
  
export default DefinitionSourceR;
