import '../styling/accordion.scss'
import { FC, useState } from 'react'
import { ReactComponent as ExpandIcon } from '../shared/assets/images/icons/down-chevron.svg';
import { ReactComponent as CloseIcon } from '../shared/assets/images/icons/up-chevron.svg';

export type AccordionElementProps = {
  title: string;
  childComponent: React.ReactNode;
  openToStart?: boolean;
}

const AccordionElement: FC<AccordionElementProps> = ({title, childComponent, openToStart=false}) => {
  const [isOpen, setIsOpen] = useState<boolean>(openToStart);

  return (
    <div className='accordion-element'>
      <div className='accordion-title-bar' onClick={() => setIsOpen(!isOpen)}>
        <div className='accordion-title-text'>{title}</div>
        <div className='accordion-title-icon'>{isOpen ? <CloseIcon/> : <ExpandIcon/>}</div>
      </div>
        {isOpen && childComponent}
    </div>
  );
}

export default AccordionElement;
