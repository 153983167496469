import "../styling/library-explorer.scss";
import '../styling/centered-panel.scss';

const OaiPrivacyPolicy = () => {

  return (
    <div className="centered-panel">
      <div className="title-wrapper">
        <div className="title">
           Lexogon Office Add-in Privacy Policy
        </div>
      </div>
      <div className="left-justified-content">
        <p>
        The Lexogon Office Add-in (the &quot;Add-in&quot;) is a tool for Microsoft Word which assists the user in finding regulatory and medical device terms in their Word documents.
        </p>
        <p>The Add-in adheres to the following privacy policy:</p>
        <ul>
          <li>The Add-in does not collect any personal information about the user using the Add-in.</li>
          <li>The Add-in does not collect any data from any document for which the user uses the Add-in.</li>
          <li>The Add-in does not collect any statistical or other data about the use of the Add-in or any documents it is used to analyze.</li>
        </ul>
      </div>
    </div>
  );
}

export default OaiPrivacyPolicy;
