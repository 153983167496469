export function jurisIdToText(jurisID: string): string {

  switch (jurisID.toUpperCase()) {
    case '00':
      return 'International Working Group or Standards Body';
    case 'EU':
      return 'European Union';
    case 'US':
      return 'United States';
    default:
      return jurisID.toUpperCase();
  }

}
