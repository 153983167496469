import "../styling/content-panel.scss";
import { FC, memo } from 'react';
import ContentBlockReference from './ContentBlockReference';
import ContentBlockReferenceAsPdf from './ContentBlockReferenceAsPdf';
import type { LexLightObject } from '../interfaces/LexLightObject';
import { useGetReferenceSegmentsQuery } from '../slices/apiSlice';
import LexogonLoader from './LexogonLoader';

export interface ContentPanelReferenceModel {
  lexObject: LexLightObject;
}

const ContentPanelReference: FC<ContentPanelReferenceModel> = ({lexObject}) => {

  function isPdf(s: string) : boolean {
    return s.toLowerCase().includes('.pdf');
  }

  const {
    data: referenceResult,
    isLoading: isQueryLoading,
    isSuccess: isQuerySuccess,
    isFetching: isQueryFetching,
    isError: isQueryError,
    //    error: queryError
  } = useGetReferenceSegmentsQuery(lexObject.instanceId.toString());
  
  if (isQueryLoading || isQueryFetching) return(<LexogonLoader />);
  if (isQueryError) return(<div>An error occurred</div>);
  if (!referenceResult) return(<div>Error: failed to retrieve data for reference</div>);

  const pdf = isPdf(referenceResult.reference.localUrl)

  return (
    <div className="content-panel">
      {pdf ?
        <ContentBlockReferenceAsPdf lexObject={lexObject} referenceResult={referenceResult} /> :
        <ContentBlockReference lexObject={lexObject} referenceResult={referenceResult} />
      }
    </div>
  );
}

export default memo(ContentPanelReference);
