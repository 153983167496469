// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KeyFeaturePanel {
  display: grid;
  grid-template: "Image" auto "Text";
  justify-items: center;
  align-content: start;
  grid-gap: 0.5em;
  width: 18em;
  cursor: default;
  padding: 1.5em;
  outline: 0.18em solid;
  outline-color: transparent;
  transition: outline-color 1s ease;
  border-radius: 1em;
  margin-bottom: 1rem;
}

.KeyFeaturePanel:hover {
  outline-color: #F2848D;
}

.KeyFeatureText {
  grid-area: Text;
  text-align: center;
  line-height: 2;
  margin: 0 1em;
  font-size: large;
}

.KeyFeaturePanel .Description {
  font-weight: 300;
  color: transparent;
  transition: color 1.5s ease;
}

.KeyFeaturePanel:hover .Description {
  color: var(--SoftTextColor);
}

.KeyFeatureImage {
  grid-area: Image;
  width: 14em;
  pointer-events: none; /*So Edge does not add the graphic search icon*/
}

.KeyFeaturePanel .Heading {
  font-weight: 600;
  color: var(--CompanyColor);
}`, "",{"version":3,"sources":["webpack://./src/styling/key-feature-panel.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,kCACI;EAEJ,qBAAA;EACA,oBAAA;EACA,eAAA;EACA,WAAA;EACA,eAAA;EACA,cAAA;EACA,qBAAA;EACA,0BAAA;EACA,iCAAA;EACA,kBAAA;EACA,mBAAA;AADJ;;AAIA;EACI,sBAAA;AADJ;;AAIA;EACI,eAAA;EACA,kBAAA;EACA,cAAA;EACA,aAAA;EACA,gBAAA;AADJ;;AAIA;EACI,gBAAA;EACA,kBAAA;EACA,2BAAA;AADJ;;AAIA;EACI,2BAAA;AADJ;;AAIA;EACI,gBAAA;EACA,WAAA;EACA,oBAAA,EAAA,+CAAA;AADJ;;AAIA;EACI,gBAAA;EACA,0BAAA;AADJ","sourcesContent":[".KeyFeaturePanel{\n    display: grid;\n    grid-template:\n        \"Image\" auto\n        \"Text\";\n    justify-items: center;\n    align-content: start;\n    grid-gap: 0.5em;\n    width: 18em;\n    cursor: default;\n    padding: 1.5em;\n    outline: 0.18em solid;\n    outline-color: transparent;\n    transition: outline-color 1s ease;\n    border-radius: 1em;\n    margin-bottom: 1rem;\n}\n\n.KeyFeaturePanel:hover{\n    outline-color:  #F2848D;    \n}\n\n.KeyFeatureText{\n    grid-area: Text;\n    text-align: center;\n    line-height: 2;\n    margin: 0 1em;\n    font-size: large;\n}\n\n.KeyFeaturePanel .Description{\n    font-weight: 300;\n    color: transparent;\n    transition: color 1.5s ease;\n}\n\n.KeyFeaturePanel:hover .Description{\n    color: var(--SoftTextColor);\n}\n\n.KeyFeatureImage{\n    grid-area: Image;\n    width: 14em;\n    pointer-events: none; /*So Edge does not add the graphic search icon*/\n}\n\n.KeyFeaturePanel .Heading{\n    font-weight: 600;\n    color: var(--CompanyColor);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
