import { FC } from 'react';
import FlagStrip from '../flag-strip/flag-strip';
import "./descriptor.scss";
import { ReactComponent as LinkOutIcon } from '../assets/images/icons/link-out-icon.svg';
import ReferenceStatusIndicator from '../../components/ReferenceStatusIndicator';

export interface DescriptorModel { 
  title: string;
  jurisIDs: string[];
  status?: string;
  extraClasses?: string[];
  outsideLink?: string;
}

export interface DescriptorProps extends DescriptorModel{
  
}


const Descriptor: FC<DescriptorProps> = ({ title, jurisIDs, status, extraClasses, outsideLink }) => {

  let classes : string[] = ["descriptor"];
  if (extraClasses) 
    classes = classes.concat(extraClasses);

  return (
    <div className={classes.join(" ")}>
      <div className="descriptor-title">{title}</div>
      <FlagStrip jurisIDs={jurisIDs}/>
      { status && <ReferenceStatusIndicator status={status} />}
      {outsideLink && <a href={outsideLink} target="_blank" rel="noreferrer" title={`Open source document ${outsideLink} in new tab/window`}><LinkOutIcon className="link-out"/></a>}
    </div>
  );
};

export default Descriptor;
