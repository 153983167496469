import '../styling/advanced-search-options-indicator.scss';
import { FC } from 'react';
import type { AdvancedSearchOptions } from '../interfaces/AdvancedSearchOptions';
import FlagStrip from '../shared/flag-strip/flag-strip';
import { toTitleCase } from '../common/toTitleCase';

const AdvancedSearchOptionsIndicator: FC<AdvancedSearchOptions> = (options: AdvancedSearchOptions) => {


  return (
    <div className='advanced-search-options-indicator'>
      <div className='advanced-search-options-section'>
        <div className='advanced-search-options-section-title'>Types:</div>
        <div className='advanced-search-options-option'>{options.types.includes('TERM') && "\u2261 Terms"}</div>
        <div className='advanced-search-options-option'>{options.types.includes('REFERENCE') && "\u{1F4D8} References " }</div>
      </div>
      <div className='advanced-search-options-section'>
        <div className='advanced-search-options-section-title'>Jurisdictions:</div>
        <div className='advanced-search-options-option'>
          <FlagStrip jurisIDs={options.jurisdictions} />
        </div>
      </div>
      <div className='advanced-search-options-section'>
        <div className='advanced-search-options-section-title'>Pedigrees:</div>
          {options.pedigrees.map((pedigree, index) => (
            <div className='advanced-search-options-option' key={index}>{toTitleCase(pedigree)}</div>
          ))}

      </div>
      <div className='advanced-search-options-section'>
        <div className='advanced-search-options-section-title'>Sources:</div>
          {options.sources.map((source, index) => (
            <div className='advanced-search-options-option' key={index}>{source}</div>
          ))}
      </div>
      <div className='advanced-search-options-section'>
        <div className='advanced-search-options-section-title'>Statuses:</div>
          {options.statuses.map((status, index) => (
            <div className='advanced-search-options-option' key={index}>{toTitleCase(status)}</div>
          ))}

      </div>
    </div>
  );
}

export default AdvancedSearchOptionsIndicator;
