import React, { HTMLInputTypeAttribute, ChangeEvent, useState } from 'react';
import { Editability } from '../shared/enums/editability';
import '../styling/textbox.scss';

interface TextboxProps {
  id : string;
  textboxType? : HTMLInputTypeAttribute;
  placeholder? : string;
  initialText? : string;
  editability? : Editability;
  changeHandler? : (event: ChangeEvent<HTMLInputElement>) => void;
  autofocus? : boolean;

}

const Textbox: React.FC<TextboxProps> = ({id, textboxType = "text", placeholder = "", initialText = "", editability = Editability.Editable, changeHandler, autofocus = false}) => {
 
  const [text, setText] = useState(initialText);

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setText(event.target.value);
      if (changeHandler)
          changeHandler(event);
  };

  var lClassNames = ["textbox-wrapper"];
  var lReadOnly : boolean = (editability === Editability.ReadOnly);
  if (lReadOnly)
      lClassNames.push("read-only");

  return (
        <div className={lClassNames.join(" ")}  >
            <input id={id} type={textboxType} className="internal-textbox" placeholder={placeholder} readOnly={lReadOnly} onChange={handleTextChange} value ={text} autoFocus={autofocus} />
        </div>
    );
}

export default Textbox;
