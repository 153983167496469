import "../styling/content-block.scss";
//import { Point } from "../shared/point/point";
import { FC, useState, useRef } from 'react';
import { ExpansionState } from "../shared/expansion-button/expansion-button";
import ExpansionButton from "../shared/expansion-button/expansion-button";
export type ContentBlockType = "DOCUMENT_BLOCK" | "DEFINITION_BLOCK";
export type BlockInclusionReason = "SELECTED_BY_USER" | "SUGGESTED_BY_APP";
import type { ReferenceResult } from "../interfaces/types";
import type { LexLightObject } from '../interfaces/LexLightObject';
import NeoReferenceBlockHeader from "./NeoReferenceBlockHeader";
import ReferenceEntryBlockBody from "./ReferenceEntryBlockBody";
import ReferenceEntryDisplay from './ReferenceEntryDisplay';
import { useDispatch } from 'react-redux';
import { showToc } from '../slices/tocSlice';

export interface ContentBlockReferenceModel {
  lexObject: LexLightObject;
  referenceResult: ReferenceResult;
  inclusionReason? : BlockInclusionReason;
}

// TODO could be useful elsewhere
//function cursorIsWithinComponentBoundaries(cursorPosition : Point, element : HTMLDivElement) : boolean {
//  const { left, top, right, bottom } = element.getBoundingClientRect();
//  return (cursorPosition.x >= left && cursorPosition.x <= right && cursorPosition.y >= top && cursorPosition.y <= bottom);
//}

const ContentBlockReference: FC<ContentBlockReferenceModel> = ({ lexObject, referenceResult }) => {
  const dispatch = useDispatch();
  dispatch(showToc(false));

  const [expansionState, setExpansionState] = useState<ExpansionState>("EXPANDED");
  const contentBlockElement = useRef<HTMLDivElement | null>(null);

  const handleTitleClicked = () => {
    toggleExpansionState();
  }
  const handleExpansionButtonClicked = () => {
    toggleExpansionState();
  }

  const toggleExpansionState = () => {
    setExpansionState(expansionState === "EXPANDED" ? "COLLAPSED" : "EXPANDED");
  }

  const classes: string[] = ["content-block"];
  
  if (expansionState === "COLLAPSED")
    classes.push("collapsed");
  if (expansionState === "EXPANDED")
    classes.push("expanded");  

  const expansionTooltip = (expansionState === "EXPANDED")? `Collapse reference`: "Expand reference";  

  //let content = <ReferenceEntryBlockBody lexObject={lexObject} referenceEntries={referenceResult.entries} />;
  let content = <ReferenceEntryDisplay lexObject={lexObject} referenceEntries={referenceResult.entries} />;

  return (
    <div ref={contentBlockElement} id={referenceResult.reference.id.toString()} className={classes.join(" ")} > 
      <ExpansionButton extraClasses={["block-expansion-button"]} expansionState={expansionState} clickHandler={handleExpansionButtonClicked} />
      <div className="title" title={expansionTooltip} onClick={handleTitleClicked}>{referenceResult.reference.title}</div>
     <NeoReferenceBlockHeader
        reference={referenceResult.reference}
      />
      {expansionState === "EXPANDED" &&
      <>
        {content}
      </>
    } 
    </div>
  );
}
 
export default ContentBlockReference;
