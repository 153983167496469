import "../styling/library-explorer.scss";
import '../styling/centered-panel.scss';

const OaiSupport = () => {

  return (
    <div className="centered-panel">
      <div className="title-wrapper">
        <div className="title">
           Lexogon Office Add-in Support 
        </div>
      </div>
      <div className="content">
        <p>
          For support or to report a bug with the Lexogon Office Add-in, please send an email to <a href="mailto:info@lexogon.com">info@lexogon.com</a>.
        </p>
      </div>
    </div>
  );
}

export default OaiSupport;
