import '../styling/llo-link.scss'
import { urlFromLLO } from '../common/urlFromLLO';

export interface OutsideLinkProps {
  linkText: string;
  linkUrl: string;
}

const OutsideLink: React.FC<OutsideLinkProps> = ({linkText, linkUrl}) => {

  return (
    <div className='llo-link'><a href={linkUrl} target="_blank" rel="noreferrer" title={`Open ${linkUrl} in new tab/window`}>
      {linkText}
    </a>
    </div>
  );
}

export default OutsideLink;
