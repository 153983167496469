import { FC, memo, ReactNode, useEffect, useState } from 'react';
import type { LexLightObject } from '../interfaces/LexLightObject';
import type { ReferenceEntry } from '../interfaces/types';
import type { TermLink } from '../interfaces/TermLink';
import type { ReferenceContainsUrlRelationship } from '../interfaces/ReferenceRelationships';
import { useGetReferenceRelationshipsQuery } from '../slices/apiSlice';
import { useGetTermLinksQuery } from '../slices/apiSlice';
import LexogonLoader from './LexogonLoader';
import ReferenceEntryItem from './ReferenceEntryItem';
import { ReactComponent as LinkOutIcon } from '../shared/assets/images/icons/link-out-icon.svg';
import { useDispatch } from 'react-redux';
import { showToc } from '../slices/tocSlice';

export interface ReferenceEntryDisplayModel {
  lexObject: LexLightObject;
  referenceEntries: ReferenceEntry[];
}

const ReferenceEntryDisplay: FC<ReferenceEntryDisplayModel> = ({ lexObject, referenceEntries }) => {
  const dispatch = useDispatch();

  const [hasExecuted, setHasExecuted] = useState<boolean>(false);

  const [entries, setEntries] = useState<ReactNode[]>([]);
  function addEntry(entry: ReactNode) {
    setEntries(oldEntries => [...oldEntries, entry]);
  }

  async function addEntryOnDelay(termLinks: TermLink[], containsUrls: ReferenceContainsUrlRelationship[] )  {
    for (var e=0; e<referenceEntries.length; ++e) {
      const delay = (ms:number) => new Promise((resolve) => setTimeout(resolve, ms));

      addEntry(
        <ReferenceEntryItem entry={referenceEntries[e]} index={e} termLinks={termLinks} refLinks={containsUrls} isTermAlreadyLinked={isTermAlreadyLinked} addAlreadyLinkedTerm={addAlreadyLinkedTerm} key={e} />);
      await delay(5);
    }
  }

  const [alreadyLinkedTerms, setAlreadyLinkedTerms] = useState<string[]>([]);
  //let alreadyLinkedTerms: string[] = [];

  function addAlreadyLinkedTerm(term: string) {
    setAlreadyLinkedTerms([
      ...alreadyLinkedTerms,
      term
    ]);
    //alreadyLinkedTerms.push(term);
  }

  function isTermAlreadyLinked(term: string): boolean {
    return alreadyLinkedTerms.includes(term);
  }

  const {
    data: termLinks,
    isLoading,
    isFetching,
    isSuccess,
    isError,
    error
  } = useGetTermLinksQuery();

  const {
    data: relationships,
    isLoading: isQueryLoading,
    isSuccess: isQuerySuccess,
    isFetching: isQueryFetching,
    isError: isQueryError,
    error: queryError
  } = useGetReferenceRelationshipsQuery(lexObject.instanceId.toString());

  useEffect(() => {
    if (termLinks && relationships && !hasExecuted) {
      setHasExecuted(true);
      addEntryOnDelay(termLinks, relationships.containsUrls);
    }
  }, [termLinks, relationships]);

  useEffect(() => {
    if (entries.length == referenceEntries.length && (0 != referenceEntries.length)) {
      dispatch(showToc(true));
    }
  }, [entries]);

  if (!hasExecuted) {
    return (
     <div className="block-body">
      <div className="band single-version-band">
        <div className="rendered-segment">
          <div className="segment-body">
            <LexogonLoader />
          </div>
        </div>
      </div>
    </div> 
    );
  }

  if (referenceEntries.length == 0) {
    const style = { height: '0.95em'}
    entries.push(<div><i>Could not retrieve document contents.  Original document can be obtained from <LinkOutIcon style={style} /> link above.</i></div>)
  }

  return (
    <div className="block-body">
      <div className="band single-version-band">
        <div className="rendered-segment">
          <div className="segment-body">
          {entries}
          </div>
        </div>
      </div>
    </div> 
  )
}

export default memo(ReferenceEntryDisplay);
