import '../styling/llo-link.scss'
import type { LexLightObject } from '../interfaces/LexLightObject';
import { urlFromLLO } from '../common/urlFromLLO';
import FlagStrip from '../shared/flag-strip/flag-strip';

const LLOLink = (lexObject: LexLightObject) => {

  const isReference = lexObject.lexType === 'reference';

  return (
    <div className='llo-link'><a href={urlFromLLO(lexObject)}>{lexObject.displayTitle}</a>
    { isReference && <FlagStrip jurisIDs={lexObject.jurisIDs} />}
    </div>
  );
}

export default LLOLink;
