import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoginResponse } from '../interfaces/LoginResponse';
import { UserSession } from '../interfaces/UserSession';

const initialState = {
  expiry: null,
  token: null,
  user: {
    username: null
  }
} as LoginResponse;

export const userSessionSlice = createSlice({
    name: "userSession",
    initialState,
    reducers: {
      authenticate: (state, action: PayloadAction<LoginResponse>) => {
        // have to explicitly write out copy to avoid getting immer'd
        state.expiry = action.payload.expiry;
        state.token = action.payload.token;
        state.user.username = action.payload.user.username;
      },
      storeAuthentication: (state, action: PayloadAction<LoginResponse>) => {
        if (action.payload.token) {
          localStorage.setItem(
            "lexSession",
            JSON.stringify(action.payload)
          );
        }
        state.expiry = action.payload.expiry;
        state.token = action.payload.token;
        state.user.username = action.payload.user.username;
      },
      logout (state) {
        localStorage.clear();
        state.expiry = null;
        state.token = null;
        state.user.username = null;
      },
    },
})

export const { authenticate, storeAuthentication, logout } = userSessionSlice.actions
export default userSessionSlice.reducer
