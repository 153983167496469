import { FC } from 'react';
import JurisFlag from './juris-flag/juris-flag';
import "./flag-strip.scss";

interface FlagStripModel {
  jurisIDs: string[];
}

interface FlagStripProps extends FlagStripModel {
}

const FlagStrip: FC<FlagStripProps> = ({ jurisIDs }) => {
  return (
    <div className="flag-strip">
      {jurisIDs.map((jurisId, index) => (
        <JurisFlag key={index} jurisId={jurisId} />
      ))}
    </div>
  );
};

export default FlagStrip;
