import "../styling/library-explorer.scss";
import type { LexLightObject } from '../interfaces/LexLightObject';
import ContentPanelTerm from './ContentPanelTerm';
import ContentPanelReference from './ContentPanelReference';
import RelationshipsSidePanel from './RelationshipsSidePanel';
//import { ContentLimitationReason } from './LimitedContentExplanation';

const ContentPanel: React.FC<LexLightObject> = (lexObject: LexLightObject) => {

  return (
    <div id="consumption-panel">
      { lexObject.lexType === "term" && <ContentPanelTerm {...lexObject} />}
      { lexObject.lexType === "document" && <ContentPanelReference lexObject={lexObject} /> }
      <RelationshipsSidePanel {...lexObject} />
    </div>
  );
}

export default ContentPanel;
