import '../styling/selection-panel.scss';
import { FC } from 'react';
import PotentialBookmarkBlock from './PotentialBookmarkBlock';
import SavedBookmarksBlock from './SavedBookmarksBlock';
import type { LexLightObject } from '../interfaces/LexLightObject';
import AccordionElement from './AccordionElement'; 
import TableOfContentsBlock from './TableOfContentsBlock';
import { useAppSelector } from '../store/hooks';
import type { RootState } from '../store/store';

const SelectionPanel: FC<LexLightObject> = ( lexObject: LexLightObject ) => {
  const showToc = useAppSelector((state: RootState) => state.toc.showToc);

  return (
    <div id="selection-panel">
      <div className="selection-panel-container">
      {lexObject !== undefined && lexObject !== null &&
        (
          <>
            <PotentialBookmarkBlock {...lexObject} />
            <div className='accordion-area'>
              <AccordionElement
                title='Bookmarks'
                childComponent=<SavedBookmarksBlock />
              />
            </div>
          {lexObject.lexType === 'document' && showToc &&
            <TableOfContentsBlock {...lexObject} />
          }
          </>
        )}
      </div>
    </div>
  );
}
  
export default SelectionPanel;
