import "./entry-title.scss";
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import FlagStrip from '../flag-strip/flag-strip';
import type { Reference } from '../../interfaces/types';

export interface EntryTitleProps {
  title: string;
  jurisIDs: string[];
  reference?: Reference;
}

const EntryTitle: FC<EntryTitleProps> = ({title, jurisIDs, reference }) => {

  const navigate = useNavigate();

  function removeLastWord(str : string) {
    const words = str.trim().split(' ');
    if(words.length <= 1) {
      return "";
    }
    words.pop(); // Removes the last word
    return words.join(' ');
  }

  function getLastWord(str : string) {
    const words = str.trim().split(' ');
    const lastWord = words[words.length - 1];
    const prefix = (words.length > 1)? " " : "";

    return prefix + lastWord;
  }

  function handleClick() {
    if (reference) {
      console.log('got reference');
      navigate(`/document/${reference.id}`)
    }
    else {
      console.log('no reference');
    }
  }

  const everyWordExceptTheLast = removeLastWord(title); // The title needs to be divided, to make sure that the flags after it are never rendered alone (orphans) on the next line.
  const lastWord = getLastWord(title);

  return (
    <span className="entry-title" title={"Go to \"" + title + "\""} onClick={handleClick}>
        <span className="every-word-except-the-last">{everyWordExceptTheLast}</span>
        <span className="last-word-and-flags" style={ {whiteSpace: "nowrap"}}>
            <span className="last-word">{lastWord}</span>
            <FlagStrip jurisIDs={jurisIDs}/>
        </span>
    </span>
  );
}
  
export default EntryTitle;
