import '../styling/elevator-pitch-panel.scss';

function ElevatorPitchPanel() {

  return (
    <div className="elevator-pitch-panel">
        All the <b>legal information</b> you need to develop a <b>medical device</b>, in a single place.
    </div>
  );
}

export default ElevatorPitchPanel;
