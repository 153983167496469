import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface TocControl {
  showToc: boolean;
}

const initialState = {
  showToc: false,
} as TocControl;

export const tocSlice = createSlice({
  name: "toc",
  initialState,
  reducers: {
    showToc: (state, action: PayloadAction<boolean>) => {
      state.showToc = action.payload;
    }
  }
});

export const { showToc } = tocSlice.actions;
export default tocSlice.reducer;
