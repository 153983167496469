import '../styling/llo-list.scss';
import OutsideLink from './OutsideLink';
import type { ReferenceContainsUrlRelationship } from '../interfaces/ReferenceRelationships';

export interface OutsideLinkListProps {
  outsideLinks: ReferenceContainsUrlRelationship[];
}

const OutsideLinkList: React.FC<OutsideLinkListProps> = ({outsideLinks}) => {

  // TODO use more scalable solution to filter to unique links
  var filtered:ReferenceContainsUrlRelationship[] = outsideLinks.reduce((unique: ReferenceContainsUrlRelationship[], o) => {
    if (!unique.some(obj => obj.subString === o.subString)) {
      unique.push(o);
    }
    return unique;
  },[])

  return (
    <ul className="llo-list">
      {filtered.map((object, index) => {
        return (
          <li key={index} className="outside-link">
            <OutsideLink linkText={object.subString} linkUrl={object.externalLink} />
          </li>
        )
      })}
    </ul>
  );
}

export default OutsideLinkList;
