import '../styling/centered-column.scss';
import { FC } from 'react';
//import { useNavigate } from 'react-router-dom';

export type UserPanelProps = {
  child: React.ReactNode;
}

const UserPanel: FC<UserPanelProps> = ({child}) => {

  // TODO remove?
  //  const navigate = useNavigate();
  //
  //  const handleBack = () => {
  //    navigate('/user'); 
  //  }

  return (
    <div className="centered-column">
      {child}
    </div>
  );
}

export default UserPanel;
