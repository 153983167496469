// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-container {
  display: flex;
  height: 100%;
  padding: 0;
  margin: 0;
  align-items: center;
  justify-content: center;
}
.loader-container .loader-row {
  width: auto;
}
.loader-container .loader-row .loader {
  padding: 10px;
  width: 80px;
  height: 80px;
}`, "",{"version":3,"sources":["webpack://./src/styling/loader.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;EACA,UAAA;EACA,SAAA;EACA,mBAAA;EACA,uBAAA;AACF;AACE;EACE,WAAA;AACJ;AACI;EACE,aAAA;EACA,WAAA;EACA,YAAA;AACN","sourcesContent":[".loader-container {\n  display: flex;\n  height: 100%;\n  padding: 0;\n  margin: 0;\n  align-items: center;\n  justify-content: center;\n\n  .loader-row {\n    width: auto;\n\n    .loader {\n      padding: 10px;\n      width: 80px;\n      height: 80px;\n    }\n\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
