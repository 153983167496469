import { FunctionComponent } from 'react';
import { ReactComponent as InformationIcon } from "../assets/images/icons/information-icon.svg";
import "./information-button.scss";

export interface InformationIconProps {
  legend : string;
}

const InformationButton: FunctionComponent<InformationIconProps> = ({legend}) => {

      return(
      <>
        <InformationIcon className="information-icon" />
        <div className="popup">
          {legend}
        </div>
      </>
      );
};

export default InformationButton;