import "../styling/content-panel.scss";
import type { LexLightObject } from '../interfaces/LexLightObject';
import type { Definition } from '../interfaces/types';
import { useGetTermDetailQuery } from '../slices/apiSlice';
import { groupSameDefinitions } from '../common/groupSameDefinitions'; 
import ContentBlockTerm from './ContentBlockTerm';
import LexogonLoader from './LexogonLoader';

export interface TermWithGroupedDefinitions {
  id: number | null; 
  term: string | null;
  slug: string | null;
  lexType: string | null;
  definitions: [Definition][]
}

const ContentPanelTerm: React.FC<LexLightObject> = (lexObject: LexLightObject) => {

  const {
    data: termDetail,
    isSuccess: isQuerySuccess,
    isError: isQueryError,
    isLoading: isQueryLoading,
    isFetching: isQueryFetching,
  } = useGetTermDetailQuery(lexObject.instanceId.toString());
  
  const grouped: TermWithGroupedDefinitions = {id: null, term: null, slug: null, lexType: null, definitions:[]}; 

  if (isQuerySuccess) {
    if (termDetail) { // termDetail is of type TermResult
      grouped.id = termDetail.id;
      grouped.term = termDetail.term;
      grouped.slug = termDetail.slug;
      grouped.lexType = termDetail.lexType;
      const sourceDefinitions: Definition[] = termDetail.definitions;

      grouped.definitions = groupSameDefinitions(sourceDefinitions);
    }
  }

  if (isQueryLoading || isQueryFetching) return(<LexogonLoader />);
  if (!termDetail) return(<div>Could not get term details :(</div>);
  if (isQueryError) return(<div>An error occurred</div>);
  
  return (
    <div className="content-panel">
      { grouped.definitions.map((defArray, index) => (
        <ContentBlockTerm
          key={index}
          term={lexObject}
          definitions={defArray}
        />
      ))}
    </div>
  );
}

export default ContentPanelTerm;
