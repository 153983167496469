import { FC } from 'react';
import { ReactComponent as ExpansionIcon } from "../assets/images/icons/expansion-icon.svg";
import "./expansion-button.scss";

export type ExpansionState = "COLLAPSED" | "EXPANDED";

export interface ExpansionButtonProps {
    expansionState: ExpansionState; 
    extraClasses? : string[]; 
    clickHandler? : () => void;
    onMouseEnter? : () => void;
    onMouseLeave? : () => void;
}

const ExpansionButton: FC<ExpansionButtonProps> = ({ expansionState, extraClasses, clickHandler, onMouseEnter: mouseEntranceHandler, onMouseLeave: mouseDepartureHandler }) => {
    
    function calculateTooltip() : string {
        return expansionState === "COLLAPSED"? "Expand" : "Collapse";
    }

    function calculateClassName() : string {
        let classes : string[] = ["expansion-button"];
        
        if (extraClasses)
            classes = classes.concat(extraClasses);
        
        if (expansionState === "EXPANDED")
            classes = classes.concat("expanded");
            if (expansionState === "COLLAPSED")
            classes = classes.concat("collapsed");
        return classes.join(" ");
    }

    

    return (
        <ExpansionIcon className={calculateClassName()} title={calculateTooltip()} onClick={clickHandler} onMouseEnter={mouseEntranceHandler} onMouseLeave={mouseDepartureHandler}/>
  );
}
  
export default ExpansionButton;