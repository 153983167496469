// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.centered-column {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  row-gap: 1rem;
  justify-content: center;
  align-content: start;
  align-items: center;
  padding-top: 1.8rem;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 576px) {
  .centered-column {
    max-width: 100%;
    padding-top: 1rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/styling/centered-column.scss"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,eAAA;EACA,mBAAA;EACA,aAAA;EACA,uBAAA;EACA,oBAAA;EACA,mBAAA;EACA,mBAAA;EACA,cAAA;EAKA,iBAAA;EACA,kBAAA;AAJJ;AADI;EAXJ;IAYM,eAAA;IACA,iBAAA;EAIJ;AACF","sourcesContent":[".centered-column {\n\n    display: flex;\n    flex-wrap: wrap;\n    flex-direction: row;\n    row-gap: 1rem; \n    justify-content: center;\n    align-content: start;\n    align-items: center;\n    padding-top: 1.8rem;\n    max-width: 80%;\n    @media screen and (max-width: 576px) {\n      max-width: 100%;\n      padding-top: 1rem;\n    }\n    margin-left: auto;\n    margin-right: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
