import reactStringReplace from 'react-string-replace';
import SingleVersionBand, {SingleVersionBandModel } from "./SingleVersionBand";
import DualVersionBand, {DualVersionBandModel } from "./DualVersionBand";
import{FC} from 'react';
import{WordPrevalence} from './WordPrevalence';
import{AnyBandModel} from "./AnyBand";
import "../styling/block-body.scss";
import "../styling/band.scss";
import "../styling/single-version-band.scss";
import "../styling/rendered-segment.scss";
import{ImportanceFilter} from "./ImportanceFilter";
import type {Term, Definition } from "../interfaces/types";
import type {LexLightObject } from "../interfaces/LexLightObject";
import {bandFromDefinition} from "../common/bandFromDefinition";
import type { TermLink } from '../interfaces/TermLink';
import { potentialTermSet } from '../common/potentialTermSet';

export interface BlockBodyTermModelNeo {
  term: LexLightObject;
  definition: Definition;
  termLinks?: TermLink[];
}

export interface BlockBodyTermPropsNeo extends BlockBodyTermModelNeo{
}

  
const BlockBodyTermNeo: FC<BlockBodyTermPropsNeo> = ({ term, definition, termLinks }) => { 
  let classes : string[] = ["block-body"];

  const potentialTerms = potentialTermSet(definition.definition, 7);
  const linkableTerms: TermLink[] = [];

  const potentialTermArray = Array.from(potentialTerms);

  if (termLinks) {
    for (const pt of potentialTermArray) {
      termLinks.forEach(function (value) {
        if (value.term === pt && pt !== term.displayTitle) {
          linkableTerms.push(value);
        }
      })
    }
    linkableTerms.sort((a, b) => b.term.length - a.term.length);
  }

  const initContent = definition.definition;
  var content;

  if (0 != linkableTerms.length) {
    var re1 = new RegExp('\\b('+linkableTerms[0].term+')\\b', 'i');
    content = reactStringReplace(initContent, re1, (match, i) => (
      <span id="term-link"><a href={`/term/${linkableTerms[0].slug}`}>{match}</a></span>
    ));

    for (let a=1; a<linkableTerms.length; ++a) {
      var re2 = new RegExp('\\b('+linkableTerms[a].term+')\\b', 'i');
      content = reactStringReplace(content, re2, (match, i) => (
        <span id="term-link" key="{a}"><a href={`/term/${linkableTerms[a].slug}`}>{match}</a></span>
      ));
    }
  }
  else {
    content = definition.definition;
  }

  return (
    <div className = {classes.join(" ")}>
      <div className="band single-version-band">
        <div className="rendered-segment">
          <div className="segment-body">
            {content}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlockBodyTermNeo;
