import "../styling/saved-bookmarks-panel.scss";
import "../styling/sign-or-not.scss";
import { useEffect, useState } from 'react';
import { useGetBookmarksQuery } from '../slices/apiSlice';
import BookmarkTree from './BookmarkTree';
import { useAppSelector } from '../store/hooks';
import type { RootState } from '../store/store';
import LexogonLoader from './LexogonLoader';
import { useMediaQuery } from 'react-responsive';


const SavedBookmarksBlock = () => {
  const [loggedIn, setLoggedIn] = useState<boolean>(false);

  const isMobile = useMediaQuery({maxWidth: 576});
  const username = useAppSelector((state: RootState) => state.userSession.user.username);

  useEffect(() => {
    if (username) {
      setLoggedIn(true);
    }
    else {
      setLoggedIn(false);
    }
  });

  const {
    data: bookmarks,
    isLoading,
    isFetching,
    //    isSuccess,
    //    isError,
    //    error
  } = useGetBookmarksQuery();

  const classNames : string[] = [];

  if (isMobile) return (<></>);
  if (loggedIn && (isLoading || isFetching)) return(<LexogonLoader />);

  var content = <div id="sign-or-not">To use bookmarks, <a href="/sign-in">sign in</a> or <a href="/sign-up">sign up</a>.</div>;
  if (loggedIn && !isFetching && bookmarks) {
    content = <BookmarkTree initialBookmarks={bookmarks} />
  }

  return (
    <div id="saved-bookmarks-panel" className={classNames.join(" ")} >
      <div id="saved-bookmarks-panel-body">
        {content} 
      </div> 
    </div>
  );
}
  
export default SavedBookmarksBlock;
