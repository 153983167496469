import "../styling/related-entry-panel.scss";
import { FC } from 'react';
import FlatCheckbox, { CheckedState } from './FlatCheckbox';
import { SelectionState } from './enumerations';
import FlagStrip from "../shared/flag-strip/flag-strip";
import { DescriptorModel } from "../shared/descriptor/descriptor";
import EntryTitle from "../shared/entry-title/entry-title";

export interface RelatedEntryPanelModel{
    id : string;
    descriptor: DescriptorModel;
    entryTitle: string;
    selectionState: SelectionState;
}

export interface RelatedEntryPanelProps extends RelatedEntryPanelModel{
    parentSelectionState: SelectionState;
}

const RelatedEntryPanel: FC<RelatedEntryPanelProps> = ({id,descriptor, entryTitle, selectionState, parentSelectionState }) => {
  let checkedState : CheckedState = "UNCHECKED";  
  let classNames : string[] = ["related-entry-panel"];

  switch (selectionState) {
    case "UNSELECTED":
        classNames.push("unselected");
        checkedState = "UNCHECKED";
    break;
    case "SELECTED":
      switch (parentSelectionState) {
        case "UNSELECTED":
            classNames.push("directly-selected");
            checkedState = "CHECKED";
          break;
        case "SELECTED":
            classNames.push("indirectly-selected");
            checkedState = "SUBDUED";
          break;
      }
      break;
  }

  function removeLastWord(str : string) {
    let words = str.trim().split(' ');
    if(words.length <= 1) {
      return "";
    }
    words.pop(); // Removes the last word
    return words.join(' ');
  }

  function getLastWord(str : string) {
    let words = str.trim().split(' ');
    const lastWord = words[words.length - 1];
    const prefix = (words.length > 1)? " " : "";

    return prefix + lastWord;
  }

  const everyWordExceptTheLast = removeLastWord(entryTitle); // The title needs to be divided, to make sure that the flags after it are never rendered alone (orphans) on the next line.
  const lastWord = getLastWord(entryTitle);

  return (
    <div className={classNames.join(" ")}>
      <FlatCheckbox shape="SQUARE" checkedState={checkedState}/>   
      <div className="related-entry-panel-header">
        <span className="related-entry-descriptor">{descriptor.title + ": "}</span>
        
        <EntryTitle title={entryTitle} jurisIDs={descriptor.jurisIDs}/>
        


    </div> 
    </div>
  );
}
  
export default RelatedEntryPanel;
