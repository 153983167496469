import CentralizedAccessAnimatedImage from '../images/Centralized library (animated).gif';
import TailorInformationAnimatedImage from '../images/Tailor information (animated).gif';
import ReceiveSuggestionsAnimatedImage from '../images/Receive suggestions (animated).gif';
import CentralizedAccessStaticImage from '../images/Centralized library (static).png';
import TailorInformationStaticImage from '../images/Tailor information (static).png';
import ReceiveSuggestionsStaticImage from '../images/Receive suggestions (static).png';
import KeyFeaturePanel from './KeyFeaturePanel';
import '../styling/key-features-panel.scss';

const KeyFeaturesPanel = () => {
    return (
        <div id = "KeyFeaturesPanel">
            <KeyFeaturePanel 
                id='CentralizedAccessKeyFeaturePanel' 
                heading='Access hundreds of legal documents' 
                description='from multiple countries, in a single place, and use smart tools see the differences between them.' 
                staticImageUrl={CentralizedAccessStaticImage}
                animatedImageUrl={CentralizedAccessAnimatedImage}/>
            <KeyFeaturePanel 
                id='TailoringKeyFeaturePanel' 
                heading='Tailor the information to your needs' 
                description='by highlighting, hiding, and bookmarking documents, chapters, and text snippets!' 
                staticImageUrl={TailorInformationStaticImage}
                animatedImageUrl={TailorInformationAnimatedImage}/>
            <KeyFeaturePanel 
                id='SuggestionsKeyFeaturePanel' 
                heading='Get smart suggestions' 
                description='of relevant legal documents you may need to consider, and get notified when any of your bookmarks become obsolete.' 
                staticImageUrl={ReceiveSuggestionsStaticImage}
                animatedImageUrl={ReceiveSuggestionsAnimatedImage}/>
        </div>
    );
}

export default KeyFeaturesPanel;
