import '../styling/modal-content.scss';
import { FC, useEffect, useRef } from 'react';

export type ModalBoxProps = {
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  childComponent: React.ReactNode;
}

const ModalBox: FC<ModalBoxProps> = ({setVisible, childComponent}) => {

  const modalRef = useRef<any>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (!modalRef.current?.contains(event.target)) {
        setVisible(false);
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [modalRef]);

  return (
    <div id="modal-background"> 
      <div id="modal-content" ref={modalRef}>
        <span className="modal-close-button" onClick={() => setVisible(false)}>&times;</span>
        {childComponent} 
      </div>
    </div>
  );
}

export default ModalBox;
