import "../styling/saved-bookmarks-panel.scss";
import { ReactComponent as FolderIcon } from "../shared/assets/images/icons/folder-icon.svg";

import { FC, useState, useRef } from 'react';
import FlatCheckbox, { Shape } from './FlatCheckbox';
import { ReactComponent as GrabbableSurfaceIcon } from "../shared/assets/images/icons/grabbable-surface-icon.svg";
import ExpansionButton from '../shared/expansion-button/expansion-button';
import { ReactComponent as EllipsisIcon } from "../shared/assets/images/icons/ellipsis-icon.svg";
import { Dropdown } from "antd";
import type { MenuProps } from 'antd';
import '../styling/folder.scss';
import '../styling/saved-bookmarks-panel.scss';
import { CheckedState } from './FlatCheckbox';
import { TreeItemModel } from './TreeItem';
import { ExpansionState, SelectionState } from './enumerations';
import {levelIndent} from './SharedLevelIndent';
import { useNavigate } from 'react-router-dom';
import { lexTypeToToken } from '../common/lexTypeToToken';
import { useUpdateBookmarkMutation, useDeleteBookmarkMutation } from '../slices/apiSlice';

type FolderSelectionStyle = "DIRECTLY_SELECTED" | "INDIRECTLY_SELECTED" | "UNSELECTED";
type Collapsability = "COLLAPSABLE" | "ALWAYS_EXPANDED";

export interface BookmarkTreeNodeProps {
  node: any;
  style: any;
  dragHandle?: any;
  tree: any;
}

const BookmarkTreeNode: FC<BookmarkTreeNodeProps> = ({ node, style, dragHandle, tree }) => {

  const navigate = useNavigate();
  const [updateBookmark, updateBookmarkResult] = useUpdateBookmarkMutation();
  const [deleteBookmark, deleteBookmarkResult] = useDeleteBookmarkMutation();

  const isFolder : boolean = (node.data.lexType === "bookmark-folder");

  const [headerIsHovered, setHeaderIsHovered] = useState(false);                                               

  let initialExpansion : ExpansionState = node.isClosed ? "COLLAPSED" : "EXPANDED";

  const [titleIsHovered, setTitleIsHovered] = useState(false);
  const [popupMenuIsOpened, setPopupMenuIsOpened] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const [expansionState, setExpansionState] = useState<ExpansionState>(initialExpansion); 
  const collapsability = useRef<Collapsability>(isFolder ? "COLLAPSABLE" : "ALWAYS_EXPANDED");

  const totalIndent = (node.level * levelIndent) + "em";
  
  const classNames = ["folder tree-node "];
  const headerClasses = ["folder-header", "tree-node-header", "tree-row"];
  const bodyClasses = ["folder-body", "tree-node-body"];

  if (headerIsHovered)
    headerClasses.push("hovered");

  if (titleIsHovered) 
    headerClasses.push("hovered-title"); 
  
  if (popupMenuIsOpened)
    headerClasses.push("popup-menu-opened");
  if (isRenaming) {
    headerClasses.push("renaming");
  }
  else {
    const index = headerClasses.indexOf("renaming");
    if (index !== -1) {
      headerClasses.splice(index, 1);
      console.log('removed renaming');
    }
  }

  const renderedExpansionState : ExpansionState = collapsability.current === "ALWAYS_EXPANDED"? "EXPANDED" : expansionState;

  if (node.isClosed) {
    classNames.push("collapsed");
    headerClasses.push("collapsed");
    bodyClasses.push("collapsed");
  }
  else {
    classNames.push("expanded");
    headerClasses.push("expanded");
    bodyClasses.push("expanded");
  }

  let titleTooltip : string = "";
  switch (collapsability.current) {
    case "COLLAPSABLE":
      titleTooltip = (expansionState === "COLLAPSED" ? "Expand folder " : "Collapse folder ") + "\"" + node.data.displayTitle + "\"";
      break;
    case "ALWAYS_EXPANDED":
      titleTooltip = "Go to " + node.data.lexType + " \"" + node.data.displayTitle + "\"";
      break;
  }
  if (collapsability.current === "COLLAPSABLE")
    titleTooltip = (expansionState === "COLLAPSED" ? "Expand folder " : "Collapse folder ") + "\"" + node.data.displayTitle + "\"";
  //  const checkboxTitle = selectionState === "UNSELECTED" ? "Select folder" : "Unselect folder";

  const items: MenuProps['items'] = [];
  let shape: Shape = "BOOKMARK";
  if (isFolder) {
    items.push({key: 'RENAME_FOLDER', label: "Rename folder"});
    items.push({key: 'DELETE_FOLDER', label: "Delete folder"});
    items.push({key: 'NEW_FOLDER',    label: "New folder"});

    // may not need classnames
    classNames.push("collapsable");
    shape = "FOLDER";
    if (node.willReceiveDrop ) {
      // user is hovering drag and drop action over
      shape = "SQUARE";
      // TODO use better icon like open folder when available
    }
  }
  else {
    items.push({key: "DELETE_BOOKMARK", label: "Delete bookmark"});

    // may not need classnames
    classNames.push("always-expanded");

  }

  const handleHeaderMouseOver = () => {
    setHeaderIsHovered(true);
  }

  const handleHeaderMouseOut = () => {
      setHeaderIsHovered(false);
  }

  const handlePopupMenuChange = (isOpen : boolean) => {
    if (isOpen === false)
      setHeaderIsHovered(false); 
    setPopupMenuIsOpened(isOpen);
    //popupMenuChangeHandler(isOpen);
  }

  interface MenuClickedEventParameters {
    key : string
  }

  const toggleExpansionState = () => {
    //const newExpansionState = expansionState === "COLLAPSED" ? "EXPANDED" : "COLLAPSED";

    let newExpansionState: ExpansionState;
    if (node.isClosed) {
      newExpansionState = "EXPANDED";
      node.open();
    }
    else {
      newExpansionState = "COLLAPSED";
      node.close();
    }
    setExpansionState(newExpansionState);
    //    expansionChangeHandler(node.data.instanceId, level, newExpansionState);  
  }

  const expansionButtonClickHandler = () => {
    toggleExpansionState();
  }

  const titleClickHandler = () => {
    if(isFolder) {
      //if (collapsability.current === "COLLAPSABLE")
      toggleExpansionState();
    }
    else {
      const token = lexTypeToToken(node.data.lexType);
      navigate(`/${token}/${node.data.instanceId}`);
    }
  }

  const handleMenuClicked = ( params: MenuClickedEventParameters )  => {
    handlePopupMenuChange(false);

    if (params.key === "RENAME_FOLDER") {
      setIsRenaming(true);
      node.edit();
      //renamingStateChangeHandler(true);
    }
    else if (params.key === "DELETE_FOLDER") {
      console.log("deleting folder: ", node.data);
      deleteBookmark(node.data);
      tree.delete(node.id);
    }
    else if (params.key === "NEW_FOLDER") {
      console.log("new folder");
      tree.createLeaf(node.id);
    }
    else if (params.key === "DELETE_BOOKMARK") {
      console.log('deleting bookmark: ', node.data);
      deleteBookmark(node.data);
      tree.delete(node.id);
    }
  } 

  const menuProps : MenuProps = {
    items: items,
    onClick: handleMenuClicked
  }

  let typeText: string = "";
  if (node.data.lexType === "term") {
    typeText = '[term]';
  }
  else if (node.data.lexType === "document") {
    typeText = "[doc]";
  }
  
  return (
    <div key={node.data.id} className={classNames.join(" ")} ref={dragHandle}>
      <div className={headerClasses.join(" ")} onMouseOver={ handleHeaderMouseOver} onMouseOut={ handleHeaderMouseOut} >
        <div className="left-action-panel tree-indentation action-panel" style={{marginLeft: totalIndent}}>
          <FlatCheckbox shape={shape} checkedState={"UNCHECKED"} extraClasses={["tree-node-checkbox", "action-button"]} tooltip={node.data.lexType}/>
        </div>
        
        <div className="folder-title tree-node-title" title={titleTooltip}
          onMouseOver={() => setTitleIsHovered(true)} 
          onMouseOut={() => setTitleIsHovered(false)} 
          onClick={titleClickHandler}>
          {node.isEditing ? (
            <input
              type="text"
              defaultValue={node.data.displayTitle}
              onFocus={(e) =>e.currentTarget.select()}
              onBlur={() => node.reset()}
              onKeyDown={(e)=>{
                if (e.key === "Escape") node.reset();
                if (e.key === "Enter" || e.key === "Tab") {
                  //node.submit(e.currentTarget.value);
                  updateBookmark({
                    "displayTitle": e.currentTarget.value,
                    "bookmarkId": node.data.bookmarkId,
                    "parentId": node.data.parentId,
                    "lexType": node.data.lexType,
                    "instanceId": node.data.instanceId,
                    "order": 0 // TODO
                  });
                  //node.data.displayTitle = e.currentTarget.value;
                  setIsRenaming(false);
                }
              }}
              autoFocus
            />

          ) : (
            <span>{node.data.displayTitle}</span>
          )}
        </div>

      <div className="right-action-panel action-panel">
        {isFolder &&
          <ExpansionButton extraClasses={["action-button"]} expansionState={expansionState}  clickHandler={expansionButtonClickHandler}/>
        }
          <Dropdown menu={menuProps} trigger={["click"]} onOpenChange={handlePopupMenuChange}  >
            <EllipsisIcon className="ellipsis-button action-button" title="More options..."/>
          </Dropdown> 
          <GrabbableSurfaceIcon className="move-button action-button" title="Move folder"/>
        </div>
      </div>
    </div>
  );
}

export default BookmarkTreeNode;
