import React, { useState } from "react";
import "../styling/key-feature-panel.scss";

interface Props {
    id: string;
    staticImageUrl: string;
    animatedImageUrl : string;
    heading : string;
    description : string;
  }

const KeyFeaturePanel: React.FC<Props> = ({id, staticImageUrl, animatedImageUrl, heading, description}) => {

    const [isHovering, setIsHovering] = useState(false);

    return (
        <div 
            id = {id} 
            className = "KeyFeaturePanel" 
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}>
            <img 
                id = "SuggestionsKeyFeatureImage" 
                alt = {heading} 
                className = "KeyFeatureImage" 
                src = {isHovering? animatedImageUrl: staticImageUrl}/>
            <div id = "SuggestionsKeyFeatureDescription" className = "KeyFeatureText">
                <div className="Heading">
                    {heading}
                </div>
                <div className="Description">
                    {description}
                </div>
            </div>
        </div>
    );
}

export default KeyFeaturePanel;
