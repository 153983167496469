import { FC, memo } from 'react';
import '../styling/reference-entries.scss';
import type { ReferenceEntry } from '../interfaces/types';
import type { ReferenceContainsUrlRelationship } from '../interfaces/ReferenceRelationships';
import reactStringReplace from 'react-string-replace';

export interface ReferenceEntryCitationModel {
  entry: ReferenceEntry;
  index: number;
  refLinks: ReferenceContainsUrlRelationship[];
}

const ReferenceEntryCitation: FC<ReferenceEntryCitationModel> = ({ entry, index, refLinks }) => {

  const linkRefs = refLinks.filter(e => e.reference_entry === entry.documentEntryUid);
  const initContent = entry.content;
  var content;
  var spanCount = 0;

  if (linkRefs.length) {
    var re1 = new RegExp('\\b('+linkRefs[0].subString+')\\b', 'i');
    content = reactStringReplace(initContent, re1, (match, i) => (
      <span key={`c1span-${entry.documentEntryUid}-${spanCount}`} title={`Go to external link "${linkRefs[0].subString}"`}><a href={linkRefs[0].externalLink} target="_blank" rel="noreferrer">{match}</a></span>
    ));
    ++spanCount;

    for (let a=1; a<linkRefs.length; ++a) {
      var re1 = new RegExp('\\b('+linkRefs[a].subString+')\\b', 'i');
      content = reactStringReplace(content, re1, (match, i) => (
        <span key={`c2span-${entry.documentEntryUid}-${spanCount}`} title={`Go to external link "${linkRefs[a].subString}"`}><a href={linkRefs[a].externalLink} target="_blank" rel="noreferrer">{match}</a></span>
      ));
      ++spanCount;
    }
  }
  else {
    content = entry.content;
  }

  return (
    <div className='citation' id={entry.documentEntryUid}  title={entry.sectionId}>{content}</div>
  )
}

export default memo(ReferenceEntryCitation);
