import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import AppHeader, { MenuIconCursorEvent } from "./components/AppHeader";
import WelcomePage from "./components/WelcomePanel";
import './styling/app.scss';
import LibraryExplorer from "./pages/LibraryExplorer";
import { useEffect, useState } from "react";
import {SearchBoxAction} from "./components/SearchBox";
import { SearchResultRowModel } from './interfaces/types';
import { useAppSelector } from './store/hooks';
import type { RootState } from './store/store';
import { storeAuthentication } from './slices/userSessionSlice';
import { useAppDispatch } from './store/hooks';
import NeoSearchResultsPanel from './components/NeoSearchResultsPanel';
import { SearchResultsPanelModel, SearchResultsPanelProps } from './interfaces/types';
import SignInPanel from './components/SignInPanel';
import SignUpPanel from './components/SignUpPanel';
import UserDashboard from './components/UserDashboard';
import UserPanel from './components/UserPanel';
import UserProfile from './components/UserProfile';
import UserSubscriptions from './components/UserSubscriptions';
import LibraryPanel from './components/LibraryPanel';
import DocumentCoverage from './components/DocumentCoverage';
import ReferenceListPanel from './components/ReferenceListPanel';
import JurisdictionPanel from './components/JurisdictionPanel';
import ReferenceStatusPanel from './components/ReferenceStatusPanel';
import AppMenu from './components/AppMenu';
import { Availability } from './shared/enums/availability';
import { Affordance, ExpansionState } from './components/AppMenu';
import { AccountLevel } from './shared/enums/AccountLevel';
import AdvancedSearchResultsPage from './components/AdvancedSearchResultsPage';
import CookieConsent from 'react-cookie-consent';
import { clearSearch } from './slices/searchSlice';
import LoginRedirect from './components/LoginRedirect';
import PublicationToDocumentResolver from './components/PublicationToDocumentResolver';
import OaiPrivacyPolicy from './components/OaiPrivacyPolicy';
import OaiSupport from './components/OaiSupport';

enum AppPage {
  Welcome,
  LibraryExplorer,
  SignUp,
  EmailConfirmationSent,
  EmailConfirmed,
  SignIn,
  Settings,
  PasswordReplacement,
  PremiumBenefits,
  WelcomeToPremium
}

function App() {

  const [searchResultsSelectedIndex, setSearchResultsSelectedIndex] = useState<number>(-1);
  const [resultsPanelModel, setResultsPanelModel] = useState<SearchResultsPanelModel>({ searchResults: undefined});

  const [accountLevel, setAccountLevel] = useState<AccountLevel>(AccountLevel.Guest);
  //  const [subscriptionRenewalOption, setSubscriptionRenewalOption] = useState<SubscriptionRenewalOption | null>(null);
  const [periodEnd, setPeriodEnd] = useState<Date | null>(null);
  const [userEmail, setUserEmail] = useState<string>("");
  const [signInErrorMessage, setSignInErrorMessage] = useState<string | null>(null);
  const [passwordReplacementErrorMessage, setPasswordReplacementErrorMessage] = useState<string | null>(null);

  const [appMenuExpansionState, setAppMenuExpansionState] = useState<ExpansionState>(ExpansionState.Collapsed);
  const [appMenuAffordance, setAppMenuAffordance] = useState<Affordance>(Affordance.None);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);

  const username = useAppSelector((state: RootState) => state.userSession.user.username);

  useEffect(() => {
    if (username) {
      setLoggedIn(true);
    }
    else {
      setLoggedIn(false);
    }
  });

  const handleSearchResultsRowHover = (rowId : string) : void => {
    if (resultsPanelModel?.searchResults === undefined)
      return;
    
    const rowIndex = resultsPanelModel.searchResults.findIndex( searchResult => searchResult.id === rowId);
    setSearchResultsSelectedIndex(rowIndex || 0);
  }  
  
  function createResultPanelPropsFromModel(model : SearchResultsPanelModel) : SearchResultsPanelProps {
    const props : SearchResultsPanelProps = {
      searchResults: model.searchResults? [] : undefined,
      selectedIndex: -1,
      rowHoverHandler: handleSearchResultsRowHover
    };
  
    model.searchResults && model.searchResults.map( (searchResult : SearchResultRowModel, index: number) => {
      props.searchResults && props.searchResults.push({
        id: searchResult.id,
        title: searchResult.title,
        descriptor: searchResult.descriptor,
        lexType: searchResult.lexType,
      });
    })
  
    return props;
  }

  const assignSearchResults = (resultsPanelModel : SearchResultsPanelModel) => {
    const panelProps : SearchResultsPanelProps = createResultPanelPropsFromModel(resultsPanelModel);
    setSearchResultsSelectedIndex ( (resultsPanelModel.searchResults && resultsPanelModel.searchResults.length > 0) ? 0 : -1);
    setResultsPanelModel(panelProps);
  }
  
  const shiftSelectedSearchResultIndex = (shift: number) => {
    
    if (resultsPanelModel.searchResults === undefined) 
      return;
  
    const newIndex = searchResultsSelectedIndex+ shift;
    if (newIndex < 0 || newIndex > resultsPanelModel.searchResults.length - 1)
      return;
    
      setSearchResultsSelectedIndex(newIndex);
  }  
  
  const handleSearchQueryChange = (newSearchQuery: string) => {
    if (newSearchQuery === "")
      setResultsPanelModel({searchResults: undefined});
  }
  const handleSearchBoxAction = (action: SearchBoxAction) => {
    console.log("handleSearchBoxAction: ", action);
    switch(action) {
      case "CLEAR_RESULTS":
        setResultsPanelModel({searchResults: undefined});
        break;
      case "GO_TO_SELECTED_RESULT":
        
        break;
      case "HIGHLIGHT_NEXT_RESULT":
        shiftSelectedSearchResultIndex(1);
        break;
      case "HIGHLIGHT_PREVIOUS_RESULT":
        shiftSelectedSearchResultIndex(-1);
        break;
    }
  }

  const handleMenuOptionClick = (anActionId: string) => {
    clearSearch(); //TODO

    setAppMenuExpansionState(ExpansionState.Collapsed);
  }

  const dispatch = useAppDispatch();
  const lexSession = JSON.parse(localStorage.getItem("lexSession") || "{}");

  if ('user' in lexSession && 'token' in lexSession) {
    dispatch(storeAuthentication(lexSession));
  }

  const handleMenuIconClicked = () : void => {
    clearSearch(); //TODO
    if (appMenuExpansionState === ExpansionState.Expanded)
      setAppMenuExpansionState(ExpansionState.Collapsed);
    else
      setAppMenuExpansionState(ExpansionState.Expanded);

  }

  const handleMenuIconMouseEvent = (event: MenuIconCursorEvent) => {
    switch (event){
      case MenuIconCursorEvent.Entered:
        setAppMenuAffordance(Affordance.ShowPossibility);
        break;
      case MenuIconCursorEvent.MouseDown:
        setAppMenuAffordance(Affordance.ShowIntent);
        break;
        case MenuIconCursorEvent.MouseUp:
        setAppMenuAffordance(Affordance.ShowPossibility);
        break;
      case MenuIconCursorEvent.Leave:
        setAppMenuAffordance(Affordance.None);
        break;
    }   
  }

  return (
    <BrowserRouter>
        <div id="app">
          <AppHeader
            menuAvailability={Availability.Available}
            signupAvailability={Availability.Available}
            signInAvailability={Availability.Available}
            upgradeAvailability={Availability.Available}
            signoutAvailability={Availability.Available}
            searchBoxAvailability={Availability.Available}
            searchQueryChangeHandler={handleSearchQueryChange}
            searchboxActionHandler={handleSearchBoxAction}
            signUpButtonClickHandler={()=>{}}
            signInButtonClickHandler={()=>{}}
            signOutButtonClickHandler={()=>{}}
            viewPremiumBenefitsButtonClickHandler={()=>{}}
            appMenuIconClickHandler={handleMenuIconClicked}
            menuIconMouseHandler={handleMenuIconMouseEvent}

          />
          <div className="app-header-appendix">
            { resultsPanelModel && <NeoSearchResultsPanel selectedIndex={searchResultsSelectedIndex} rowHoverHandler={handleSearchResultsRowHover}  />}
          </div>
          <div id="app-body-container">
          <Routes>
            <Route path="/" element = {<WelcomePage />} />
            <Route path="/sign-in" element = {<SignInPanel />} />
            <Route path="/sign-up" element = {<SignUpPanel />} />
            <Route path="/user" element = {
              <LoginRedirect isLoggedIn={loggedIn} loggedInTarget={<UserDashboard />} />}
            />
            <Route path="/user/profile" element = {
              <LoginRedirect isLoggedIn={loggedIn} loggedInTarget={<UserPanel child={<UserProfile />} />}/>}
            />
            <Route path="/user/subscriptions" element = {
              <LoginRedirect isLoggedIn={loggedIn} loggedInTarget={<UserPanel child={<UserSubscriptions />} />}/>}
            />
            <Route path="/library" element = {<LibraryPanel />} />
            <Route path="/coverage" element = {<DocumentCoverage />} />
            <Route path="/advanced-search" element = {<AdvancedSearchResultsPage />} />
            <Route path="/jurisdiction/:juris" element = {<LibraryPanel />} />
            <Route path="/publication/:instanceId" element = {<PublicationToDocumentResolver />} />
            <Route path="/document/jurisdiction" element = {<JurisdictionPanel />} />
            <Route path="/document/status" element = {<ReferenceStatusPanel />} />
            <Route path="/document/:category/:categoryToken" element = {<ReferenceListPanel />} />
            <Route path="/oai/privacy-policy" element = {<OaiPrivacyPolicy />} />
            <Route path="/oai/support" element = {<OaiSupport />} />
            <Route path="/:firstToken" element = {
              <LibraryExplorer />
              } />
            <Route path="/:firstToken/:secondToken" element = {
              <LibraryExplorer />
              } />
             </Routes>
             <AppMenu expansionState={appMenuExpansionState} affordance={appMenuAffordance} optionClickHandler={handleMenuOptionClick} />
          </div>
        </div>
        <CookieConsent
          style={{ background: "#F3F4FB", color: "#4254BE", fontWeight: "500", boxShadow: "0em -0.3em 0.3em rgba(0,0,0,0.2)" }}
          buttonStyle={{ background: "#4254BE", color: "white", padding: "0.6rem", borderRadius: "0.4rem" }}
        >
          This website uses cookies to enhance the user experience.
        </CookieConsent>
    </BrowserRouter>
  );
}

export default App;
